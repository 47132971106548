import React, {useEffect, useState} from "react";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import usefetchdata from "../../../hooks/usefetchdata";
import useTitle from "../../../hooks/TitleManager";

const Unavailability = () => {
    useTitle({ title: "Profil - Mes disponibilités - Périodes" });
    const {tempWorkerData} = usefetchdata();
    const token = localStorage.getItem('xsrfToken')
    const [isToastLunch, setIsToastLunch] = useState(true);

    const manageToastLunch = () => {
        if(isToastLunch){
            setIsToastLunch(false)
            setTimeout(() => {
                setIsToastLunch(true)
            }, 5000)
        }
    }

    const [days, setDays] = useState([
        {
            name: 'Lundi',
            isUnavailable: false
        },
        {
            name: 'Mardi',
            isUnavailable: false
        },
        {
            name: 'Mercredi',
            isUnavailable: false
        },
        {
            name: 'Jeudi',
            isUnavailable: false
        },
        {
            name: 'Vendredi',
            isUnavailable: false
        },
        {
            name: 'Samedi',
            isUnavailable: false
        },
        {
            name: 'Dimanche',
            isUnavailable: false
        }
    ])

    const clickUnavailableDay = (index) => {
        const currentStatusDay = days[index].isUnavailable
        setDays(prevData => {
            const newData = [...prevData]
            newData[index].isUnavailable = !currentStatusDay
            return newData
        });
        ApiDatabase.putUnavailableDay({token, day: index, isUnavailable: !currentStatusDay}, (data) => {
            if(isToastLunch){
                toast.success('Votre disponibilité a bien été enregistrée')
                manageToastLunch();
            }
        }, err => {
            toast.error('Une erreur est survenue')
            setDays(prevData => {
                const newData = [...prevData]
                newData[index].isUnavailable = currentStatusDay
                return newData
            });
            console.log(err)
        });
    }

    useEffect(() => {
        if(tempWorkerData?.unavailable_days){
            setDays(prevData => {
                const newData = [...prevData]
                tempWorkerData.unavailable_days.map(day => {
                    newData[day].isUnavailable = true
                })
                return newData
            });
        }
    }, [tempWorkerData]);

    return (
        <>
            <div className={'flex flex-col gap-3'}>
                <h1 className="flex justify-start ft-lg">
                    Je suis disponible le ...
                </h1>
                <div className={'grid grid-cols-3 gap-2'}>
                    {days.map((day, index) => (
                        <div key={index}
                             className={`flex flex-col gap-3 border rounded px-2 py-3 cursor-pointer ${!day.isUnavailable ? 'bg-green-50 text-green-700 border-green-100' : 'bg-gray-50'}`}
                             onClick={() => clickUnavailableDay(index)}
                        >
                            <div className={'text-center'}>{day.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Unavailability;