import React from 'react';
import PageForm from "components/pageForm/PageForm";
import MediaQuery from "react-responsive";
import SubNavFiles
    from "components/subnav/documents/desktop/subnavfiles";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ChevronRight} from "assets/icons";
import SubNavMobileFile from "components/subnav/documents/mobile";
import useTitle from "../../hooks/TitleManager";

const Documents = () => {
    useTitle({ title: "Documents" });
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()
    const linkMobile = [
        {
            path: 'contracts/nosign',
            pTrad: 'Contrats'
        }, {
            path: 'hours/nocompleted',
            pTrad: 'Relevés d’heures'
        },
        {
            path: 'payslip',
            pTrad: 'Fiche de paie'
        }
    ]
    return (
        <PageForm>
            <MediaQuery minWidth={770}>
                <div className="flex items-center justify-between col-span-12 bg-white sticky z-10">
                    <SubNavFiles/>
                </div>
                <div className="col-span-12 px-8 mt-8 mb-28">
                    <Outlet/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                <div
                    className="col-span-12 bg-white fixed w-full mt-16">
                    <SubNavMobileFile/>
                </div>
                {pathname === '/documents' ?
                    <div className="col-span-12 mt-32 mb-28">
                        {linkMobile.map((item) =>
                            (<div onClick={e =>
                                navigate(`./${item.path}`)
                            }>
                                <div
                                    className='flex items-center justify-between h-14 px-2 my-2'>
                                    <p className='ft-sm font-bold text-gray-900'>
                                        {item.pTrad}
                                    </p>
                                    <ChevronRight color={'#111827'}
                                                  wh={'32'}/>
                                </div>
                            </div>)
                        )}
                    </div>
                    :
                    <div className={'mt-32 mb-32'}>
                        <Outlet/>
                    </div>
                   }
            </MediaQuery>

        </PageForm>
    );
};

export default Documents;