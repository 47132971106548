import React, {useState, useEffect, useContext} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {Information, Mail} from "assets/icons";
import {EMAIL_REGEX} from "helper/helper";
import useModal from "components/modal/useModal";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import usefetchdata from "hooks/usefetchdata";
import UseContext from "hooks/useContext";

const Modalmodifyemail = () => {
    const {toggle} = useModal()
    const {userData}=usefetchdata()
    const {refreshLoadUser,setObjID,setObjType} = UseContext()
    const [newEmail, setNewEmail] = useState('')
    const [validNewEmail, setValidNewEmail] = useState(false)

    useEffect(() => {
        setValidNewEmail(EMAIL_REGEX.test(newEmail));
    }, [newEmail]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if(newEmail !== userData.email){
            localStorage.setItem('nEmail',newEmail)
            setObjID(newEmail)
            setObjType(userData.validation)
            ApiDatabase.postCreateTokenMail(
                {token: localStorage.getItem('xsrfToken'), email: newEmail},
                (data) => {
                    refreshLoadUser()
                    toast.warning('Modification en cours de vérification')
                    toggle()
                    toggle('tok_u_tokenEmail')
                },
                (err)=>{
                    if(err?.response?.data?.message) toast.error(err?.response?.data?.message)
                    else toast.error('Un problème est survenue')
                }
            )
        } else {
            toast.error('Vous avez entré le même email')
        }
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Changer l'e-mail
            </Modalheader>
            <ModalBody>
                <form>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <label
                                htmlFor="email_address"
                                className="block ft-sm font-medium  text-gray-500"
                            >
                                E-mail actuel
                            </label>
                            <div className="relative text-gray-500">
                                <input
                                    name="email"
                                    value={userData?.email}
                                    className="w-full h-10 pl-8 ft-b placeholder-gray-600 border rounded-lg "
                                    type="email"
                                    disabled
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                    <Mail wh={18} color={'#666666'} strokeW={2}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <label
                                htmlFor="email_address"
                                className="block ft-sm font-medium  text-gray-500"
                            >
                                Nouveau e-mail
                            </label>
                            <div className="relative text-gray-500">
                                <input
                                    name="email"
                                    value={newEmail}
                                    onChange={(e) => {
                                        setNewEmail(e.target.value);
                                    }}
                                    className="w-full h-10 pl-8 ft-b placeholder-gray-600 border rounded-lg "
                                    type="email"
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                    <Mail wh={18} color={'#666666'} strokeW={2}/>
                                </div>
                            </div>
                            <p
                                className={
                                    newEmail && !validNewEmail
                                        ? "text-yellow-500 flex flex-row gap-4 items-center"
                                        : "hidden"
                                }
                            >
                              <span>
                                  <Information wh={15} color={'#FF9600'}/>
                              </span>
                                Format de l'email incorrect
                            </p>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Modalmodifyemail;