import React, {useEffect, useState} from 'react';
import Indicator from "components/modal/dataModal/data/modaljustify/address/component/indicator";
import Firstform from "components/modal/dataModal/data/modaljustify/address/component/body/firstform";
import Secondform from "components/modal/dataModal/data/modaljustify/address/component/body/secondform";
import Threeform from "components/modal/dataModal/data/modaljustify/address/component/body/threeform";
import Fourform
    from "components/modal/dataModal/data/modaljustify/address/component/body/fourform";
import Fiveform
    from "components/modal/dataModal/data/modaljustify/address/component/body/fiveform";
import CheckFilesIdentity from "../../identity/components/body/checkFilesIdentity";
import AddressGeoApi from "../../../../../../AddressGeoApi/AddressGeoApi";
import Usefetchdata from "../../../../../../../hooks/usefetchdata";
import ApiDatabase from "../../../../../../../server";
import {toast} from "react-toastify";
 
const Multiform = ({formIndex,setFormIndex,setCheck,setProof,proof,addressValid,attestation,setAttestation,check}) => {
    const [address, setAddress] = useState({});
    const {tempWorkerData}=Usefetchdata()

    const elements = [
        <Firstform single={setProof} />,
        <>
            <div className="w-full">
                <AddressGeoApi
                    addressGeoValue={setAddress}
                    addressValue={address}
                    page={'profileAddress'}
                />
            </div>
            <CheckFilesIdentity key={CheckFilesIdentity} files={[proof]} />
        </>,
    ]

    const elements2 = [
        <Firstform single={setProof}/>,
        <>
            <div className="w-full">
                <AddressGeoApi
                    addressGeoValue={setAddress}
                    addressValue={address}
                    page={'profileAddress'}
                />
            </div>
            <CheckFilesIdentity key={CheckFilesIdentity} files={[proof]} />
        </>,
        <Secondform attest={setAttestation}/>,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[attestation]} />,
        <Threeform setFormIndex={setFormIndex} setCheck={setCheck} />,
        <Fourform single={setProof} />,
        <>
            <div className="w-full">
                <AddressGeoApi
                    addressGeoValue={setAddress}
                    addressValue={address}
                    page={'profileAddress'}
                />
            </div>
            <CheckFilesIdentity key={CheckFilesIdentity} files={[proof]} />
        </>,
        <Fiveform attest={setAttestation}/>,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[attestation]} />,
    ]

    useEffect(() => {
        if (tempWorkerData?.addresses?.length > 0) {
            ApiDatabase.getAddress({address: tempWorkerData.addresses},
                (data) => {
                    tempWorkerData.addresses = data;
                    setAddress(data);
                    addressValid(true)
                },
                (err) => {
                    console.error(err)
                })
        }
    }, [tempWorkerData]);

    useEffect(() => {
        if(tempWorkerData){
            if (tempWorkerData?.addresses !== address && Object.keys(address).length > 0) {
                //Insert address in database
                ApiDatabase.postUpdateAddress(
                    {
                        token: localStorage.getItem('xsrfToken'),
                        address: address
                    },
                    (data) => {
                        addressValid(true)
                        toast.success('L\'adresse a été modifiée.')
                    }
                )
            }
        }
    }, [address]);

    return (
        <>
            {
                !check && (
                    <Indicator formIndex={formIndex}/>
                )
            }
            {
                check ? (
                    <>
                        {elements.map((item, index) => {
                            if((index + 1) === formIndex){
                                return elements[index]
                            }
                        })}
                    </>
                ) : (
                    <>
                        {elements2.map((item, index) => {
                            if((index + 1) === formIndex){
                                return elements2[index]
                            }
                        })}
                    </>
                )
            }
        </>
    );
};

export default Multiform;