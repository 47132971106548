import {useEffect, useState} from 'react';
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";

const Usefetchdata = () => {
    const {refreshUser, refreshWorker,setTempData} = UseContext()
    const [userData, setUserData] = useState([])
    const [tempWorkerData, setTempWorkerData] = useState([])

    useEffect(() => {

        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.getUserProfile({token},
            (data => {
                setUserData(data)

            }),
            (err =>
            {
                localStorage.removeItem('xsrfToken')
                localStorage.removeItem('rUser')
                window.location.href=process.env.REACT_APP_INTERIM_LOGIN
            }))

    }, [refreshUser])

    useEffect(() => {
        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.getTempWorker({token},
            (data => {
                setTempWorkerData(data)
                setTempData(data)
            }),
            (err => console.error(err)))
    }, [refreshWorker])
    return {
        userData, tempWorkerData
    }

}

export default Usefetchdata;