import Badge from "components/badge/badge";
import {NavLink} from "react-router-dom";

const CardProfileCompletionDashboard = ({text, link, value}) => {

    return (
        <NavLink to={link} className={'md:border md:rounded'}>
            <div className={'flex justify-between md:w-[354.67px] px-0 py-3 md:px-4 md:py-5'}>
                <div className={'my-auto'}>
                    {text}
                </div>
                <div className={'my-auto'}>
                    <Badge type={'WARNING'}>+{value}%</Badge>
                </div>
            </div>
        </NavLink>
    );
}

export default CardProfileCompletionDashboard;