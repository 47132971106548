import React from "react";
import { DocumentIcon, Pencil } from "assets/icons";
import BadgeSquare from "components/badge/badgeSquare";

const Confirmation = ({ setFormIndex, userData, nationality }) => {
    const pIndex = (p) => p === "NUE";
    const handleModifyPI = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(6);
        else setFormIndex(5);
    };

    const handleModifySelfie = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(7);
        else setFormIndex(6);
    };

    const handleModifyAttest = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(8);
    };
    return (
        <div className={"flex flex-col gap-4"}>
            <div className="flex items-center justify-between">
                <div className="flex gap-2">
                    <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                        <DocumentIcon wh={35} color={"#111827"} />
                    </div>
                    <div className="col-span-3 ft-b text-gray-900 my-auto">
                        <div className={`text-gray-900`}>Pièce d'identité</div>
                    </div>
                </div>
                <div className="col-span-1 flex">
                    <div className="my-auto mr-2">
                        {userData?.identity?.validated !== "0" ? (
                            <BadgeSquare type={userData?.identity?.validated} />
                        ) : null}
                    </div>
                    {userData?.identity?.validated === "1" ||
                    userData?.identity?.validated === "3" ? (
                        <div className="my-auto">
                            <button
                                onClick={handleModifyPI}
                                className="rounded-full w-10 h-10 flex items-center justify-center"
                            >
                                <Pencil wh={22} color={"#374151"} />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className="flex gap-2">
                    <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                        <DocumentIcon wh={35} color={"#111827"} />
                    </div>
                    <div className="col-span-3 ft-b text-gray-900 my-auto">
                        <div className={`text-gray-900`}>Selfie d'identité</div>
                    </div>
                </div>
                <div className="col-span-1 flex">
                    <div className="my-auto mr-2">
                        {userData?.selfie?.validated !== "0" ? (
                            <BadgeSquare type={userData?.selfie?.validated} />
                        ) : null}
                    </div>
                    {userData?.selfie?.validated === "1" ||
                    userData?.selfie?.validated === "3" ? (
                        <div className="my-auto">
                            <button
                                onClick={handleModifySelfie}
                                className="rounded-full w-10 h-10 flex items-center justify-center"
                            >
                                <Pencil wh={22} color={"#374151"} />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {userData?.visa?.validated === "1" ||
            userData?.visa?.validated === "3" ||
            nationality === "NUE" ? (
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                            <DocumentIcon wh={35} color={"#111827"} />
                        </div>
                        <div className="col-span-3 ft-b text-gray-900 my-auto">
                            <div className={`text-gray-900`}>
                                Autorisation de travail
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex">
                        <div className="my-auto mr-2">
                            {userData?.visa?.validated !== "0" ? (
                                <BadgeSquare type={userData?.visa?.validated} />
                            ) : null}
                        </div>
                        {userData?.visa?.validated === "1" ||
                        userData?.visa?.validated === "3" ? (
                            <div className="my-auto">
                                <button
                                    onClick={handleModifyAttest}
                                    className="rounded-full w-10 h-10 flex items-center justify-center"
                                >
                                    <Pencil wh={22} color={"#374151"} />
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Confirmation;
