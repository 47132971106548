import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import {useEffect, useRef, useState} from "react";
import useModal from "../../../useModal";
import ApiDatabase from "server";
import usefetchdata from "../../../../../hooks/usefetchdata";
import UseContext from "hooks/useContext.js";

const ModalChangePreferenceDepartment = () => {
    const {tempWorkerData} = usefetchdata()
    const {toggle} = useModal()
    const {refreshWorker, setRefreshWorker} = UseContext()
    const [isLoading, setIsLoading] = useState(false)
    const [validFormAddress, setValidFormAddress] = useState(false)
    const [address, setAddress] = useState('')
    const [searchAddress, setSearchAddress] = useState('')
    const [addressListChoice, setAddressListChoice] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [selectedAddress, setSelectedAddress] = useState({
        city: null,
        department: null
    })
    const dropDownRef = useRef(null)
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        ApiDatabase.getCities({}, (data) => {
            setAddressList(data)
        }, (err) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        //fuzzy search on addressList with searchAddress
        const addressChoices = []
        for (let i = 0; i < addressList.length; i++) {
            //compare searchAddress with addressList[i].departement and addressList[i].name, escape special characters and accents and case insensitive and spaces and uppercase
            //replace œ by oe, replace éèêë by e, replace àâä by a, replace ùûü by u, replace îï by i, replace ôö by o, replace ç by c, replace characters other than letters by nothing
            const regex = new RegExp(searchAddress.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''), 'i')
            if (regex.test(addressList[i].name.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''))) {
                addressChoices.push(addressList[i])
            }
        }
        setAddressListChoice(addressChoices)
        if (searchAddress && searchAddress !== (selectedAddress?.city + ', ' + selectedAddress?.department)) {
            setSelectedAddress({city: null, department: null})
            setAddress('')
            setValidFormAddress(false)
        }
    }, [searchAddress, dropdownOpen])

    const selectAddress = (address) => {
        setSearchAddress(`${address.name}, ${address.departement}`)
        setAddressListChoice([])
        setSelectedAddress({city: address.name, department: address.departement})
        setAddress(address)
        setValidFormAddress(true)
    }

    useEffect(() => {
        if (tempWorkerData.preferences_department) {
            if (tempWorkerData.preferences_department?.city && tempWorkerData.preferences_department?.department) {
                setSelectedAddress({city: tempWorkerData.preferences_department?.city, department: tempWorkerData.preferences_department?.department})
                setSearchAddress(`${tempWorkerData.preferences_department?.city}, ${tempWorkerData.preferences_department?.department}`)
                setAddressListChoice([])
                setAddress(tempWorkerData.preferences_department)
                setValidFormAddress(true)
            }
        }
    }, [tempWorkerData.preferences_department])

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            } else {
                setDropdownOpen(true)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        ApiDatabase.updPreferenceDepartment({token, preferences_department: selectedAddress}, (data) => {
            setRefreshWorker(!refreshWorker)
            setIsLoading(false)
            toggle()
        }, (err) => {
            setIsLoading(false)
            console.log(err)
        })
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Changer la localisation
            </Modalheader>
            <ModalBody>
                <div className={`flex flex-col gap-3 ${(dropdownOpen && !selectedAddress?.city && !selectedAddress?.department) && 'mb-40'}`}>
                    <div className={'text-gray-500'}>
                        L’adresse va nous servir à vous proposer les meilleurs missons proche de chez vous.
                    </div>
                    <div className="flex flex-col">
                        <label className="block ft-sm font-medium text-gray-500">
                            Ville
                        </label>
                        <div className="relative text-gray-500" ref={dropDownRef}>
                            <input
                                name="address"
                                value={searchAddress}
                                onChange={(e) => setSearchAddress(e.target.value)}
                                className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg`}
                                type={'text'}
                                placeholder={'Rechercher une ville ...'}
                                required
                            />
                            {(dropdownOpen && !selectedAddress?.city && !selectedAddress?.department) && (
                                <>
                                    {addressListChoice.length > 0 ? (
                                        <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                            <div className={'bg-white border rounded'}>
                                                {addressListChoice.map((address, index) => (
                                                    <>
                                                        <div key={index} className={`flex flex-col gap-2 p-2 ${addressListChoice.length - 1 !== index && 'border-b'}`}>
                                                            <button className={'flex flex-row gap-2 items-center'}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        selectAddress(address)
                                                                    }}>
                                                            <span className={'ft-sm'}>{address.name}, {address.departement}</span>
                                                            </button>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                            <div className={'bg-white border rounded'}>
                                                <div className={`flex flex-col gap-2 p-2`}>
                                                    <p className={'ft-sm'}>Aucun résultat</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                {isLoading ? <Button size={'LG'} color={'DISABLED'} disabled={true}>Chargement...</Button> :
                    <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>}
            </ModalFooter>
        </>
    )
}

export default ModalChangePreferenceDepartment;