import React from 'react';
import Tooltips from "../../tooltips/Tooltips";
import {InformationCircle} from "../../../assets/icons";

const Thead = ({champsHeader, isWaiting}) => {
    return (
        <>
            <tr>
                {
                    isWaiting ? (
                        <>
                        {
                            champsHeader?.map((title, index) => (
                                <td className={'px-2 py-7'}>
                                    <div className="h-2 bg-gray-200 rounded"></div>
                                </td>
                            ))
                        }
                        </>
                    ) : (
                        <>
                            {
                                champsHeader?.map(item =>
                                    <th className={'m-3 text-left'}>{
                                        item === 'ESTIMATION' ? (
                                            <div className={'flex gap-2'}>
                                                <div className={'my-auto'}>
                                                    ESTIMATION
                                                </div>
                                                <div className={'my-auto cursor-pointer'}>
                                                    <Tooltips
                                                        text={`Le salaire brut estimé est basé sur les heures travaillées et n\'inclut pas les heures de nuit ou les primes. BETA`}
                                                        placement='top'
                                                    >
                                                        <div><InformationCircle wh={20} color={'#6B7280'}/></div>
                                                    </Tooltips>
                                                </div>
                                            </div>
                                        ) : (
                                            item.toUpperCase()
                                        )
                                    }</th>
                                )
                            }
                            <th></th>
                        </>
                    )
                }
            </tr>
        </>
    );
};

export default Thead;