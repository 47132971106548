import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";

const Modalcompetence = () => {
    const {toggle}=useModal()
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>Ajouter une compétence</Modalheader>
                <ModalBody>
                    En attente Modal Compétence
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                    <Button size={'LG'}>Confirmer</Button>
                </ModalFooter>
        </>
    );
};

export default Modalcompetence;