import React, {useEffect, useRef, useState} from 'react';
import PageForm from "components/pageForm/PageForm";
import Cardsearchmission from "components/card/cardsearchmission/cardsearchmission";
import axios from "axios";
import MediaQuery from "react-responsive";
import {CheckIcon, ChevronDown, Filter, Localisation, LoopIcon, Plus} from "assets/icons";
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";
import useContext from "../../hooks/useContext";
import useModal from "../../components/modal/useModal";
import ApiDatabase from 'server'
import Button from "../../components/button";
import useTitle from "../../hooks/TitleManager";

const SearchMission = () => {
    useTitle({ title: "Recherche de missions" });
    const {toggle} = useModal()
    const {userData, tempWorkerData} = usefetchdata()
    const {setIsPostulateWithoutAvailability} = useContext()
    const [consulte, setConsulte] = useState(false)
    const [postule, setPostule] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [isWaiting, setIsWaiting] = useState(true);

    //Partie filtre
    const [job, setJob] = useState('')
    const [jobProv, setJobProv] = useState('')
    const [listMission, setListMission] = useState([])
    const [listMissionUserInvite, setListMissionUserInvite] = useState([])
    const [typesQualifications, setTypesQualifications] = useState([])
    const [dropdownSectorOpen, setDropdownSectorOpen] = useState(false)
    const [dropdownFilterOpen, setDropdownFilterOpen] = useState(false)
    const dropDownSectorRef = useRef(null)
    const dropDownFilterRef = useRef(null)
    const token = localStorage.getItem('xsrfToken')

    const qualificationsTypes = {
        'sale': {
            name: 'Vente',
            icon: '🛍',
            isOpen: false,
        },
        'food': {
            name: 'Restauration',
            icon: '🍳',
            isOpen: false,
        },
        'logistic': {
            name: 'Logistique',
            icon: '📦',
            isOpen: false,
        },
        'administration': {
            name: 'Administration',
            icon: '🧾',
            isOpen: false,
        },
        'largeDistribution': {
            name: 'Grande distribution',
            icon: '🛒',
            isOpen: false,
        },
        'other': {
            name: 'Autre',
            icon: '👷',
            isOpen: false,
        },
    };

    const [selectTypesQualifications, setSelectTypesQualifications] = useState(qualificationsTypes)
    const [finalSelectTypesQualifications, setFinalSelectTypesQualifications] = useState([])

    let timerMissionSearch = null;

    const handleFilter = (e) => {
        setFilterOpen(!filterOpen)
    }

    const handleConsulte = (e) => {
        setConsulte(!consulte)
    }
    //Filter Mission
    const manageMissionSearch = async () => {
        setIsWaiting(true)

        ApiDatabase.searchMission({token, search: job, department: tempWorkerData?.preferences_department?.department ?? '', postule: postule, typesQualifications: JSON.stringify(finalSelectTypesQualifications)}, (data) => {
            setListMission(data.listMissions)
            setListMissionUserInvite(data.listMissionsInvite)
            setIsWaiting(false)
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (userData?._id && tempWorkerData?._id) {
            timerMissionSearch = setTimeout(async () => {
                await manageMissionSearch();
            }, 400);
            return () => clearTimeout(timerMissionSearch);
        }
    }, [job, finalSelectTypesQualifications, postule])

    useEffect(() => {
        if (userData?._id && tempWorkerData?._id) {
            manageMissionSearch(false);
        }
    }, [userData, tempWorkerData?.preferences_department?.department])

    useEffect(() => {
        setIsPostulateWithoutAvailability({})
    }, []);

    const changePreferenceDepartment = () => {
        toggle('change_preference_department')
    }

    useEffect(() => {
        let handler = (e) => {
            if (dropDownSectorRef.current && !dropDownSectorRef.current.contains(e.target)) {
                setDropdownSectorOpen(false)
            }
            if (dropDownFilterRef.current && !dropDownFilterRef.current.contains(e.target)) {
                setDropdownFilterOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    useEffect(() => {
        ApiDatabase.getTypesQualifications({token}, (data) => {
            setTypesQualifications(data)
        }, (err) => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        let finalArray = []
        Object.entries(selectTypesQualifications).map(([key, value]) => {
            if (value.isOpen) {
                finalArray.push(key)
            }
        })
        setFinalSelectTypesQualifications(finalArray)
        console.log(finalArray)
    }, [selectTypesQualifications])

    const searchMissionClick = () => {
        setJob(jobProv)
        setDropdownFilterOpen(false)
    }

        return (
            <PageForm>
                <MediaQuery minWidth={769}>
                    <div className='flex flex-col overflow-y-auto h-full'>
                        <div className={'sticky w-full flex justify-center p-3 border-b top-0 bg-white z-20'}>
                            <div className={'flex gap-3 max-w-[800px] w-full'}>
                                <div className="relative w-full">
                                    <input value={job}
                                           onChange={(e) => setJob(e.target.value)}
                                           className="w-full h-10 pl-9 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md text-sm"
                                           type="text"
                                           placeholder='Recherchez par poste...'
                                           required
                                    />
                                    <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                        <LoopIcon wh={20} color={'#6B7280'}/>
                                    </div>
                                </div>
                                <div className={'max-w-[233px] w-full'}>
                                    <div className="relative w-full" onClick={() => changePreferenceDepartment()}>
                                        <div className="w-full h-10 pl-9 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md text-sm flex items-center cursor-pointer">
                                            {tempWorkerData?.preferences_department?.department ?? 'Martinique & Guadeloupe'}
                                        </div>
                                        <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                            <Localisation wh={20} color={'#6B7280'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center my-26 px-4'>
                            <div className='flex flex-col max-w-[550px] w-full mb-60 gap-8'>
                                <div className={'w-full flex gap-2 py-3 z-10'}>
                                    <div className={'flex gap-3'}>
                                        <button
                                            className={`border rounded-lg text-gray-500 flex gap-2 items-center px-3 py-2 ${postule && ('border-yellow-500 bg-yellow-50 text-yellow-500')}`}
                                            onClick={() => setPostule(!postule)}>
                                            <div className={''}>Candidatures</div>
                                            <div className={''}>
                                                {postule ? (
                                                    <CheckIcon w={20} h={13} color={'#FF9600'}/>
                                                ) : (
                                                    <Plus wh={20} color={'#6B7280'}/>
                                                )}
                                            </div>
                                        </button>
                                    </div>
                                    <div className={'flex gap-3 relative'} ref={dropDownSectorRef}>
                                        <button
                                            className={'border rounded-lg text-gray-500 flex gap-2 items-center px-3 py-2'}
                                            onClick={() => setDropdownSectorOpen(!dropdownSectorOpen)}>
                                            <div className={''}>Secteur</div>
                                            <div className={''}>
                                                <ChevronDown w={20} h={9} color={'#6B7280'}/>
                                            </div>
                                        </button>
                                        {dropdownSectorOpen && (
                                            <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto min-w-[202px]'}>
                                                <div className={'bg-white border rounded'}>
                                                    {typesQualifications?.map((type, index) => (
                                                        <div key={index}
                                                             className={`flex flex-col gap-2 ${typesQualifications.length - 1 !== index && 'border-b'}`}>
                                                            <div
                                                                className={`flex flex-row gap-2 p-2 items-center cursor-pointer`}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setSelectTypesQualifications({
                                                                        ...selectTypesQualifications,
                                                                        [type]: {
                                                                            ...selectTypesQualifications[type],
                                                                            isOpen: !selectTypesQualifications[type].isOpen
                                                                        }
                                                                    })
                                                                }}>
                                                                <label className={'flex gap-2 my-auto'}>
                                                                    <input type={'checkbox'}
                                                                           className={'my-auto w-4 h-4 accent-yellow-400'}
                                                                           checked={selectTypesQualifications[type]?.isOpen}
                                                                    />
                                                                    <div className={'my-auto'}>{qualificationsTypes[type]?.icon}</div>
                                                                    <div className={'my-auto text-sm'}>{qualificationsTypes[type]?.name}</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {
                                    isWaiting ? (
                                        <>
                                            {
                                                [1, 2, 3, 4, 5].map((i, index) => (
                                                    <Cardsearchmission key={i} data={i} isWaiting={isWaiting}/>
                                                ))
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                !postule && listMissionUserInvite?.map(mission =>
                                                    <Cardsearchmission key={mission._id} data={mission}
                                                                       validation={userData.validation}
                                                                       isUserInvite={true}/>
                                                )
                                            }
                                            {
                                                listMission.length > 0 ?
                                                    listMission?.map(mission =>
                                                        <Cardsearchmission key={mission._id} data={mission}/>
                                                    )
                                                    : <EmptyState type={'mission_search'}/>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                    <div className={`flex flex-col mb-60 mt-16 mx-2`}>
                        <div className={'sticky w-full flex gap-2 py-3 border-b z-20'}>
                            <div className={'flex gap-2 items-center justify-center w-full relative'}
                                 ref={dropDownFilterRef}>
                                <div>Recherche de mission</div>
                                <div className={'p-2 border border-gray-100 rounded cursor-pointer'}
                                     onClick={() => setDropdownFilterOpen(!dropdownFilterOpen)}>
                                    <Filter wh={20} color={'#374151'}/>
                                </div>
                                {dropdownFilterOpen && (
                                    <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto min-w-[202px]'}>
                                        <div className={'bg-white border rounded'}>
                                            <div className={`flex flex-col gap-2 p-2`}>
                                                <div className="relative w-full">
                                                    <input value={jobProv}
                                                           onChange={(e) => setJobProv(e.target.value)}
                                                           className="w-full h-10 pl-9 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md text-sm"
                                                           type="text"
                                                           placeholder='Recherchez par poste...'
                                                           required
                                                    />
                                                    <div
                                                        className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                        <LoopIcon wh={20} color={'#6B7280'}/>
                                                    </div>
                                                </div>
                                                <div className="relative w-full"
                                                     onClick={() => changePreferenceDepartment()}>
                                                    <div
                                                        className="w-full h-10 pl-9 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md text-sm flex items-center cursor-pointer">
                                                        {tempWorkerData?.preferences_department?.department ?? 'Martinique & Guadeloupe'}
                                                    </div>
                                                    <div
                                                        className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                        <Localisation wh={20} color={'#6B7280'}/>
                                                    </div>
                                                </div>
                                                <div className={'flex justify-end'}>
                                                    <Button color={'PRIMARY'} size={'SM'}
                                                            onClick={() => searchMissionClick()}>Confirmer</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'w-full flex gap-2 py-3 z-10'}>
                            <div className={'flex gap-3'}>
                                <button
                                    className={`border rounded-lg text-gray-500 flex gap-2 items-center px-3 py-2 ${postule && ('border-yellow-500 bg-yellow-50 text-yellow-500')}`}
                                    onClick={() => setPostule(!postule)}>
                                    <div className={''}>Candidatures</div>
                                    <div className={''}>
                                        {postule ? (
                                            <CheckIcon w={20} h={13} color={'#FF9600'}/>
                                        ) : (
                                            <Plus wh={20} color={'#6B7280'}/>
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div className={'flex gap-3 relative'} ref={dropDownSectorRef}>
                                <button className={'border rounded-lg text-gray-500 flex gap-2 items-center px-3 py-2'}
                                        onClick={() => setDropdownSectorOpen(!dropdownSectorOpen)}>
                                    <div className={''}>Secteur</div>
                                    <div className={''}><ChevronDown w={20} h={9} color={'#6B7280'}/></div>
                                </button>
                                {dropdownSectorOpen && (
                                    <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto min-w-[202px]'}>
                                        <div className={'bg-white border rounded'}>
                                            {typesQualifications?.map((type, index) => (
                                                <div key={index}
                                                     className={`flex flex-col gap-2 ${typesQualifications.length - 1 !== index && 'border-b'}`}>
                                                    <div className={`flex flex-row gap-2 p-2 items-center cursor-pointer`}
                                                         onClick={(e) => {
                                                             e.preventDefault()
                                                             setSelectTypesQualifications({
                                                                 ...selectTypesQualifications,
                                                                 [type]: {
                                                                     ...selectTypesQualifications[type],
                                                                     isOpen: !selectTypesQualifications[type].isOpen
                                                                 }
                                                             })
                                                         }}>
                                                        <label className={'flex gap-2 my-auto'}>
                                                            <input type={'checkbox'}
                                                                   className={'my-auto w-4 h-4 accent-yellow-400'}
                                                                   checked={selectTypesQualifications[type]?.isOpen}
                                                            />
                                                            <div className={'my-auto'}>{qualificationsTypes[type]?.icon}</div>
                                                            <div className={'my-auto text-sm'}>{qualificationsTypes[type]?.name}</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'flex flex-col gap-8'}>
                            {
                                isWaiting ? (
                                    <>
                                        <div className="animate-pulse">
                                            {
                                                [1, 2, 3, 4, 5].map((i, index) => (
                                                    <Cardsearchmission key={i} data={i} isWaiting={isWaiting}/>
                                                ))
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {
                                            !postule && listMissionUserInvite?.map(mission =>
                                                <Cardsearchmission key={mission._id} data={mission}
                                                                   validation={userData.validation}
                                                                   isUserInvite={true}/>
                                            )
                                        }
                                        {
                                            listMission.length > 0 ?
                                                listMission?.map(mission =>
                                                    <Cardsearchmission key={mission.company} data={mission}/>
                                                )
                                                : <EmptyState type={'mission_search'}/>
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                </MediaQuery>
            </PageForm>
        );
};

export default SearchMission;