import {Check, Exclamation} from "assets/icons";
import React from "react";

const CircleStatus = ({status,children,...props})=> {
    const bg = {
        '0': 'bg-red-50',
        '3': 'bg-red-50',
        '2': 'bg-green-50',
    }
    const logo = {
        '0': <Exclamation wh={14} color={'#EF4444'}/>,
        '3': <Exclamation wh={14} color={'#EF4444'}/>,
        '2': <Check wh={14} color={'#10B981'}/>,
    }

    if(bg[status] === undefined && logo[status] === undefined) return null

    return (
        <>
            <div className={`absolute bottom-0 right-0 flex items-center justify-between`}>
                <div
                    className={`${bg[status]} w-[18px] h-[18px] rounded-full flex items-center border-2 border-white`}>
                    <div className={`mx-auto`}>
                        {logo[status]}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CircleStatus;