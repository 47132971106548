import React, {useEffect, useState} from "react";
import Banner from "components/banner/banner";
import Divider from "components/divider/divider";
import CardIdentityValidationLine from "components/card/CardIndentityValidation/CardIndentityValidationLine";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import useTitle from "../../../hooks/TitleManager";

const Identity = () => {
    useTitle({ title: "Profil - Mes justificatifs - Identité" });
    const { tempData } = UseContext();
    const [typeIdentity, setTypeIdentity] = useState(tempData?.identity?.validated);
    const [typeAddress, setTypeAddress] = useState(tempData?.proof_of_address?.validated);

    useEffect(() => {
        if(tempData){
            //identity part
            if(tempData?.identity?.validated === "2" || tempData?.selfie?.validated === "2" || (tempData?.visa?.type && tempData?.visa?.validated === "2")){
                setTypeIdentity(2)
            }
            if(tempData?.identity?.validated === "0" || tempData?.selfie?.validated === "0" || (tempData?.visa?.type && tempData?.visa?.validated === "0")){
                setTypeIdentity(0)
            }
            if(tempData?.identity?.validated === "1" || tempData?.selfie?.validated === "1" || (tempData?.visa?.type && tempData?.visa?.validated === "1")){
                setTypeIdentity(1)
            }
            if(tempData?.identity?.validated === "3" || tempData?.selfie?.validated === "3" || (tempData?.visa?.type && tempData?.visa?.validated === "3")){
                setTypeIdentity(3)
            }

            //address part
            if(tempData?.proof_of_address?.validated === "2" || tempData?.accommodation_of_address?.validated === "2"){
                setTypeAddress(2)
            }
            if(tempData?.proof_of_address?.validated === "0"){
                setTypeAddress(0)
            }
            if(tempData?.proof_of_address?.validated === "1" || tempData?.accommodation_of_address?.validated === "1"){
                setTypeAddress(1)
            }
            if(tempData?.proof_of_address?.validated === "3" || tempData?.accommodation_of_address?.validated === "3"){
                setTypeAddress(3)
            }
        }
    }, [tempData]);

    const { toggle } = useModal();
    return (
        <div className="flex  flex-col mb-6 mt-32 md:mt-0">
            <div className="">
                <h1 className="ft-xl text-gray-700 py-2 text-center md:text-start ">
                    Justificatifs d’identité
                </h1>
                <p className="ft-b text-gray-500 text-center md:text-start">
                    Ces documents seront utilisés pour valider votre identité,
                    créer votre contrat et vous payer. Validation en 48h
                    maximum.
                </p>
            </div>
            <div className="py-4">
                <Banner type="info">
                    Nous ne gardons pas ces données, elles sont uniquement
                    traitées afin de valider votre inscription dans notre
                    agence.
                </Banner>
            </div>
            <div className={'my-5'}>
                <Divider/>
            </div>
            <div className="py-2">
                <CardIdentityValidationLine
                    type={"identity"}
                    onClick={(e) => {
                        e.preventDefault();
                        if (typeIdentity === 0) {
                            toggle("j_c_identityImport");
                        }
                        if (
                            typeIdentity === 1 ||
                            typeIdentity === 3
                        ) {
                            toggle("j_u_identiy");
                        }
                    }}
                    status={typeIdentity}
                />
            </div>
            <div className="py-2">
                <CardIdentityValidationLine
                    type={"vital_card"}
                    onClick={(e) => {
                        e.preventDefault();
                        toggle("j_c_vitale");
                    }}
                    status={tempData?.card_vitality?.validated}
                />
            </div>
            <div className="py-2">
                <CardIdentityValidationLine
                    type={"proof_address"}
                    onClick={(e) => {
                        e.preventDefault();
                        toggle("j_c_proof");
                    }}
                    status={typeAddress}
                />
            </div>
            <div className="py-2">
                <CardIdentityValidationLine
                    type={"bank_id"}
                    onClick={(e) => {
                        e.preventDefault();
                        tempData?.bank?.validated === "0"
                            ? toggle("j_c_iban")
                            : toggle("j_u_iban");
                    }}
                    status={tempData?.bank?.validated}
                />
            </div>
        </div>
    );
};

export default Identity;
