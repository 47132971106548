import React, { useEffect, useState } from "react";
import Modalheader from "components/modal/components/header";
import useModal from "components/modal/useModal";

import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Firstform from "components/modal/dataModal/data/modaljustify/address/component/body/firstform";
import Multiform from "components/modal/dataModal/data/modaljustify/address/component/multiform";
import Button from "components/button";
import { CheckIcon } from "assets/icons";
import axios from "axios";
import { toast } from "react-toastify";
import usefetchdata from "hooks/usefetchdata";
import Footerbutton from "components/modal/dataModal/data/modaljustify/address/component/footer/footerbutton";
import UseContext from "hooks/useContext";
import LoadingButton from "components/button/loading.js";

const Proofaddress = () => {
    const { toggle } = useModal();
    const { tempWorkerData } = usefetchdata();
    const { language, refreshLoadWorker } = UseContext();
    const [check, setCheck] = useState(true);
    const [formIndex, setFormIndex] = useState(1);
    const [proof, setProof] = useState("");
    const [address, setAddress] = useState(false);
    const [files, setFiles] = useState(false);
    const [attestation, setAttestation] = useState("");
    const [loading, setLoading] = useState(false);

    const pAddressMulti =
        tempWorkerData?.proof_of_address?.validated === "1" &&
        tempWorkerData?.accommodation_of_address?.validated === "1";

    const pAddressSingle = tempWorkerData?.proof_of_address?.validated === "1";
    useEffect(() => {
        if (pAddressMulti && formIndex < 4 && pAddressSingle && formIndex < 4) {
            setFormIndex(5);
            setFiles(true);
            setCheck(false);
        } else {
            setFiles(false);
        }
    }, [formIndex, tempWorkerData]);

    const singleFile = async (data) => {
        try {
            setLoading(true);
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}upload/singleFile/proofAddress`,
                    data
                )
                .then((res) => {
                    if (res.data) {
                        if (formIndex === 4) {
                            toast.success(
                                'Le document "Justificatif de domicile" est modifié.'
                            );
                            setFormIndex(3);
                            toggle();
                            refreshLoadWorker();
                        } else {
                            toast.success(
                                'Le document "Justificatif de domicile" est ajouté.'
                            );
                            toggle();
                            refreshLoadWorker();
                        }
                    }
                    if (res.error) {
                        toast.error("Un problème est survenu");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error("Un problème est survenu");
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const secondFile = async (data) => {
        try {
            setLoading(true);
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}upload/singleFile/accommodationCertificate`,
                    data
                )
                .then((res) => {
                    if (res.data) {
                        setLoading(false);
                        toast.success(
                            'Le document "Attestation de domicile" est modifié.'
                        );
                        refreshLoadWorker();
                        setFormIndex(3);
                        toggle();
                    }
                    if (res.errors) {
                        setLoading(false);
                        toast.error("Un problème est survenu");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error("Un problème est survenu");
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const multiFiles = async (firstFile, secondFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/proofAddress`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/accommodationCertificate`,
                file: secondFile,
            },
        ];

        let isAllFilesSent = false;
        setLoading(true);
        const idToastLoad = toast.loading("Envoi des fichiers en cours");
        for (let i = 0; i < files.length; i++) {
            const result = await axios.post(files[i].url, files[i].file);
            if (result.status === 201) {
                setLoading(false);
                isAllFilesSent = true;
            } else {
                setLoading(false);
                isAllFilesSent = false;
                break;
            }
        }
        if (isAllFilesSent) {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Fichiers uploadés",
                type: "success",
                autoClose: 3000,
                isLoading: false,
            });
            setProof("");
            setAttestation("");
            refreshLoadWorker();
            toggle();
        } else {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Erreur d'envoi des fichiers",
                type: "error",
                isLoading: false,
            });
        }
    };

    const handleCheck = (e) => {
        e.preventDefault();
        setCheck(!check);
    };

    const handleIndex = (e) => {
        e.preventDefault();

        if(((formIndex === 1 || formIndex === 2 || formIndex === 6) && proof) || ((formIndex === 3 || formIndex === 4 || formIndex === 8) && attestation)){
            setFormIndex(formIndex + 1);
        } else {
            toast.warning("Veuillez importer un fichier");
        }
    };

    const handleSubmitSingle = async (e) => {
        e.preventDefault();
        if (proof !== "" && address) {
            const token = localStorage.getItem("xsrfToken");
            const formData = new FormData();
            formData.append("file", proof);
            formData.append("token", token);
            await singleFile(formData);
        } else toast.warning("Veuillez télécharger un fichier et ajouter une adresse");
    };

    const handleSubmitMultiSingle = async (e) => {
        if ((proof !== "" || attestation !== "") && !files && address) {
            e.preventDefault();
            const token = localStorage.getItem("xsrfToken");
            const formData = new FormData();
            formData.append("file", proof);
            formData.append("token", token);
            const formDataSecond = new FormData();
            formDataSecond.append("file", attestation);
            formDataSecond.append("token", token);
            await multiFiles(formData, formDataSecond);
        } else {
            if(proof === '' && attestation === '') return toggle();
            console.log(proof, attestation, files, address)
            toast.warning("Veuillez télécharger les fichiers et ajouter une adresse");
        }
    };

    const handleSubmitProof = async (e) => {
        e.preventDefault();
        if (proof !== "" && address) {
            const token = localStorage.getItem("xsrfToken");
            const formData = new FormData();
            formData.append("file", proof);
            formData.append("token", token);
            await singleFile(formData);
        } else toast.warning("Veuillez télécharger un fichier et ajouter une adresse");
    };

    const handleSubmitAttest = async (e) => {
        e.preventDefault();
        if (attestation !== "") {
            const token = localStorage.getItem("xsrfToken");
            const formDataSecond = new FormData();
            formDataSecond.append("file", attestation);
            formDataSecond.append("token", token);
            await secondFile(formDataSecond);
        } else toast.warning("Veuillez télécharger un fichier");
    };

    useEffect(() => {
        if(tempWorkerData?.addresses)
            setAddress(true)
    }, [tempWorkerData])

    return (
        <>
            <Modalheader hideShow={true} hide={toggle}>
                {language.getValue("justify.m_proof_title_c")}
            </Modalheader>
            <ModalBody>
                <Multiform
                    formIndex={formIndex}
                    setFormIndex={setFormIndex}
                    setProof={setProof}
                    proof={proof}
                    addressValid={setAddress}
                    setAttestation={setAttestation}
                    attestation={attestation}
                    setCheck={setCheck}
                    check={check}
                />
                {formIndex !== 1 ? (
                    ""
                ) : (
                    <div className={" ft-sm mt-4 flex items-center gap-2 cursor-pointer"}
                        onClick={handleCheck}
                    >
                        <div className={`flex items-center justify-center h-4 w-4 border border-gray-300 rounded-sm bg-white ${check ? "bg-yellow-600 border-yellow-600" : ""} focus:outline-none transition duration-200`}>
                            {check ? (
                                <CheckIcon w={14} h={10} color={"#ffff"} />
                            ) : (
                                ""
                            )}
                        </div>
                        Le justificatif d’adresse est à mon nom
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        {
                            (formIndex === 1 || formIndex === 6 || formIndex === 8) ? (
                                <Button
                                    size={"LG"}
                                    color={"SECONDARY"}
                                    onClick={toggle}
                                >
                                    Annuler
                                </Button>
                            ) : (
                                <Button
                                    size={"LG"}
                                    color={"SECONDARY"}
                                    onClick={() => setFormIndex(formIndex - 1)}
                                >
                                    Précédent
                                </Button>
                            )
                        }

                        <Footerbutton
                            formIndex={formIndex}
                            check={check}
                            oC1={handleIndex}
                            oC2={handleSubmitMultiSingle}
                            oC3={handleSubmitProof}
                            oC4={handleSubmitAttest}
                            oC5={handleSubmitSingle}
                        />
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default Proofaddress;
