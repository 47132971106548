import {Check, ChevronRight} from "../../../../assets/icons";
import useModal from "../../../../components/modal/useModal";
import usefetchdata from "../../../../hooks/usefetchdata";
import {useEffect, useState} from "react";
import {dateWordShort} from "../../../../helper/helper";
import useTitle from "../../../../hooks/TitleManager";

const TrainingGuides = () => {
    useTitle({ title: "Profil - Formations & Guides" });
    const {toggle} = useModal()
    const {tempWorkerData} = usefetchdata();

    return (
        <div className='col-span-12 flex justify-center items-center mb-32 tab:mb-0'>
            <div className='md:w-[800px] w-full mx-auto'>
                <div className={'flex  flex-col mb-6 mt-32 md:mt-0'}>
                    <div className={'flex flex-col gap-3'}>
                        <div className={'border border-gray-100 rounded-lg p-3 cursor-pointer'} onClick={() => toggle('welcome_guide')}>
                            <div className={'flex justify-between'}>
                                <div className={'my-auto'}>
                                    <div className={''}>
                                        Guide de bienvenue
                                    </div>
                                    {tempWorkerData?.welcome_guide?.is_read && (
                                        <div className={'ft-sm'}>
                                            Consulté le {dateWordShort(tempWorkerData?.welcome_guide?.updated)}
                                        </div>
                                    )}
                                </div>
                                <div className={'my-auto'}>
                                    {tempWorkerData?.welcome_guide?.is_read ? (
                                        <div className={'rounded p-2 bg-green-50'}>
                                            <Check wh={20} color={'#10B981'}/>
                                        </div>
                                    ) : (
                                        <div className={'p-2'}>
                                            <ChevronRight wh={20} color={'#374151'}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={'border border-gray-100 rounded-lg p-3 cursor-pointer'} onClick={() => toggle('safety_booklet')}>
                            <div className={'flex justify-between'}>
                                <div className={'my-auto'}>
                                    <div className={''}>
                                        Livret de sécurité
                                    </div>
                                    {tempWorkerData?.safety_booklet?.is_read && (
                                        <div className={'ft-sm'}>
                                            Consulté le {dateWordShort(tempWorkerData?.safety_booklet?.updated)}
                                        </div>
                                    )}
                                </div>
                                <div className={'my-auto'}>
                                    {tempWorkerData?.safety_booklet?.is_read ? (
                                        <div className={'rounded p-2 bg-green-50'}>
                                            <Check wh={20} color={'#10B981'}/>
                                        </div>
                                    ) : (
                                        <div className={'p-2'}>
                                            <ChevronRight wh={20} color={'#374151'}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingGuides;