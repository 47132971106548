import React, {useContext, useEffect, useState} from 'react';
import Badge from "components/badge/badge";
import Button from "components/button";
import {Delete, Dots, Modify, Warning} from "assets/icons";
import {pFormation, sBadge, tBadge} from "helper/helper";
import useModal from "components/modal/useModal";
import Divider from "components/divider/divider";
import UseContext from "hooks/useContext";

const CardJustify = ({item, type}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isWarning, setIsWarning] = useState(false)
    const {toggle} = useModal()
    const {setObjID,setObjType} = UseContext()

    useEffect(() => {
        if(type === 'licence'){
            if(!item.licence || !item.file){
                setIsWarning(true)
            }
            else {
                setIsWarning(false)
            }
        }
        else if (type === 'training'){
            if(!item.name || !item.description){
                setIsWarning(true)
            }
            else {
                setIsWarning(false)
            }
        }
    }, [item])

    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const handleDelete = (e) => {
        e.preventDefault()
        setObjID(item._id)
        setObjType(type)
        toggle('j_d_delete')
    }

    const handleModify = (e) => {
        e.preventDefault()
        setObjID(item._id)
        if(type === 'training'){
            toggle('j_u_formation')
        }
        else if (type === 'licence'){
            toggle('j_u_licence')
        }
    }

    return (
        <>
            <div className="grid grid-cols-2">
                <div className="col-span-1 flex flex-col gap-3">
                    <div>
                        {item.licence? item.licence : null}
                        {item.certificate ? pFormation(item.certificate):null}
                        {item.name ? item.name : null}
                        {
                            item.description && (
                                <div className={'ft-sm text-gray-700'}>
                                    {item.description}
                                </div>
                            )
                        }
                    </div>
                    {
                        item.validation ? (
                            <div className='w-32'>
                                <Badge type={sBadge(item.validation)}>{tBadge(item.validation)}</Badge>
                            </div>
                        ) : null
                    }
                </div>
                <div className="relative col-span-1 flex flex-row items-center justify-end gap-4">
                    {isWarning ? <Warning wh={17} color={"#EF4444"}/> : null}
                    <Button size={'SMI'} color={'DEFAULT'} onClick={handleDots}>
                        <Dots w={14} h={4} color={'#374151'}/>
                    </Button>
                    <div className={`${dropdownOpen ? "" : "hidden"}  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-4 z-30 bg-gray-50 flex flex-col gap-2`}>
                        <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={handleModify}>
                            <Modify wh={14} color={'#111827'}/> Modifier
                        </div>
                        <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm text-red-600' onClick={handleDelete}>
                            <Delete color={'#DC2626'} wh={15}/> Supprimer
                        </div>
                    </div>
                </div>
            </div>
            <div className={'my-5'}>
                <Divider w={100}/>
            </div>
        </>

    );
};

export default CardJustify;