import React, {useEffect, useState} from "react";
import axios from "axios";

import EmptyState from "../EmptyState/EmptyState";
import usefetchdata from "../../hooks/usefetchdata";
import {NavLink} from "react-router-dom";
import {SearchMissionIcon} from "assets/icons";
import Divider from "components/divider/divider";
import CardSearchMissionDashboard from "./cardSearchMissionDashboard";
import useWindowDimensions from "hooks/useWindowDimensions";
import Button from "components/button";

const MissionSearch = () => {
    const {userData} = usefetchdata();

    const [resultsFound, setResultsFound] = useState(false);
    const [missionListResult, setMissionListResult] = useState([]);
    const [missionColumns, setMissionColumns] = useState(3);
    const [nbMissionDisplay, setNbMissionDisplay] = useState(3);
    const {height, width} = useWindowDimensions();

    const getMissionList = async () => {
        const missionList = await axios.get(`${process.env.REACT_APP_API_URL}mission`);

        if (missionList.data) {
            setResultsFound(true);
            setMissionListResult(missionList.data.slice(0, 3));
        }
    };

    useEffect(() => {
        getMissionList();
    }, []);

    useEffect(() => {
        if (width > 1200) {
            setMissionColumns(3);
            setNbMissionDisplay(3);
        } else if (width > 900) {
            setMissionColumns(2);
            setNbMissionDisplay(2);
        } else {
            setMissionColumns(1);
            setNbMissionDisplay(2);
        }
    }, [width]);

    return (
        <>
            <div className={'px-4 py-5 hidden md:block'}>
                <div className={'flex justify-between'}>
                    <div className={'flex my-auto'}>
                        <div className={'my-auto'}>
                            <div className={'rounded-full bg-gray-100 p-2'}>
                                <SearchMissionIcon wh={22}/>
                            </div>
                        </div>
                        <div className={'my-auto font-bold ml-3'}>Rechercher une mission</div>
                    </div>
                    <div className={'my-auto'}>
                        <NavLink to={'../searchMission/'}>
                            <Button color={'SECONDARY'} size={'SM'}>Consulter les missions</Button>
                        </NavLink>
                    </div>
                </div>
                <div className={'my-3'}>
                    <Divider/>
                </div>
                <div className={`grid grid-cols-${missionColumns} gap-5`}>
                    {
                        resultsFound ?
                            missionListResult?.map((mission, key) =>
                                key < nbMissionDisplay ? <CardSearchMissionDashboard key={key} data={mission}/> : ''
                            ) : <EmptyState type={'mission_search_dashboard'}/>
                    }
                </div>
            </div>
        </>
    )
}

export default MissionSearch;