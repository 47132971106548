import React from 'react';
import {Mail} from "assets/icons";
const NotificationUnit = () => {
    return (
        <>
            <div className="flex flex-row justify-content h-[72px] p-1 gap-1">
                <div className="px-2">
                    <Mail  wh={18} color={"#6B7280"} strokeW={2} />
                </div>
                <div className="flex flex-col w-[175px] tab:w-60 ">
                    <span className="font-semi-bold ft-sm">Title</span>
                    <p className="ft-sm text-gray-600">Velit placeat sit ducimus non sed</p>
                </div>
                <div className=" ft-sm text-gray-600 w-20 text-center">1d ago</div>
            </div>
        </>
    );
};

export default NotificationUnit;