const CardProfile = ({children}) => {
    return (
        <>
            <div className='bg-white rounded-md shadow-sm p-6 w-full mt-32 md:mt-0'>
                {children}
            </div>
        </>
    )
}

export default CardProfile;