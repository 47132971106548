import UseContext from "hooks/useContext";
import React, {useEffect, useState} from "react";
import PageForm from "../../../components/pageForm/PageForm";
import {useNavigate} from "react-router-dom";
import {ArrowLeft, UploadIcon, Warning} from "../../../assets/icons";
import usefetchdata from "hooks/usefetchdata";
import ApiDatabase from "../../../server";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import Divider from "../../../components/divider/divider";
import Accordion from "../../../components/accordion/accordion";
import useModal from "../../../components/modal/useModal";
import CardExperience from "../../../components/card/cardsavoirfaire/cardexperience";
import Chip from "../../../components/chip/chip";
import CardJustify from "../../../components/card/cardjustify";
import {dateToEpoch} from "../../../helper/helper";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Button from "../../../components/button";
import {toast} from "react-toastify";
import Banner from "../../../components/banner/banner";
import useTitle from "../../../hooks/TitleManager";

const CvParsing = () => {
    useTitle({ title: "Détail du CV parsé" });

    const {userData, tempWorkerData} = usefetchdata();
    const [fullName, setFullName] = useState('')
    const {cvParsing, setCvParsing, setRefreshWorker, refreshWorker, setObjID} = UseContext()
    const idU = localStorage.getItem('xsrfToken')
    const [isWaiting, setIsWaiting] = useState(true)
    const [url, setUrl] = useState()
    const {toggle}=useModal()
    const [tempCvParsing, setTempCvParsing] = useState({})
    const [isExperiencesWarning, setIsExperiencesWarning] = useState(false)
    const [isFormationsWarning, setIsFormationsWarning] = useState(false)
    const [isLicencesWarning, setIsLicencesWarning] = useState(false)
    const token = localStorage.getItem('xsrfToken')
    const {height, width} = useWindowDimensions();

    const navigate = useNavigate()

    useEffect(() => {
        if(cvParsing.status === 'inProgress') {
            setIsWaiting(true)
        }
        else if(cvParsing.status === 'done') {
            ApiDatabase.deleteUserAllExperiences({token: token},
                (data) => {
                    if (cvParsing.experiences.length) {
                        for (let i = 0; i < cvParsing.experiences.length; i++) {
                            ApiDatabase.postCreateExp({
                                    token: token,
                                    experience: {
                                        metier: cvParsing.experiences[i].title ?? null,
                                        entreprise: cvParsing.experiences[i].company ?? null,
                                        localisation: cvParsing.experiences[i].location.text ?? null,
                                        dateStart: cvParsing.experiences[i].date_start.iso8601 ? dateToEpoch(cvParsing.experiences[i].date_start.iso8601) : null,
                                        dateEnd: cvParsing.experiences[i].date_end.iso8601 ? dateToEpoch(cvParsing.experiences[i].date_end.iso8601) : null,
                                    },
                                    isParsing: true,
                                },
                                (data) => {
                                    //console.log(data)
                                    setRefreshWorker(prev => !prev)
                                }, (err) => {
                                    console.log(err)
                                })
                        }
                    }
                }, (err) => {
                    console.log(err)
                }
            )

            ApiDatabase.deleteUserAllLanguages({token: token},
                (data) => {
                    if (cvParsing.languages.length) {
                        for (let i = 0; i < cvParsing.languages.length; i++) {
                            ApiDatabase.postUserLanguage({
                                    id: token,
                                    language: cvParsing.languages[i].name,
                                    isParsing: true,
                                },
                                (data) => {
                                    //console.log(data)
                                    setRefreshWorker(prev => !prev)
                                }, (err) => {
                                    console.log(err)
                                }
                            )
                        }
                    }
                }, (err) => {
                    console.log(err)
                }
            )

            ApiDatabase.deleteUserAllFormations({token: token},
                (data) => {
                    if (cvParsing.educations.length) {
                        for (let i = 0; i < cvParsing.educations.length; i++) {
                            ApiDatabase.postUserFormationWithoutFile({
                                    token: token,
                                    formation: cvParsing.educations[i].title,
                                    description: cvParsing.educations[i].description,
                                    isParsing: true
                                },
                                (data) => {
                                    //console.log(data)
                                    setRefreshWorker(prev => !prev)
                                }, (err) => {
                                    console.log(err)
                                }
                            )
                        }
                    }
                }, (err) => {
                    console.log(err)
                }
            )

            ApiDatabase.deleteUserAllLicences({token: token},
                (data) => {
                    if (cvParsing.certifications.length) {
                        for (let i = 0; i < cvParsing.certifications.length; i++) {
                            ApiDatabase.postUserLicenceWithoutFile({
                                    token: token,
                                    licence: cvParsing.certifications[i].name,
                                    isParsing: true
                                },
                                (data) => {
                                    //console.log(data)
                                    setRefreshWorker(prev => !prev)
                                }, (err) => {
                                    console.log(err)
                                }
                            )
                        }
                    }
                }, (err) => {
                    console.log(err)
                }
            )

            setIsWaiting(false)
        } else {
            toggle('sf_c_cv')
        }
    }, [cvParsing])

    useEffect(() => {
        if (tempWorkerData?.cv?.file) {
            ApiDatabase.getPathFile({id: tempWorkerData.cv.file},
                (data) => {
                    setUrl(data.filePath)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [tempWorkerData, tempWorkerData])

    useEffect(() => {
        if(userData) {
            setFullName(userData.firstname + '-' + userData.lastname)
        }
    }, [userData])

    useEffect(() => {
        if(tempWorkerData) {
            setTempCvParsing(tempWorkerData)
            if(tempWorkerData?.experiences){
                setIsExperiencesWarning(false)
                for (let i = 0; i < tempWorkerData.experiences.length; i++) {
                    if(!tempWorkerData.experiences[i].name || !tempWorkerData.experiences[i].company.name || !tempWorkerData.experiences[i].company.location || !tempWorkerData.experiences[i].date.start || !tempWorkerData.experiences[i].date.end){
                        setIsExperiencesWarning(true)
                    }
                }
            }
            if(tempWorkerData?.formations){
                setIsFormationsWarning(false)
                for (let i = 0; i < tempWorkerData.formations.length; i++) {
                    if(!tempWorkerData.formations[i].name || !tempWorkerData.formations[i].description){
                        setIsFormationsWarning(true)
                    }
                }
            }
            if(tempWorkerData?.licences){
                setIsLicencesWarning(false)
                for (let i = 0; i < tempWorkerData.licences.length; i++) {
                    if(!tempWorkerData.licences[i].licence || !tempWorkerData.licences[i].file){
                        setIsLicencesWarning(true)
                    }
                }
            }
        }
    }, [refreshWorker, tempWorkerData])


    const handleDeletedLanguage = (e,item) =>{
        e.preventDefault()
        setObjID(item)
        toggle('sf_d_lang')
    }

    const handleConfirm = (e) => {
        e.preventDefault()

        if(tempCvParsing){
            if(isExperiencesWarning){
                toast.error('Certains champs sont manquants dans vos expériences')
            } else if(isFormationsWarning){
                toast.error('Certains champs sont manquants dans vos formations')
            } else if(isLicencesWarning){
                toast.error('Certains champs sont manquants dans vos permis')
            } else {
                const dataCvParsing = {
                    experiences: tempCvParsing.experiences ?? [],
                    languages: tempCvParsing.language ?? [],
                    educations: tempCvParsing.formations ?? [],
                    licences: tempCvParsing.licences ?? [],
                }
                ApiDatabase.putUpdateHrflowCandidate({token: token, data: dataCvParsing},
                    (data) => {
                        navigate('../profil/savoir_faire/cv')
                        toast.success('Vos informations ont étés mises à jour')
                    },
                    (err) => {
                        console.log(err)
                    })
            }
        }
    }

    return (
        <>
            <PageForm>
                {
                    isWaiting ? (
                        <>
                            <div className={'animate-fadeIn animate-pulse'}>
                                <div className="col-span-12 bg-white sticky z-10">
                                    <div className='flex items-center justify-between h-14 px-4'>
                                        <div className='flex items-center gap-2 cursor-pointer'>
                                        <span onClick={e => navigate('/profil/savoir_faire/cv')}>
                                            <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        </span>
                                            <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between border">
                                    <div className={`${width > 800 ? 'w-1/2 p-6' : 'w-full p-6 px-2'}`}>
                                        <div className={'border rounded px-3 py-2 flex justify-between'}>
                                            <div className={'my-auto'}>
                                                <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                            </div>
                                            <button className={'my-auto'}>
                                                <div className={'h-5 bg-gray-200 rounded w-10'}></div>
                                            </button>
                                        </div>
                                        <div className={'mt-3'}>
                                            <div className={'w-full flex justify-between p-5 rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800'}>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                                </div>
                                            </div>
                                            <div className={'w-full flex justify-between p-5 rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800'}>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                                </div>
                                            </div>
                                            <div className={'w-full flex justify-between p-5 rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800'}>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                                </div>
                                            </div>
                                            <div className={'w-full flex justify-between p-5 rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800'}>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div className={'h-3 bg-gray-200 rounded w-40'}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        width > 800 && (
                                            <div className={'w-1/2 py-12 px-16 bg-gray-50'}></div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={'animate-fadeIn'}>
                                <div className="col-span-12 bg-white sticky z-10">
                                    <div className='flex items-center justify-between h-14 px-4'>
                                        <div className='flex items-center gap-2 cursor-pointer'>
                                        <span onClick={e => navigate('/profil/savoir_faire/cv')}>
                                            <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        </span>
                                            Retour à Mon savoir faire
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex justify-between border ${width > 800 ? '' : 'mb-20'}`}>
                                    <div className={`${width > 800 ? 'w-1/2 p-6' : 'w-full p-6 px-2'} flex flex-col`}>
                                        <div className={'border rounded px-3 py-2 flex justify-between'}>
                                            <div className={'my-auto'}>
                                                cv-{fullName.toLowerCase()}
                                            </div>
                                            <button className={'my-auto'} onClick={e => toggle('sf_c_cv')}>
                                                <UploadIcon wh={24} color={'#111827'}/>
                                            </button>
                                        </div>
                                        <div className={'mt-3'}>
                                            {
                                                tempCvParsing?.experiences && (
                                                    <Accordion type={1} title={'Expériences'} additional={`${tempCvParsing?.experiences.length} ${tempCvParsing?.experiences.length > 1 ? 'expériences' : 'expérience'}`} isWarning={isExperiencesWarning}>
                                                        {
                                                            isExperiencesWarning && (
                                                                <div className={'w-full mb-5'}>
                                                                    <Banner type={'alert'}>
                                                                        Pour maximiser l'impact de votre profil et augmenter vos chances de décrocher un emploi, complétez tous les champs requis.
                                                                    </Banner>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            tempCvParsing?.experiences && (
                                                                tempCvParsing?.experiences.map((item, index) => (
                                                                    <CardExperience key={index} item={item}/>
                                                                ))
                                                            )
                                                        }
                                                    </Accordion>
                                                )
                                            }
                                            {
                                                tempCvParsing?.language && (
                                                    <Accordion type={2} title={'Langues'} additional={`${tempCvParsing?.language.length} ${tempCvParsing?.language.length > 1 ? 'langues' : 'langue'}`}>
                                                        <div className='flex flex-wrap gap-2 items-center justify-start my-4'>
                                                            {tempCvParsing?.language?.map((langue, index) =>
                                                                <Chip key={index} onClick={e=>{handleDeletedLanguage(e,langue)}} icon={true} select={true} type={'SELECTON'}
                                                                >
                                                                    {langue}
                                                                </Chip>
                                                            )}
                                                        </div>
                                                    </Accordion>
                                                )
                                            }
                                            {
                                                tempCvParsing?.formations && (
                                                    <Accordion type={3} title={'Formations'} additional={`${tempCvParsing?.formations.length} ${tempCvParsing?.formations.length > 1 ? 'formations' : 'formation'}`} isWarning={isFormationsWarning}>
                                                        {
                                                            isFormationsWarning && (
                                                                <div className={'w-full mb-5'}>
                                                                    <Banner type={'alert'}>
                                                                        Pour maximiser l'impact de votre profil et augmenter vos chances de décrocher un emploi, complétez tous les champs requis.
                                                                    </Banner>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            tempCvParsing?.formations.map((formation, index) => {
                                                                return (
                                                                    <CardJustify type={'training'} item={formation} key={index}/>
                                                                )
                                                            })
                                                        }
                                                    </Accordion>
                                                )
                                            }
                                            {
                                                tempCvParsing?.licences && (
                                                    <Accordion type={4} title={'Permis'} additional={`${tempCvParsing?.licences.length} permis`} isWarning={isLicencesWarning}>
                                                        {
                                                            isLicencesWarning && (
                                                                <div className={'w-full mb-5'}>
                                                                    <Banner type={'alert'}>
                                                                        Pour maximiser l'impact de votre profil et augmenter vos chances de décrocher un emploi, complétez tous les champs requis.
                                                                    </Banner>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            tempCvParsing?.licences.map((certification, index) => {
                                                                return (
                                                                    <CardJustify type={'licence'} key={index} item={certification}/>
                                                                )
                                                            })
                                                        }
                                                    </Accordion>
                                                )
                                            }
                                        </div>
                                        <div className={'w-full mt-auto'}>
                                            <Divider/>
                                            <div className={'ml-auto flex justify-end'}>
                                                <div className={'flex gap-2 pt-5 '}>
                                                    <Button size={'LG'} color={'SECONDARY'} onClick={handleConfirm}>Quitter</Button>
                                                    <Button size={'LG'} onClick={handleConfirm}>Confirmer</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        width > 800 && (
                                            <div className={'w-1/2 py-12 px-16 bg-gray-50'}>
                                                <DocViewer documents={[
                                                    {uri:`${process.env.REACT_APP_FILE + url}`}
                                                ]} config={{
                                                    pdfZoom: {
                                                        defaultZoom: 1, // 1 as default,
                                                        zoomJump: 0.1, // 0.1 as default,
                                                    },
                                                }} pluginRenderers={DocViewerRenderers}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            </PageForm>
        </>
    )
}

export default CvParsing;