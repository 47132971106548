import React, {useContext} from 'react';
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";
import {DocumentIcon} from "assets/icons";
import UseContext from "hooks/useContext";

const DocumentsDL = ({pathname}) => {
    const {language}=UseContext()
    return (
        // SidebarUnit props => routeName:name link, routeSidebar:link router, children:svg
        <SidebarUnitDesktop routeName={"Mes documents"} routeSidebar={"/documents/contracts/nosign"}>
            <span className='shrink-0'>
            <DocumentIcon wh={40} color={`${pathname.includes("documents") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("documents") ? "text-yellow-500" : ''
                }`}
            >
          {language.getValue('sidebar.file','Mes Documents')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default DocumentsDL;