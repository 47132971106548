import React, {useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import Banner from "../../../../banner/banner";
import {CheckIcon} from "../../../../../assets/icons";
import {toast} from "react-toastify";
import ApiDatabase from "server";

const Modalmodifydeleted = () => {
    const {toggle}=useModal()
    const [reasonSubmit, setReasonSubmit] = useState('')
    const [page, setPage] = useState(1)
    const [check, setCheck] = useState(false)
    const [textComment, setTextComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const reasonList = [
        {name: 'J’ai trouvé du travailleurs ailleurs, CDD, CDI, autre mission', page: 2},
        {name: 'Je ne suis jamais accepté sur les missions', page: 3},
        {name: 'Je n’aime pas les missions proposées', page: 4},
        {name: 'Une autre raison', page: 5}
    ]
    const token = localStorage.getItem('xsrfToken')

    const nextPage = () => {
        const nextPage = reasonList.find(reason => reason.name === reasonSubmit)

        if (nextPage) {
            setPage(nextPage.page)
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault()

        let isValidForm = true
        if (page === 5) {
            if(textComment.length < 5 || textComment.length > 250) {
                isValidForm = false
                return toast.error('Veuillez compléter le formulaire')
            }
        }

        if (check && isValidForm) {
            const completeReason = reasonSubmit === 'Une autre raison' ? reasonSubmit + ' - ' + textComment : reasonSubmit
            setIsLoading(true)
            ApiDatabase.deleteAccount({token, reason: completeReason}, (data) => {
                localStorage.removeItem('xsrfToken')
                localStorage.removeItem('rUser')
                window.location.href = 'https://jobbiz.fr'
            }, (err) => {
                console.log('err', err)
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Supprimer le compte
            </Modalheader>
            <ModalBody>
                <div className={'flex flex-col gap-6'}>
                    {page === 1 && (
                        <>
                            <div className={'text-sm text-gray-500'}>
                                Toutes vos informations, documents, seront supprimés.
                                Une fois confirmée, vous ne pouvez plus annuler cette action.
                            </div>
                            <div>
                                <label className='ft-sm text-gray-500'>
                                    <div>
                                        Pourquoi supprimez-vous votre compte ?
                                    </div>
                                    <div>
                                        <select className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                value={reasonSubmit}
                                                onChange={e => setReasonSubmit(e.target.value)}
                                        >
                                            <option value={''}>Sélectionner</option>
                                            {
                                                reasonList.map((reason, index) => {
                                                    return (
                                                        <option key={index} value={reason.name}>{reason.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </label>
                            </div>
                        </>
                    )}
                    {page === 2 && (
                        <Banner type={"disabled"}>
                            <div className={'flex flex-col gap-1'}>
                                <div className={'text-gray-900 font-black'}>Vous avez trouvé du travail ailleurs ?</div>
                                <div className={''}>
                                    Chez Jobbiz, même après avoir trouvé un emploi ailleurs, vous avez la possibilité de rester et de rechercher d'autres opportunités avec nous.
                                </div>
                            </div>
                        </Banner>
                    )}
                    {page === 3 && (
                        <Banner type={"disabled"}>
                            <div className={'flex flex-col gap-1'}>
                                <div className={'text-gray-900 font-black'}>Vous n’êtes jamais accepté en mission ?</div>
                                <div className={''}>
                                    Chez Jobbiz, l'expérience varie suivant la localité, la saison, et les missions disponibles. Nous nous excusons pour ces variations et nous nous efforçons d'assurer des opportunités pour tous.
                                </div>
                            </div>
                        </Banner>
                    )}
                    {page === 4 && (
                        <Banner type={"disabled"}>
                            <div className={'flex flex-col gap-1'}>
                                <div className={'text-gray-900 font-black'}>Vous n’aimez pas les missions proposées ?</div>
                                <div className={''}>
                                    Chez Jobbiz, si les missions proposées ne vous conviennent pas, nous nous en excusons et nous nous engageons à diversifier les options disponibles pour mieux répondre à vos besoins.
                                </div>
                            </div>
                        </Banner>
                    )}
                    {page === 5 && (
                        <label>
                            <div className={'text-gray-500'}>Quel est votre raison ?</div>
                            <textarea
                                cols="30"
                                rows="5"
                                className="border border-gray-300 rounded-md w-full p-1"
                                required
                                maxLength={250}
                                minLength={3}
                                wrap="true"
                                value={textComment}
                                onChange={(e) => setTextComment(e.target.value)}
                            ></textarea>
                            <div className={'text-gray-400 text-sm'}>
                                {textComment.length === 0 && (
                                    <>
                                        5 caractères minimum
                                    </>
                                )}
                                {textComment.length > 0 && textComment.length < 5 && (
                                    <div className={'text-red-500'}>
                                        5 caractères minimum
                                    </div>
                                )}
                                {textComment.length >= 5 && textComment.length <= 250 && (
                                    <>
                                        {textComment.length} / 250 caractères
                                    </>
                                )}
                            </div>
                        </label>
                    )}
                    {page !== 1 && (
                        <div
                            className={"w-full ft-sm flex items-start gap-3 border b-gray-50 p-6 rounded-md cursor-pointer"}
                            onClick={() => setCheck(!check)}>
                            <div
                                className={`flex items-center justify-center mt-1 min-h-[16px] min-w-[16px] border border-gray-300 rounded-sm bg-white ${check ? "bg-yellow-600 border-yellow-600" : ""} focus:outline-none transition duration-200 cursor-pointe`}>
                                {check && <CheckIcon w={14} h={10} color={"#ffff"}/>}
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"ft-sm font-bold text-gray-700"}>
                                    Confirmer la suppression du compte
                                </span>
                                <p className="ft-sm font-medium text-gray-500">
                                    Je suis conscient que mes informations, documents, seront supprimés.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                {page === 1 && <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>}
                {page !== 1 && <Button size={'LG'} color={'SECONDARY'} onClick={() => setPage(1)}>Retour</Button>}
                {page === 1 && (
                    <>
                        {reasonSubmit !== '' ? (
                            <Button size={'LG'} onClick={() => nextPage()}>Suivant</Button>
                        ) : (
                            <Button size={'LG'} color={'DISABLED'}>Suivant</Button>
                        )}
                    </>
                )}
                {page !== 1 && (
                    <>
                        {check
                            ? (
                                <>
                                    {isLoading ? (
                                        <Button size={'LG'} color={'DISABLED'}>Confirmation...</Button>
                                    ) : (
                                        <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                                    )}
                                </>
                            )
                            : <Button size={'LG'} color={'DISABLED'}>Confirmer</Button>
                        }
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default Modalmodifydeleted;