import React, {useEffect, useState} from 'react';
import Table from "components/table/table";
import EmptyState from "components/EmptyState/EmptyState";
import ApiDataBase from 'server'
import MediaQuery from "react-responsive";
import Cardhour from "components/card/cardhour";
import Tooltips from "../../../../components/tooltips/Tooltips";
import {dateToEpoch, numberFormat} from "../../../../helper/helper";
import {InformationCircle} from "../../../../assets/icons";
import useTitle from "../../../../hooks/TitleManager";


const CompletedHours = () => {
    useTitle({ title: "Relevés d'heures complets" });
    const [arrayTimeSheets,setArrayTimeSheets]=useState([])
    const [dataTimeSheets,setDataTimeSheets]=useState([])
    const [isWaiting, setIsWaiting] = useState(true);
    const [monthUsed, setMonthUsed] = useState({
        '1': {
            name: 'Janvier',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '2': {
            name: 'Février',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '3': {
            name: 'Mars',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '4': {
            name: 'Avril',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '5': {
            name: 'Mai',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '6': {
            name: 'Juin',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '7': {
            name: 'Juillet',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '8': {
            name: 'Août',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '9': {
            name: 'Septembre',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '10': {
            name: 'Octobre',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '11': {
            name: 'Novembre',
            used: false,
            estimationMonthTimeSheet: 0,
        },
        '12': {
            name: 'Décembre',
            used: false,
            estimationMonthTimeSheet: 0,
        },
    })

    const monthUsedList = [];

    useEffect(()=>{
        const token = localStorage.getItem('xsrfToken')
        ApiDataBase.getTimeSheets({token:token},
            (data)=>{
                setArrayTimeSheets(data)
                setIsWaiting(false)
            },
            (err)=>{
                console.log(err)
            })
    },[])
    const titles=['N° DE RELEVÉ', 'MISSION', 'N° DE MISSION', 'ENTREPRISE', 'ESTIMATION', 'ÉTAT', 'PÉRIODE']
    useEffect(() => {
        let tampon = []
        for (let m in arrayTimeSheets) {
            if (arrayTimeSheets[m].validated >0) {
                tampon.push(arrayTimeSheets[m])
            }
        }
        setDataTimeSheets(tampon)
    }, [arrayTimeSheets])

    useEffect(() => {
        if(dataTimeSheets.length > 0){
            dataTimeSheets.map((item, index) => {
                const month = dateToEpoch(item.periode_start).getMonth() + 1
                setMonthUsed(prevState => ({
                    ...prevState,
                    [month]: {
                        ...prevState[month],
                        used: true,
                        estimationMonthTimeSheet: prevState[month].estimationMonthTimeSheet + item.estimation_hours_time_sheet
                    }
                }))
            })
        }
    }, [dataTimeSheets])

    return (
        <>
            <MediaQuery minWidth={770}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index)=>(
                                    <Table champsBody={i} champsHeader={titles} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                dataTimeSheets.length > 0 ?
                                    <Table champsBody={dataTimeSheets} champsHeader={titles}/>
                                    :
                                    <EmptyState type={'time_sheet'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            <div className={'animate-pulse w-full'}>
                                {
                                    [1, 2, 3, 4, 5].map((i, index)=>(
                                        <Cardhour item={i} isWaiting={isWaiting}/>
                                    ))
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            {dataTimeSheets?.length > 0 ? (
                                dataTimeSheets.map((item, index) => {
                                    const month = dateToEpoch(item.periode_start).getMonth() + 1
                                    let monthName = null

                                    if(monthUsed[month].used){
                                        if(!monthUsedList.includes(monthUsed[month].name)){
                                            monthName = monthUsed[month].name
                                            monthUsedList.push(monthUsed[month].name)
                                        }
                                        else {
                                            monthName = null
                                        }
                                    }

                                    return (
                                        <>
                                            {
                                                monthName && (
                                                    <>
                                                        <div className={'px-5 py-3 flex flex-wrap justify-between'}>
                                                            <div className={'my-auto'}>
                                                                {monthName}
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                <div className={'flex gap-2'}>
                                                                    <div className={'my-auto ft-sm text-gray-500 border-dotted border-b-2'}>
                                                                        Salaire brut estimé : {numberFormat(monthUsed[month].estimationMonthTimeSheet)} €
                                                                    </div>
                                                                    <div className={'my-auto cursor-pointer'}>
                                                                        <Tooltips
                                                                            text={`Le salaire brut estimé est basé sur les heures travaillées et n\'inclut pas les heures de nuit ou les primes. BETA`}
                                                                            placement='top'
                                                                        >
                                                                            <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                                                        </Tooltips>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <Cardhour key={index} item={item}/>
                                        </>
                                    )
                                }))
                                :
                                <EmptyState type={'time_sheet'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
        </>
    );
};

export default CompletedHours;