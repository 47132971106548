import React, { useEffect, useState } from "react";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import IndicatorIdentity from "components/modal/dataModal/data/modaljustify/identity/components/body/import/indicator";
import Pi from "components/modal/dataModal/data/modaljustify/identity/components/body/import/pi";
import Selfie from "components/modal/dataModal/data/modaljustify/identity/components/body/selfie";
import Autorisationtravail from "components/modal/dataModal/data/modaljustify/identity/components/body/autorisationtravail";
import Confirmation from "components/modal/dataModal/data/modaljustify/identity/components/body/import/confirmation";
import Footerbutton from "components/modal/dataModal/data/modaljustify/identity/components/body/import/footerbutton";
import usefetchdata from "hooks/usefetchdata";
import axios from "axios";
import { toast } from "react-toastify";
import UseContext from "hooks/useContext";
import LoadingButton from "components/button/loading.js";

const Modalidentity = () => {
    const { tempWorkerData } = usefetchdata();
    const { refreshLoadWorker, language } = UseContext();
    const [formIndex, setFormIndex] = useState(1);
    const [nationality, setNationality] = useState("");
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const { toggle } = useModal();
    // piece d'identité

    const [pi, setPi] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });
    const [selfie, setSelfie] = useState(null);
    const [visa, setVisa] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });
    const elements = [
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <Selfie key={Selfie} setSelfie={setSelfie} />,
        <Confirmation
            key={Confirmation}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
        <Confirmation
            key={Confirmation}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            nationality={nationality}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <Selfie key={Selfie} setSelfie={setSelfie} />,
    ];
    const elements2 = [
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <Selfie key={Selfie} setSelfie={setSelfie} />,
        <Autorisationtravail
            key={Autorisationtravail}
            setVisa={setVisa}
            visa={visa}
        />,
        <Confirmation
            key={Confirmation}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
        <Confirmation
            key={Confirmation}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            nationality={nationality}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <Selfie key={Selfie} setSelfie={setSelfie} />,
        <Autorisationtravail
            key={Autorisationtravail}
            setVisa={setVisa}
            visa={visa}
        />,
    ];
    const effect1 = (f) =>
        f.identity?.validated === "1" && f.visa?.validated === "1";
    const effect2 = (f) => f.identity?.validated === "1";
    useEffect(() => {
        if (pi.nationality !== "" && pi.nationality !== nationality) {
            setNationality(pi.nationality);
        } else {
            if (effect2(tempWorkerData) || effect1(tempWorkerData)) {
                if (effect1(tempWorkerData)) {
                    setNationality("NUE");
                    setFormIndex(5);
                } else {
                    setNationality("UE");
                    setFormIndex(4);
                }
            } else {
                setFormIndex(1);
            }
        }
    }, [tempWorkerData, pi.nationality]);

    const state1 = (f) =>
        f.type !== "" && f.nationality !== "" && f.fileR !== "";
    const state2 = (f) => f !== "";
    const state3 = (f) =>
        f.type !== "" &&
        f.fileR !== "" &&
        ((f.type === "Visa" && f.fileV !== "") || f.type === "RTS");

    const handleIndex = (e) => {
        if (check) {
            if (formIndex < 1) setFormIndex(1);

            if (
                (formIndex === 1 && state1(pi)) ||
                (formIndex === 2 && state2(selfie)) ||
                (formIndex === 3 && state3(visa))
            )
                setFormIndex(formIndex + 1);
            else toast.warning("Veuillez remplir les champs");
        } else {
            toast.warning("Veuillez cocher l'attestation sur l'honneur");
        }
    };

    const f = async (files, successMsg) => {
        let isAllFilesSent = false;
        setLoading(true);
        const idToastLoad = toast.loading("Envoi des fichiers en cours");
        for (let i = 0; i < files.length; i++) {
            const result = await axios.post(files[i].url, files[i].file);
            if (result.status === 201) {
                isAllFilesSent = true;
            } else {
                isAllFilesSent = false;
                break;
            }
        }
        if (isAllFilesSent) {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Fichiers uploadés",
                type: "success",
                autoClose: 3000,
                isLoading: false,
            });
            refreshLoadWorker();
            toggle();
        } else {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Erreur d'envoi des fichiers",
                type: "error",
                isLoading: false,
            });
        }
    };
    const m = async (files, successMsg) => {
        const results = await Promise.all([
            files.map((f) => axios.post(f.url, f.file)),
        ]);
        const err = results.find((r) => r.errors);
        if (err) {
            toast.error("Un problème est survenu");
            console.warn(err);
        } else {
            toast.success(successMsg);
            if (nationality === "NUE") {
                setFormIndex(5);
                refreshLoadWorker();
            } else {
                refreshLoadWorker();
                setFormIndex(4);
            }
            toggle();
        }
    };

    //UE - PASSEPORT -SELFIE
    const uePassSelfie = async (firstFile, secondFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondFile,
            },
        ];

        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    //UE - CNI RECTO - CNI VERSO -SELFIE
    const ueCniSelfie = async (firstFile, secondFile, threeFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: secondFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: threeFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    //NUE PASSEPORT - SELFIE - RTS
    const etrPasseportSelfieRts = async (firstFile, secondeFile, threeFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: threeFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE PASSEPORT - SELFIE - VISA R - VISA V
    const etrPasseportSelfieVisaRV = async (
        firstFile,
        secondeFile,
        threeFile,
        fourFile
    ) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: threeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: fourFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE CNI R - CNI V - SELFIE - RTS
    const etrCniSelfieVisaR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: forms[1],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: forms[2],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[3],
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE CNI R - CNI V - SELFIE - VISA R - VISA V
    const etrCniSelfieVisaRV = async (
        firstFile,
        secondFile,
        threeFile,
        fourFile,
        fiveFile
    ) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: secondFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: threeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: fourFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: fiveFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    const modifyPIR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "Identité" est' + " ajouté.");
    };

    const modifyPIRV = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: forms[1],
            },
        ];
        await m(files, 'Le document "Identité" est' + " modifié.");
    };

    const modifySelfie = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "selfie" est' + " modifié.");
    };

    const modifyVisaR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "Identité" est' + " modifié.");
    };

    const modifyVisaRV = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: forms[1],
            },
        ];
        await m(files, 'Le document "Identité" est modifié.');
    };
    const handleSubmitMulti = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("xsrfToken");
        const first = new FormData();
        const second = new FormData();
        const three = new FormData();
        const four = new FormData();
        const five = new FormData();
        const docs = [first, second, three, four, five];
        docs.map((f) => f.append("token", token));

        if (check && pi.fileR !== "") {
            first.append("file", pi.fileR);
            first.append("identityType", pi.type);

            if (pi.type === "Passeport" && pi.nationality === "UE") {
                second.append("file", selfie);
                await uePassSelfie(first, second);
            } else if (pi.type === "CNI" && pi.nationality === "UE") {
                second.append("file", pi.fileV);
                three.append("file", selfie);
                await ueCniSelfie(first, second, three);
            } else if (pi.type === "Passeport" && pi.nationality === "NUE") {
                if (visa.type === "RTS") {
                    second.append("file", selfie);
                    three.append("file", visa.fileR);
                    three.append("visaType", visa.type);
                    await etrPasseportSelfieRts(first, second, three);
                } else {
                    second.append("file", selfie);
                    three.append("file", visa.fileR);
                    three.append("visaType", visa.type);
                    four.append("file", visa.fileV);
                    await etrPasseportSelfieVisaRV(first, second, three, four);
                }
            } else if (pi.type === "CNI" && pi.nationality === "NUE") {
                second.append("file", pi.fileV);
                three.append("file", selfie);
                four.append("file", visa.fileR);
                four.append("visaType", visa.type);
                if (visa.type === "RTS") {
                    await etrCniSelfieVisaR(first, second, three, four);
                } else {
                    five.append("file", visa.fileV);
                    await etrCniSelfieVisaRV(first, second, three, four, five);
                }
            }
        }
    };
    const handleModifyPI = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("xsrfToken");
        const first = new FormData();
        const second = new FormData();
        first.append("file", pi.fileR);
        first.append("identityType", pi.type);

        const docs = [first, second];
        docs.map((f) => f.append("token", token));

        if (pi.type === "Passeport") {
            await modifyPIR(first);
        } else if (pi.type === "CNI") {
            second.append("file", pi.fileV);
            await modifyPIRV(first, second);
        }
    };

    const handleModifySelfie = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("xsrfToken");
        const first = new FormData();
        first.append("token", token);
        first.append("file", selfie);
        await modifySelfie(first);
    };
    const handleModifyVisa = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("xsrfToken");
        const first = new FormData();
        const second = new FormData();
        const docs = [first, second];
        docs.map((f) => f.append("token", token));
        first.append("visaType", visa.type);
        if (visa.type === "RTS") {
            await modifyVisaR(first);
        } else {
            second.append("file", visa.fileV);
            await modifyVisaRV(first, second);
        }
    };

    const handleClose = (e) => {
        toggle();
    };
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue("justify.m_identity_title_c")}
            </Modalheader>
            <ModalBody>
                <IndicatorIdentity
                    pi={pi}
                    nationality={nationality}
                    formIndex={formIndex}
                />

                {(pi.nationality === "NUE" || nationality === "NUE"
                    ? elements2
                    : elements
                ).filter((item, index) => index + 1 === formIndex)}
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        <Button
                            size={"LG"}
                            color={"SECONDARY"}
                            onClick={toggle}
                        >
                            Annuler
                        </Button>
                        <Footerbutton
                            formIndex={formIndex}
                            pi={pi.nationality}
                            nationality={nationality}
                            oC1={handleIndex}
                            oC2={handleSubmitMulti}
                            oC3={handleClose}
                            oC4={handleModifyPI}
                            oC5={handleModifySelfie}
                            oC6={handleModifyVisa}
                        />
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default Modalidentity;
