import React, {useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ApiDatabase from "server";
import useContext from "../../../../../hooks/useContext";
import ReactGA from "react-ga4";

const ModalMissionAcceptInvite = () => {
    const {toggle}=useModal()
    const {idMission, setIdMission} = useContext()
    const navigate = useNavigate()
    const token = localStorage.getItem('xsrfToken')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit=(e)=>{
        e.preventDefault()
        setIsLoading(true)
        ApiDatabase.postUserInviteMissionAccept({token: token, idMission: idMission._id}, async (data) => {
            ReactGA.event({
                category: 'Postuler à une mission - Accepter une invitation',
                action: 'postulate_mission',
                label: `${idMission?.isUserInvited ? 'Mission' : 'Invitation mission'} ${idMission?.title} n° ${idMission?.id_mission}`
            });
            if (data.success) await toast.success(data.success + '. Redirection ...')
            if (data.error) await toast.error(data.error + '. Redirection ...')
            setIsLoading(false)
            setIdMission({})
            toggle()
            setTimeout(() => {
                navigate('/ficheMission/' + idMission._id)
            }, 2500)
        }, (err) => {
            if(err.response.data.error) toast.error(err.response.data.error)
            setIsLoading(false)
            console.error(err)
        })
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Accepter l'invitation
            </Modalheader>
            <ModalBody>
                <div className={'text-gray-500'}>
                    Votre candidature sera traitée comme prioritaire. En acceptant cette mission, vous vous engagez à être disponible pendant toute la durée de la mission.
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                {isLoading ? <Button size={'LG'} color={'DISABLED'} disabled={true}>Chargement...</Button> : <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>}
            </ModalFooter>
        </>
    );
};

export default ModalMissionAcceptInvite;