import React, {useState} from 'react';
import ValidMessage from "./Components/ValidMessage";
import MessageForm from "./Components/Message";


const ModalMessagerie = ({isShowing, hide}) => {
    const [validEmail, setValidEmail] = useState(false)
    const [message, setMessage] = useState({
        motif: "",
        message: "",
    });
    return (
        <>
                    {validEmail ? (
                        <ValidMessage message={message} setMessage={setMessage} hide={hide}
                                      setValidEmail={setValidEmail}/>) : (
                        <MessageForm message={message} setMessage={setMessage} hide={hide}
                                     setValidEmail={setValidEmail}/>)}
        </>
    );
};

export default ModalMessagerie;