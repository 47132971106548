import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import useModal from "components/modal/useModal";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";

const Deletelanguage = () => {
    const {refreshLoadWorker}= UseContext()
    const {toggle}=useModal()
    const {objID}=UseContext()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = (e) => {
        e.preventDefault()
        ApiDatabase.deleteUserLanguage({token:token,language:objID},
            (data)=>{
                refreshLoadWorker()
                toggle()
                toast.success(`La langue ${objID} est supprimée.`)
            },
            (err)=>console.log(err))

    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Supprimer une langue </Modalheader>
            <ModalBody>
                Êtes-vous sûr de vouloir supprimer {objID} ? Cette action est irréversible.
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Deletelanguage;