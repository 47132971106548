import React from 'react';
import Button from "components/button";

const Footerbutton = ({pi,nationality,formIndex,oC1,oC2,oC3,oC4,oC5,oC6}) => {
    const test = pi || nationality
    const elementsNUE=[
        <Button key={0} size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button key={0} size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button key={1} size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button key={2} size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button key={2} size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button key={2} size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button key={3} size={'LG'} onClick={oC2}>Confirmer</Button>,
        <Button key={4} size={'LG'} onClick={oC3}>Fermer</Button>,
        <Button key={5} size={'LG'} onClick={oC4}>Modifier</Button>,
        <Button key={6} size={'LG'} onClick={oC5}>Modifier</Button>,
        <Button key={7} size={'LG'} onClick={oC6}>Modifier</Button>
    ]

    const elementsUE=[
        <Button key={5} size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button key={6} size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button key={6} size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button key={6} size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button key={7} size={'LG'} onClick={oC2}>Confirmer</Button>,
        <Button key={3} size={'LG'} onClick={oC3}>Fermer</Button>,
        <Button key={8} size={'LG'} onClick={oC4}>Modifier</Button>,
        <Button key={8} size={'LG'} onClick={oC5}>Modifier</Button>
    ]
    return (
        <>
            {(test === 'NUE' ? elementsNUE : elementsUE).filter(
                (item,index) => index+1 === formIndex)
            }
        </>
    );
};

export default Footerbutton;