import React from 'react';
import {Calendar, ChevronRight} from "assets/icons";
import {dateToEpoch} from "../../../helper/helper";
import UseModal from "../../../components/modal/useModal";
import useTitle from "../../../hooks/TitleManager";

const Availability = ({startDate, endDate, isAvailable}) => {
    useTitle({ title: "Profil - Mes disponibilités - Jours" });
    const {toggle} = UseModal()

    const cssType = {
        'waiting': {
            body: 'bg-yellow-500 text-white',
            icon: '#FF9600',
            title: 'Confirmez vos disponibilités',
            description: `Indiquez vos disponiblités`,
        },
        'available': {
            body: 'bg-green-500 text-white',
            icon: '#10B981',
            title: 'Disponiblité confirmée',
            description: '',
        },
        'unavailable': {
            body: 'bg-red-500 text-white',
            icon: '#EF4444',
            title: 'Indisponibilité confirmée',
            description: '',
        },
    }

    let currentCssType = cssType['waiting'];

    const dateNow = dateToEpoch(new Date());

    if (startDate && endDate) {
        const dateStart = dateToEpoch(new Date(startDate))
        const dateStartFormat = dateStart.toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'})
        const dateEnd = dateToEpoch(new Date(endDate))
        const dateEndFormat = dateEnd.toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'})

        if (dateNow.getTime() < dateStart.getTime()) {
            const description = `Vous êtes indisponible jusqu'au ${dateStartFormat}`

            cssType['unavailable'].description = description;
            currentCssType = cssType['unavailable']
        } else if (dateNow.getTime() >= dateStart.getTime() && dateNow.getTime() <= dateEnd.getTime()) {
            const description = `Vous êtes disponible jusqu'au ${dateEndFormat}`

            cssType['available'].description = description;
            currentCssType = cssType['available']
        } else if (dateNow.getTime() > dateEnd.getTime()) {
            currentCssType = cssType['waiting']
        }
    } else if (startDate) {
        const dateStart = dateToEpoch(new Date(startDate))
        const dateStartFormat = dateStart.toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'})

        if (dateNow.getTime() < dateStart.getTime()) {
            const description = `Vous êtes indisponible jusqu'au ${dateStartFormat}`

            cssType['unavailable'].description = description;
            currentCssType = cssType['unavailable']
        } else if (dateNow.getTime() >= dateStart.getTime()) {
            const description = `Vous êtes disponible depuis ${dateStartFormat}`

            cssType['available'].description = description;
            currentCssType = cssType['available']
        }
    } else {
        if (isAvailable === true){
            if(!endDate){
                const description = `Vous êtes disponible`

                cssType['available'].description = description;
                currentCssType = cssType['available']
            } else {
                const dateEnd = dateToEpoch(new Date(endDate))
                const dateEndFormat = dateEnd.toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'})
                const description = `Vous êtes disponible jusqu'au ${dateEndFormat}`

                cssType['available'].description = description;
                currentCssType = cssType['available']
            }
        } else if (isAvailable === false) {
            const description = `Vous êtes indisponible`

            cssType['unavailable'].description = description;
            currentCssType = cssType['unavailable']
        }
    }

    return (
        <div className={`flex justify-between gap-3 p-3 rounded cursor-pointer ${currentCssType.body}`} onClick={() => toggle('disponibility')}>
            <div className={'my-auto bg-white p-3 rounded'}>
                <Calendar wh={20} color={currentCssType.icon}/>
            </div>
            <div className={'my-auto flex-1'}>
                <div className={'flex flex-col'}>
                    <div className={''}>{currentCssType.title}</div>
                    <div className={'ft-sm'}>{currentCssType.description}</div>
                </div>
            </div>
            <div className={'my-auto'}>
                <ChevronRight wh={24} color={'#FFFFFF'}/>
            </div>
        </div>
    );
};

export default Availability;