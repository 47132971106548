import React from 'react';
import MessagerieNavMobile from "./NavbarComponents/MessagerieNavMobile";
import HelpNavMobile from "./NavbarComponents/HelpNavMobile";
import NotificationNavMobile from "./NavbarComponents/NotificationNavMobile";
import LogoutNavMobile from "./NavbarComponents/LogoutNavMobile";
import Button from "components/button";
import Usefetchdata from "hooks/usefetchdata";
const NavbarMobile = ({menuOpen}) => {
    const{userData}=Usefetchdata()
    return (
        <>
            <div
                className={`${
                    menuOpen ? "" : "hidden"
                } w-full h-auto  md:w-auto z-50 bg-white mt-5 p-2`}
            >
                {userData.validation !== 2?
                <div className="flex flex-col gap-4 p-4 items-center w-full">
                    <h4 className="text-center ">{userData.firstname + ' ' + userData.lastname}</h4>
                    <p className="text-center text-sm font-medium text-[#6B7280]">
                        Vous devez valider votre identité pour postuler à des
                        missions.
                    </p>

                    <Button size={'FULL'}>Valider mon identité</Button>
                </div>
                    :null}
                <MessagerieNavMobile />
                {/*<hr className="w-[95%] h-px bg-slate-200 mx-3" />*/}
                {/*<NotificationNavMobile />*/}
                {/*  Divider */}
                {/*<hr className="w-[95%] h-px bg-slate-200 mx-3" />*/}
                {/*<HelpNavMobile />*/}
                <hr className="w-[95%] h-px bg-slate-200 mx-3" />
                <LogoutNavMobile />
            </div>
        </>
    );
};

export default NavbarMobile;