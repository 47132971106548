import {dateWordShort} from "../../helper/helper";
import Divider from "../divider/divider";

const Cardpayslip = ({item, isWaiting}) => {
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={'flex flex-col gap-4 px-4 py-5 animate-fadeIn animate-pulse'}>
                            <p className={'h-2 bg-gray-200 rounded w-2/4'}></p>
                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                        </div>
                        <div className={'my-5'}>
                            <Divider w={100}/>
                        </div>
                    </>
                ) : (
                    <>
                        <a className={'flex flex-col gap-4 h-16 px-4 py-5 animate-fadeIn'}
                           target={"_blank"}
                           href={`${process.env.REACT_APP_INTERIM_PDF}${item.filePath}`}>
                            <p className={'ft-b font-medium text-gray-900'}>{monthNames[new Date(item.year, item.month, 0).getMonth()]}</p>
                            <div className={'ft-sm text-gray-700 uppercase'}>
                                {item.amount ? item.amount + '€' + ' -' : null} {dateWordShort(new Date(item.year, item.month, 0))}
                            </div>
                        </a>
                        <div className={'my-5'}>
                            <Divider w={100}/>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default Cardpayslip;