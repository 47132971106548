import React from "react";
import {NavLink} from "react-router-dom";
import Tooltip from "components/tooltips/Tooltips";
import UseContext from "hooks/useContext";

const SidebarUnitDesktop = ({routeSidebar, routeName, children}) => {
    const {sidebarExpanded} = UseContext()
    const activeLink="block text-yellow-600 hover:text-gray-500 truncate transition duration-150"
    const noActiveLink="block text-gray-600 hover:text-yellow-500 truncate transition duration-150"

    return (
        <>
            <li className="rounded-sm mb-0.5 last:mb-0">
                <Tooltip
                    text={routeName}
                    disabled={sidebarExpanded}
                    placement="right"
                >
                    <NavLink
                        end
                        to={routeSidebar}
                        // activeClassName="text-[#FF9600] hover:text-[#6B7280]"
                        // className={`block text-[#6B7280] hover:text-[#FF9600] truncate transition duration-150 `}
                        className={({isActive}) => (isActive ? activeLink : noActiveLink)}
                    >
                        <div id={routeName} className="flex items-center">
                            {children}
                        </div>
                    </NavLink>
                </Tooltip>
            </li>
        </>
    );
};

export default SidebarUnitDesktop;
