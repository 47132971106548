import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import useModal from "components/modal/useModal";
import Button from "components/button";
import UseContext from "hooks/useContext";
import ApiDatabase from 'server'
import Yousign from "hooks/yousign";

const ModalSignature = () => {
    const {refreshLoadUser} = UseContext()
    const {toggle} = useModal()
    const {dataYousign, signatureLink} = UseContext()
    const handleSubmit = e => {
        e.preventDefault()
        toggle()
        ApiDatabase.putStatusContract({contract: dataYousign},
            (data) => {
                if (data.success){
                    window.location.reload(false)
                    toggle()
                }
            },
            (err) => {
                console.log(err)
            })
        }

    const yousignInit = () => {
        const yousign = new Yousign({
            signatureLink: signatureLink,
            iframeContainerId: 'iframe-container',
            isSandbox: true, //To set if you use the sandbox environment
        });

        yousign.onSuccess((data) => {
            console.log("Signer has successfully signed");
        });
    }
    // window.addEventListener('message', function (e) {
    //     if (e.data.event === 'success') {
    //         console.log('Signer has successfully signed')
    //     }
    // })

    return (
        <>
            <Modalheader hide={handleSubmit} hideShow={true}>Signature du document</Modalheader>
            <ModalBody>
                <iframe id={'iframe-container'} className={'w-full h-[700px]'} src={`${signatureLink}&disable_domain_validation=true`} onLoad={yousignInit}></iframe>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={handleSubmit}>Fermer</Button>
            </ModalFooter>
        </>
    );
};

export default ModalSignature;