import React from 'react';


//import Component
import PageForm from "components/pageForm/PageForm";
import ProfileValidation from "components/Dashboard/profileValidation";
import ProfileCompletion from "components/Dashboard/profileCompletion";
import ProfileDocuments from "components/Dashboard/profileDocuments";
import MissionSearch from "components/Dashboard/missionSearch";
import useTitle from "../../hooks/TitleManager";


const Dashboard = () => {
    useTitle({ title: "Tableau de bord" });

    return (
        <>
            <PageForm>
                <div className={'col-span-12 my-12  md:my-8'}>
                    {/* Validation */}
                    <ProfileValidation/>
                    {/* Profile completion */}
                    <ProfileCompletion/>
                    {/* Documents */}
                    <ProfileDocuments/>
                    {/* Mission search */}
                    <MissionSearch/>
                </div>
            </PageForm>
        </>
    );
};

export default Dashboard;
