import React, { useContext, useState } from "react";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import ApiDatabase from "server";
import { toast } from "react-toastify";
import UseContext from "hooks/useContext";
import isIBAN from "validator/lib/isIBAN";
import { Information } from "assets/icons";
import LoadingButton from "components/button/loading.js";

const IbanModal = () => {
    const { toggle } = useModal();
    const { language, refreshLoadWorker } = UseContext();
    const [error, setError] = useState(false);
    const [bank, setBank] = useState("");
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        setValue(
            e.target.value
                .replace(/[^\dA-Z]/g, "")
                .replace(/(.{4})/g, "$1 ")
                .trim()
        );
        const iban = e.target.value.replace(/\s/g, "");

        if (iban.length === 27) {
            setError(false);

            if (isIBAN(iban)) {
                setBank(iban);
            } else {
                setError("Le numéro de compte n'est pas valide");
                setBank(null);
            }
        } else {
            setError("Le numéro de compte doit faire 27 caractères");
            setBank(null);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isIBAN(bank)) {
            setLoading(true);
            ApiDatabase.postUpdateIban(
                {
                    token: localStorage.getItem("xsrfToken"),
                    iban: bank,
                },
                (data) => {
                    setLoading(false);
                    refreshLoadWorker();
                    toast.success("L'identité bancaire est ajoutée.");
                    toggle();
                },
                (err) => {
                    setLoading(false);
                    toast.error("Un problème survenu");
                }
            );
        }
    };
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue("justify.m_iban_title_c")}
            </Modalheader>
            <ModalBody>
                <form className="grid grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <label
                            htmlFor="iban"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Iban
                        </label>
                        <div className=" text-gray-500">
                            <input
                                name="iban"
                                pattern="([A-Z])[\w+.]{1,}"
                                value={value}
                                onChange={handleChange}
                                className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                required
                            />
                        </div>
                        {error && (
                            <div className="text-yellow-500 flex flex-row gap-4 items-center">
                                <Information wh={14} color={"#FF9600"} />
                                {error}
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        <Button
                            size={"LG"}
                            color={"SECONDARY"}
                            onClick={toggle}
                        >
                            Annuler
                        </Button>
                        <Button size={"LG"} onClick={handleSubmit}>
                            Confirmer
                        </Button>
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default IbanModal;
