import React, {useState} from 'react';
import PageForm from "components/pageForm/PageForm";
import CardMission from "components/card/mission";

import MediaQuery from "react-responsive";
import SubNavMission from "components/subnav/mission/desktop";
import Button from "components/button";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useTitle from "../../hooks/TitleManager";


const Mission = () => {
    useTitle({ title: "Missions" });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const route = useLocation()
    const {pathname} = route
    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const pButton ={
        '/mission': 'Menu',
        '/mission/in_progress': 'En cours',
        '/mission/coming_soon': 'A venir',
        '/mission/completed': 'Terminées'
    }

    const handleText = (e) => {
        e.preventDefault()
        switch (e.target.textContent) {
            case 'Terminées':
                navigate('./completed')
                break
            case 'En cours':
                navigate('./in_progress')
                break
            case 'À venir':
                navigate('./coming_soon')
                break
            default:
                break
        }
    }

    return (
        <PageForm>
            <MediaQuery minWidth={769}>
                <div className="col-span-12 bg-white sticky z-10">
                    <SubNavMission/>
                </div>
                <div
                    className="col-span-12 mt-8 mb-28 flex items-center justify-start flex-wrap gap-4 px-4">
                    <Outlet/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <div className="col-span-12 bg-white fixed w-full top-16 ">
                    <div
                        className="col-span-full h-14 flex items-center justify-between px-2 gap-2 border-b b-gray-500">
                        <h1 className='ft-b font-normal text-yellow-500 '>
                            Mes missions
                        </h1>
                        <div
                            className='relative flex items-center justify-center'
                            onClick={handleDots}>
                            <Button size={'SMI'} color={'DEFAULT'}
                                    onClick={handleDots}>{pButton[pathname]}</Button>
                            <div className={`${
                                dropdownOpen ? "" : "hidden"
                            }  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-1 z-30 bg-gray-50 flex flex-col gap-2`}>
                                <div
                                    className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                    onClick={handleText}>Terminées
                                </div>
                                <div
                                    className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                    onClick={handleText}>En cours
                                </div>
                                <div
                                    className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                    onClick={handleText}>À venir
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div
                    className="col-span-12 mt-32 md:mt-16 mb-28 flex flex-col md:flex-row md:items-center justify-start px-2 tab:justify-evenly md:flex-wrap gap-4">
                    <Outlet/>
                </div>
            </MediaQuery>
        </PageForm>
    );
};

export default Mission;