import React from 'react';
import Divider from "components/divider/divider";
import Chip from "components/chip/chip";
import {InformationCircle, Modify} from "assets/icons";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import {calculateHours, dateToEpoch, formatHours} from "../../../../../helper/helper";
import Tooltips from "../../../../../components/tooltips/Tooltips";

const TbodyHours = ({i}) => {
    const {toggle} = UseModal()
    const {setTimeData, objID, setContent} = UseContext()
    const statusTextDay = {
        0: 'À compléter',
        1: 'Complet',
        2: 'Complet',
        3: 'Complet',
        4: 'À venir'
    }
    const statusColorDay = {
        0: 'ALERT',
        1: 'INFO',
        2: 'INFO',
        3: 'INFO',
        4: 'PRIMARY'
    }
    const statusIcon = {
        0: <Modify wh={15} color={'#111827'}/>,
        1: <Modify wh={15} color={'#111827'}/>,
        // 2: <CheckIcon w={24} h={12} color={'#111827'}/>,
        // 3: <CheckIcon w={24} h={12} color={'#111827'}/>
    }

    const handleModal = (e, dayValidation, index, id, timeSheetValidated,iDate) => {
        e.preventDefault()
        if ((dayValidation === 0 || dayValidation === 1) && timeSheetValidated === 0) {
            toggle('doc_c_completedHours')
            setTimeData(prevData => ({
                ...prevData,
                index: index,
                id: id,
                id_time_sheet: objID,
                date:iDate

            }))

            setContent({
                idMission: objID,
                nameCompany: i?.company?.name_commercial,
            })
        }
    }

    return (
        <>
            {i?.days.map((item, index) => {
                let period = 0
                let pause = 0
                //Display normal hours
                if(item?.periode_one?.start && item?.periode_one?.end) {
                    if (item?.periode_one?.start !== '00:00' && item?.periode_one?.end !== '00:00') {
                        period = item?.periode_one?.start + ' > ' + item?.periode_one?.end

                        if (item?.periode_one?.start !== '' && item?.periode_one?.end !== '' && item?.periode_two?.start !== '' && item?.periode_two?.end !== '' && item?.periode_three?.start === '' && item?.periode_three?.end === '') {
                            period = item?.periode_one?.start + ' > ' + item?.periode_two?.end

                            pause = formatHours(calculateHours(item?.periode_one?.end, item?.periode_two?.start, true))

                        } else if (item?.periode_one?.start !== '' && item?.periode_one?.end !== '' && item?.periode_two?.start !== '' && item?.periode_two?.end !== '' && item?.periode_three?.start !== '' && item?.periode_three?.end !== '') {
                            period = item?.periode_one?.start + ' > ' + item?.periode_three?.end

                            const calculateHoursTotal1 = calculateHours(item?.periode_one?.end, item?.periode_two?.start, true)
                            const calculateHoursTotal2 = calculateHours(item?.periode_two?.end, item?.periode_three?.start, true)

                            pause = formatHours(calculateHours(calculateHoursTotal1, calculateHoursTotal2, false))
                        }
                    }
                }

                let isEditable = true
                if(item.date){
                    const dateNow = dateToEpoch(new Date())
                    const dateTimeSheet = dateToEpoch(new Date(item.date))
                    isEditable = dateTimeSheet <= dateNow || item.validated === 1
                }

                return (
                    <>
                        <div className={'flex items-center grid grid-cols-5'}>
                            <div className={'ft-sm w-auto text-gray-900 font-medium'}>
                                {item.name}
                            </div>
                            <div className={'col-span-2 flex flex-col gap-2 ft-sm text-gray-500'}>
                                <div className={'ft-sm text-gray-900 font-medium'}>
                                    <div className={'flex gap-2'}>
                                        <div className={'my-auto'}>
                                            {(item.day_hours && item.validated !== 0) ? ((item.day_hours !== '0' && item.day_hours !== '0h' && item.day_hours !== '0h0') ? `${item.day_hours} travaillées` : 'Jour non travaillé') : null}
                                        </div>
                                        {
                                            (item.isHoursSuggested && !period) ? (
                                                <>
                                                    <div className={'my-auto'}>
                                                        <Tooltips text={'Heures suggérées par l\'entreprise'} placement='top'>
                                                            <div>
                                                                <InformationCircle color={'#9CA3AF'} wh={16}/>
                                                            </div>
                                                        </Tooltips>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </div>
                                <div className={'flex gap-2'}>
                                    <div className={'my-auto'}>
                                        {
                                            period !== 0 && (
                                                <>
                                                    {period + (pause !== 0 ? ' (' + pause + ' de pause)' : '')}
                                                </>
                                            )
                                        }
                                    </div>
                                    {
                                        (item.isHoursSuggested && period) ? (
                                            <>
                                                <div className={'my-auto'}>
                                                    <Tooltips text={'Heures suggérées par l\'entreprise'} placement='top'>
                                                        <div>
                                                            <InformationCircle color={'#9CA3AF'} wh={16}/>
                                                        </div>
                                                    </Tooltips>
                                                </div>
                                            </>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <div className={'flex items-center justify-center'}>
                                {item.day_hours === '0 h' ?
                                    <Chip color={'PRIMARY'} type={'INDICATOR'} disabled={true}>
                                        Non travaillé
                                    </Chip>
                                    : (
                                        <>
                                            {
                                                isEditable ? (
                                                    <Chip color={statusColorDay[item.validated]} type={'INDICATOR'} disabled={true}>
                                                        {statusTextDay[item.validated]}
                                                    </Chip>
                                                ) : (
                                                    <Chip color={'PRIMARY'} type={'INDICATOR'} disabled={true}>
                                                        À venir
                                                    </Chip>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div className={'flex items-center justify-end'}>
                                {
                                    isEditable && (
                                        <div className={'cursor-pointer'}
                                             onClick={(e) => {
                                                 handleModal(e, item.validated, index, item._id, i.validated, item.date)
                                             }}
                                        >
                                            {
                                                i.validated === 0 && statusIcon[item.validated]
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'my-3'}>
                            <Divider w={100}/>
                        </div>
                    </>
                )
            })}
        </>

    );
};

export default TbodyHours;