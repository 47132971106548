import axios from "axios";
import {toast} from "react-toastify";

export default {

    getUserProfile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/info`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTempWorker: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/info`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCompanyProfile: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/${payload.token}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postRoleUser: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/role`,
            data: {
                token: payload.token
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    postPasswordRefresh: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            withCredentials: true,
            data: {
                oldPwd: payload.password,
                password: payload.newPassword,
                id: payload.id
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postEmailRefresh: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/email`,
            withCredentials: true,
            data: {
                email: payload.email,
                token: payload.token,
                tokenMail: payload.tokenMail,
                validation: payload.validation
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postResendEmailToken: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenMail`,
            data: {
                email: payload.email
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postCreateTokenMail: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/createToken`,
            data: {
                email: payload.email,
                token: payload.token
            }

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    // Modal
    //******** Language **************
    getListLanguages: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}language`,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    postUserLanguage: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/language`,
            withCredentials: true,
            data: {
                token: payload.id,
                language: payload.language,
                isParsing: payload.isParsing,
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    deleteUserLanguage: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/languages`,
            withCredentials: true,
            data: {
                token: payload.token,
                language: payload.language
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteUserAllLanguages: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/allLanguages`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    //******** Tools **************
    getListTools: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tools`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    postUserTool: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/tools`,
            withCredentials: true,
            data: {
                token: payload.token,
                tools: payload.tools
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUserToolDelete: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/tools`,
            withCredentials: true,
            data: {
                token: payload.token,
                tool: payload.tool
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    // Modal
    //******** Language **************
    deleteUserCV: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/cv`,
            withCredentials: true,
            data: {
                token: payload.token,
                cv: payload.cv
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    // Mission
//******** GET ALL **************
    getAllMissions: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission`,
            withCredentials: true,
        })
            .then((res) => resolve(res.data))
            .catch(err => reject(err))
    },
    getInfoMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/info?id=${payload.id}`,
            withCredentials: true

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getInfoSearchMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/infoSearchMission?token=${payload.token}&idMission=${payload.idMission}`,
            withCredentials: true

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getInfoMyMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/infoMyMission?token=${payload.token}&idMission=${payload.idMission}`,
            withCredentials: true

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getScheduleByMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}schedule/mission/${payload.id}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postCandidate: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/addUser`,
            withCredentials: true,
            data: {
                token: payload.token,
                mission: payload.mission
            }

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postVerifyUser: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/verify`,
            withCredentials: true,
            data: {
                token: payload.token,
                missionUser: payload.mission
            }

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteUserMission: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}mission/deleteUser`,
            withCredentials: true,
            data: {
                mission: payload.mission,
                missionUser: payload.missionUser
            }

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    // FILE
//******** POST **************
    postFile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}upload/singleFile/CV`,
            withCredentials: true,
            data: {
                file: payload.file,
                token: payload.token
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** GET PATH **************
    getPathFile: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}upload/getSingleFile?id=${payload.id}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
//    Modal Experience
    //******** POST **************
    postCreateExp: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/experience`,
            withCredentials: true,
            data: {
                token: payload.token,
                experience: payload.experience,
                isParsing: payload.isParsing,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** UPDATE **************
    putUpdateExp: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/experience`,
            withCredentials: true,
            data: {
                token: payload.token,
                expID: payload.expID,
                experience: payload.experience
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** DELETE **************
    postDeleteExp: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/experiences`,
            withCredentials: true,
            data: {
                token: payload.token,
                experienceID: payload.expID
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteUserAllExperiences: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/allExperiences`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    //    Modal Licence
    //******** GET **************
    getLicenceById: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tempWorker/get/licence?token=${payload.token}&licenceID=${payload.licenceID}`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** POST **************
    postUserLicenceWithoutFile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/licences`,
            withCredentials: true,
            data: {
                token: payload.token,
                licence: payload.licence,
                isParsing: payload.isParsing,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** GET **************
    getAllLicence: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}licence`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** UPDATE **************
    //******** DELETE **************
    postDeleteLicence: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/licence`,
            withCredentials: true,
            data: {
                token: payload.token,
                licence: payload.objID
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    deleteUserAllLicences: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/allLicences`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //    Modal Formation
    //******** GET **************
    getFormationById: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tempWorker/get/formation?token=${payload.token}&formationID=${payload.formationID}`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** POST **************
    postUserFormationWithoutFile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/formations`,
            withCredentials: true,
            data: {
                token: payload.token,
                formation: payload.formation,
                description: payload.description,
                isParsing: payload.isParsing,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** UPDATE **************
    putUpdateFormation: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/formation`,
            withCredentials: true,
            data: {
                token: payload.token,
                formationID: payload.formationID,
                formation: payload.formation,
                description: payload.description
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** DELETE **************
    postDeleteFormation: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/training`,
            withCredentials: true,
            data: {
                token: payload.token,
                formation: payload.objID
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteUserAllFormations: (payload, resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/allFormations`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
//     JUSTIFICATIFS
    //    IBAN
    //******** POST **************
    postUpdateIban: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/bank`,
            withCredentials: true,
            data: {
                token: payload.token,
                bank: payload.iban
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    //    ACCOUNT
    //******** POST **************
    postUpdateCivility: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/civility`,
            withCredentials: true,
            data: {
                token: payload.token,
                civility: payload.civility,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    postUpdatePhone: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/phone`,
            withCredentials: true,
            data: {
                token: payload.token,
                phone: payload.phone,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    postUpdateAddress: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}address/add`,
            withCredentials: true,
            data: {
                token: payload.token,
                address: payload.address,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getAddress: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}address/${payload.address}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //    Time Sheets
    getTimeSheets: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}timeSheets/allInfo`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTimeSheetInfo: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}timeSheets/${payload.id}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** POST **************
    postUpdateDay: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateDay`,
            withCredentials: true,
            data: {
                timeDay: payload.timeData,
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdateWeek: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateWeek`,
            withCredentials: true,
            data: {
                id: payload.id,
                tHours: payload.totalH,
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //    CONTRACT
    //******** GET **************
    getContracts:(payload,resolve,reject)=>{
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/contract/tempworker?token=${payload.id}&status=${payload.pStatus}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    //******** PUT **************
    putStatusContract:(payload,resolve,reject)=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/updateContractTempworker`,
            withCredentials: true,
            data:{
                contract:payload.contract
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getMissions:(payload,resolve,reject)=>{
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/tempWorker?token=${payload.token}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    /*********SUPPORT*********/
    postSupportMessaging:(payload,resolve,reject)=>{
        axios({
            method:'post',
            url: `${process.env.REACT_APP_API_URL}support/messaging`,
            withCredentials: true,
            data:{
                user_sender: payload.user_sender,
                mail_messaging: payload.mail_messaging,
                type_message: payload.type_message,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putHideProfileCompletion:(payload, resolve, reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/hideProfileCompletion`,
            withCredentials: true,
            data:{
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putUpdateHrflowCandidate:(payload, resolve, reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/hrflowCandidate`,
            withCredentials: true,
            data:{
                token: payload.token,
                data: payload.data,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putCheckTutoBreaks:(payload, resolve, reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/checkTutoBreaks`,
            withCredentials: true,
            data:{
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdateSocialSecurity:(payload, resolve, reject)=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/social_security`,
            withCredentials: true,
            data:{
                token: payload.token,
                socialSecurity: payload.socialSecurity,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putUnavailableDay:(payload, resolve, reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/unavailableDay`,
            withCredentials: true,
            data:{
                token: payload.token,
                day: payload.day,
                isUnavailable: payload.isUnavailable,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putAvailability:(payload, resolve, reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/availability`,
            withCredentials: true,
            data:{
                token: payload.token,
                dateStart: payload.dateStart,
                dateEnd: payload.dateEnd,
                is_available: payload.is_available
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putWelcomeGuide: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/putWelcomeGuide`,
            withCredentials: true,
            data:{
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putSafetyBooklet: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/putSafetyBooklet`,
            withCredentials: true,
            data:{
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putReadPayslip: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/putReadPayslip`,
            withCredentials: true,
            data:{
                token: payload.token,
                idPayslip: payload.idPayslip,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUserInviteMissionAccept: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/inviteAccept`,
            withCredentials: true,
            data:{
                token: payload.token,
                idMission: payload.idMission,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUserInviteMissionRefuse: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/inviteRefuse`,
            withCredentials: true,
            data:{
                token: payload.token,
                idMission: payload.idMission,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCities: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}city`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    updPreferenceDepartment: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/preferenceDepartment`,
            withCredentials: true,
            data:{
                token: payload.token,
                preferences_department: payload.preferences_department,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    searchMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/searchMission?token=${payload.token}&search=${payload.search}&department=${payload.department}&postule=${payload.postule}&typesQualifications=${payload.typesQualifications}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTypesQualifications: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getTypesQualifications?token=${payload.token}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteAccount: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/userAccountDeletion`,
            withCredentials: true,
            data: {
                token: payload.token,
                reason: payload.reason,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    isDeleteAccount: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/checkUserAccountDeletion`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    }
}


