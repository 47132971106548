import React, {useEffect, useState,useRef} from 'react';
import Divider from "components/divider/divider";
import {Delete, Dots, Modify, Warning} from "assets/icons";
import Button from "components/button";
import useModal from "components/modal/useModal";
import {dateExp, tContract} from "helper/helper";
import UseContext from "hooks/useContext";


const CardExperience = ({item}) => {
    const {setExpID} = UseContext()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isWarning, setIsWarning] = useState(false)
    const dropDownRef = useRef(null);
    const {toggle} = useModal()
    
    const handleOutsideClick = (event) => {
        const dropdownElement = dropdownRef.current;
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            setDropdownOpen(false);
          }
      };
    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleModify = (e) => {
        e.preventDefault()
        setExpID(item._id)
        toggle('sf_u_exp')
    }
    const handleDeleted = (e) => {
        e.preventDefault()
        setExpID(item._id)
        toggle('sf_d_exp')
    }

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown",handler)

        return()=>{
            document.removeEventListener("mousedown",handler)
        }
    })

    useEffect(() => {
        if(!item.name || !item.company?.name || !item.company?.location || !item.date?.start || !item.date?.end){
            setIsWarning(true)
        } else {
            setIsWarning(false)
        }
    }, [item])
    return (
        <>
            <div className="grid grid-cols-2 gap-3">
                <div className="col-span-1 ft-b text-gray-900">{item.name ?? null}</div>
                <div className="col-span-1 flex flex-row justify-end gap-6">
                    <div className=' relative flex items-center justify-center' onClick={handleDots}>
                        {isWarning ? <Warning wh={17} color={"#EF4444"}/> : null}
                        <Button size={'SMI'} color={'DEFAULT'} onClick={handleDots}>
                            <Dots w={14} h={4} color={'#374151'}/>
                        </Button>
                        <div
                            ref={dropDownRef} 
                            className={`${dropdownOpen ? "" : "hidden"}  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-4 z-30 bg-gray-50 flex flex-col gap-2`}>
                            <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={handleModify}>
                                <Modify wh={14} color={'#111827'}/> Modifier
                            </div>
                            <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm text-red-600' onClick={handleDeleted}>
                                <Delete wh={14} color={'#DC2626'}/> Supprimer
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-2">
                    <div className="ft-sm text-gray-700">{item.company?.name ?? null} {item.contract ? tContract(item.contract) : null}</div>
                    <div>
                        <div className="ft-sm text-gray-500">{item.date?.start ? (item.date.end ? dateExp(item.date.start) + ' - ' + dateExp(item.date.end) : dateExp(item.date.start)) : ''} </div>
                        <div className="ft-sm text-gray-500">{item.company?.location ?? null}</div>
                    </div>
                </div>
            </div>
            <div className={'my-5'}>
                <Divider wh={100}/>
            </div>
        </>
    );
};

export default CardExperience;