import React from 'react';
import useModal from "components/modal/useModal";
import Button from "components/button";
import {Logout} from "assets/icons";

const LogoutNavDesktop = () => {
    const {toggle} = useModal();
const handleLogout=e=>{
    e.preventDefault()
    toggle('log_d_logout')
}

    return (
        <>
            <div className="w-full flex items-center justify-center p-4" >
                <Button size={'LGI'} color={'DEFAULT'} onClick={handleLogout}><Logout wh={18} color={'#374151'}/></Button>
            </div>

        </>
    );
};

export default LogoutNavDesktop;