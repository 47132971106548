import React, {useEffect, useState} from 'react';
import AvatarCompagny from "components/avatar/avatarcompany";
import {Building, Calendar, Euro, InformationCircle, Localisation} from "assets/icons";
import Chip from "components/chip/chip";
import {NavLink} from "react-router-dom";
import {dateDiff, dateWordShort} from "helper/helper";
import MediaQuery from "react-responsive";
import Tooltips from "../../tooltips/Tooltips";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ReactGA from "react-ga4";


const Cardsearchmission = ({data, isWaiting, isUserInvite = false}) => {
    const Date = dateDiff(data.createdAt)
    const {height, width} = useWindowDimensions();

    const handleMissionClick = () => {
        ReactGA.event({
            category: 'Recherche Mission',
            action: 'click_mission',
            label: `${isUserInvite ? 'Mission' : 'Invitation mission'} ${data.title} n° ${data.id_mission}`
        });
    }

    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={'flex flex-col w-full gap-3 shadow-md rounded-lg animate-fadeIn animate-pulse'}>
                            <div className="flex flex-col p-2">
                                <div className="grid grid-cols-5 mt-2 gap-2">
                                    <div className='col-span-3 tab:col-span-1 flex justify-start items-start tab:items-center tab:justify-center -z-10 w-[64px] h-[64px]'>
                                        <div className={'h-full w-full bg-gray-200 rounded w-full'}></div>
                                    </div>
                                    <div className='col-span-5 order-last tab:order-none tab:col-span-3'>
                                        <div className="grid grid-cols-2">
                                            <div className='col-span-2  ft-xl font-semibold mb-1'>
                                                <div className={'h-4 bg-gray-200 rounded w-3/4'}></div>
                                            </div>
                                            <div className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm mb-1'>
                                                <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                            </div>
                                            <div className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm'>
                                                <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-2 tab:col-span-1 flex items-start justify-end tab:items-start md:justify-center md:col-span-1'>
                                        <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 gap-3 flex flex-col tab:flex-row tab:items-center p-2">
                                <div className='inline-flex items-center gap-3'>
                                    <span className='ft-sm text-gray-500'>
                                        <div className={'h-2 bg-gray-200 rounded w-40'}></div>
                                    </span>
                                </div>
                                <MediaQuery minWidth={769}>
                                    <hr className='h-6 w-0 border border-gray-300'/>
                                </MediaQuery>
                                <div className='inline-flex items-center gap-3'>
                                    <span className='ft-sm text-gray-500'>
                                        <div className={'h-2 bg-gray-200 rounded w-14'}></div>
                                    </span>
                                </div>
                            </div>
                            <div className="col-span-3 flex items-end">
                                <div className='h-9 w-full inline-flex items-center justify-between px-2 bg-gray-300 rounded-b-lg'>
                                    <div className='text-white ft-sm font-semibold w-1/4'>
                                        <div className={'h-2 bg-gray-200 rounded w-full'}></div>
                                    </div>
                                    <p className={'text-white ft-sm font-semibold w-1/4'}>
                                        <div className={'h-2 bg-gray-200 rounded w-full'}></div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <NavLink className={'w-full'} to={`/ficheMissionSearch/${data._id}`} onClick={handleMissionClick}>
                            <div className={'flex flex-col w-full gap-3 shadow-md rounded-lg animate-fadeIn'}>
                                <div className="flex flex-col p-2">
                                    <div className={`flex ${width <= 768 ? 'flex-col' : 'gap-3'} justify-between mt-2`}>
                                        <div className={`flex gap-3`}>
                                            {width > 768 && (
                                                <div className='flex tab:col-span-1 flex justify-start items-start tab:items-center tab:justify-center -z-10'>
                                                    <AvatarCompagny image={''}/>
                                                </div>
                                            )}
                                            <div className='flex flex-col justify-between'>
                                                <div className='col-span-2 ft-xl font-black'>
                                                    {data.title}
                                                </div>
                                                {width > 768 && (
                                                    <div className="flex gap-2">
                                                        <div className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm'>
                                                            <Localisation wh={20} color={'#374151'}/>
                                                            {data?.address[0]?.city}
                                                        </div>
                                                        <div className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm'>
                                                            <Building wh={20} color={'#374151'}/>
                                                            {data?.company[0]?.name_commercial}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`flex ${width > 768 && 'justify-end'} flex-1`}>
                                            <div className={`flex rounded-full bg-green-100 text-green-800 px-2 h-fit text-sm ${width > 768 ? 'max-w-[170px]' : 'max-w-full'}`}>
                                                <div className={'truncate'}>
                                                    {data.activity}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-span-3 gap-3 flex ${width > 768 ? 'flex-col' : 'flex-wrap'} tab:flex-row tab:items-center p-2`}>
                                    {width <= 768 && (
                                        <>
                                            <div className='inline-flex items-center gap-2'>
                                                <Localisation wh={20} color={'#374151'}/>
                                                <div className={'ft-sm text-gray-500'}>
                                                    {data?.address[0]?.city}
                                                </div>
                                            </div>
                                            <div className='inline-flex items-center gap-2'>
                                                <Building wh={20} color={'#374151'}/>
                                                <div className={'ft-sm text-gray-500'}>
                                                    {data?.company[0]?.name_commercial}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='inline-flex items-center gap-2'>
                                        <Calendar color={'#374151'} wh={20}/>
                                        <span className='ft-sm text-gray-500'>
                                            {dateWordShort(data.duration.datestart)} > {dateWordShort(data.duration.dateend)}
                                        </span>
                                    </div>
                                    <MediaQuery minWidth={769}>
                                        <hr className='h-6 w-0 border border-gray-300'/>
                                    </MediaQuery>
                                    <div className='inline-flex items-center gap-2'>
                                        <Euro color={'#374151'} wh={20}/>
                                        <span className='ft-sm text-gray-500'>
                                            {data?.salary.wage_per_hour !== 0 ? data.salary.wage_per_hour + ' €/h' : 'Selon expérience'}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className={`col-span-3 flex items-end bg-yellow-500 ${!isUserInvite && 'rounded-b-lg'}`}>
                                    <div className='h-9 w-full inline-flex items-center justify-between px-2'>
                                        <div className={'my-auto'}>
                                            <div className={'inline-flex gap-2'}>
                                                {isUserInvite && (
                                                    <div className={'my-auto'}>
                                                        <Chip color={'WARNING'} type={'INDICATOR'}>⏰ Invitation</Chip>
                                                    </div>
                                                )}
                                                <div className='my-auto text-white ft-sm font-semibold'>
                                                    il y a {Date.day > 0 ?
                                                    (`${Date.day} ${Date.day === 1 || Date.day === '1' ? 'jour' : 'jours'}`)
                                                    : Date.hour > 0 ?
                                                        (`${Date.hour} ${Date.hour === 1 || Date.hour === '1' ? 'heure' : 'heures'}`)
                                                        : Date.min > 0 ?
                                                            `${Date.min} minutes`
                                                            : Date.sec > 0 ?
                                                                `${Date.sec} secondes` : ''
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'my-auto'}>
                                            <p className={'text-white ft-sm font-semibold'}>
                                                {isUserInvite ? (
                                                    <Tooltips
                                                        text={`Cette entreprise vous a invité pour participer à cette mission. `}
                                                        placement='top'
                                                    >
                                                        <div><InformationCircle wh={20} color={'#FFFFFF'}/></div>
                                                    </Tooltips>
                                                ) : 'Voir plus >'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </>
                )
            }
        </>
    );
};

export default Cardsearchmission;