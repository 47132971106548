import React from 'react';
import Chip from "components/chip/chip";
import {CheckIcon, InformationCircle, Modify} from "assets/icons";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import Divider from "components/divider/divider";
import {calculateHours, dateToEpoch, formatHours} from "../../../helper/helper";
import Tooltips from "../../tooltips/Tooltips";

const Cardfichehour = ({i,index, timeSheet}) => {
    const {toggle} = UseModal()
    const {setTimeData, objID, setContent} = UseContext()
    const statusTextDay = {
        0: 'À compléter',
        1: 'Complet',
        2: 'Complet',
        3: 'Complet',
        4: 'À venir'
    }
    const statusColorDay = {
        0: 'ALERT',
        1: 'INFO',
        2: 'INFO',
        3: 'INFO',
        4: 'PRIMARY'
    }

    const statusIcon = {
        0: <Modify wh={15} color={'#111827'}/>,
        1: <Modify wh={15} color={'#111827'}/>
    }
    const handleModal = (e, dayValidation, index, id, timeSheetValidated) => {
        e.preventDefault()
        if ((dayValidation === 0 || dayValidation === 1) && timeSheetValidated === 0) {
            toggle('doc_c_completedHours')
            setTimeData(prevData => ({
                ...prevData,
                index: index,
                id: id,
                id_time_sheet: objID
            }))

            setContent({
                idMission: objID,
                nameCompany: timeSheet?.company?.name_commercial,
            })
        }
    }

    let period = 0
    let pause = 0
    //Display normal hours
    i.isHoursSuggested = false;

    if(i?.periode_one?.start && i?.periode_one?.end) {
        if (i?.periode_one?.start !== '00:00' && i?.periode_one?.end !== '00:00') {
            period = i?.periode_one?.start + ' > ' + i?.periode_one?.end

            if (i?.periode_one?.start !== '' && i?.periode_one?.end !== '' && i?.periode_two?.start !== '' && i?.periode_two?.end !== '' && i?.periode_three?.start === '' && i?.periode_three?.end === '') {
                period = i?.periode_one?.start + ' > ' + i?.periode_two?.end

                pause = formatHours(calculateHours(i?.periode_one?.end, i?.periode_two?.start, true))

            } else if (i?.periode_one?.start !== '' && i?.periode_one?.end !== '' && i?.periode_two?.start !== '' && i?.periode_two?.end !== '' && i?.periode_three?.start !== '' && i?.periode_three?.end !== '') {
                period = i?.periode_one?.start + ' > ' + i?.periode_three?.end

                const calculateHoursTotal1 = calculateHours(i?.periode_one?.end, i?.periode_two?.start, true)
                const calculateHoursTotal2 = calculateHours(i?.periode_two?.end, i?.periode_three?.start, true)

                pause = formatHours(calculateHours(calculateHoursTotal1, calculateHoursTotal2, false))
            }
        }
    }

    let isEditable = true
    if(i.date){
        const dateNow = dateToEpoch(new Date())
        const dateTimeSheet = dateToEpoch(new Date(i.date))
        isEditable = dateTimeSheet <= dateNow || i.validated === 1
    }

    return (
        <>
        <div className={'flex flex-col px-4 gap-4'}>
            <div className={'flex items-center justify-between'}>
                <p className={'ft-b text-gray-900 font-medium'}>
                    {i.name}
                </p>
                <div className={'flex gap-2'}>
                    {
                        (i.day_hours) ?
                            ((i.day_hours !== '0' && i.day_hours !== '0h' && i.day_hours !== '0h0') ?
                                <Chip
                                    color={statusColorDay[i.validated]}
                                    type={'INDICATOR'}
                                    disabled={true}>
                                    {statusTextDay[i.validated]}
                                </Chip>
                                : (
                                    <>
                                        {
                                            (i.isHoursSuggested && !period) ? (
                                                <>
                                                    <div className={'my-auto'}>
                                                        <Tooltips text={'Heures suggérées par l\'entreprise'} placement='top'>
                                                            <div>
                                                                <InformationCircle color={'#9CA3AF'} wh={16}/>
                                                            </div>
                                                        </Tooltips>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                        <Chip
                                            color={'PRIMARY'}
                                            type={'INDICATOR'}
                                            disabled={true}>
                                            Non travaillé
                                        </Chip>
                                    </>
                                )
                            ) :
                        null
                    }
                    <div className={'flex items-center justify-end'}>
                        {
                            isEditable ? (
                                <div className={'cursor-pointer'}
                                     onClick={(e) => {
                                         handleModal(e, i.validated, index, i._id, timeSheet.validated)
                                     }}
                                >
                                    {
                                        timeSheet.validated === 0 && statusIcon[i.validated]
                                    }
                                </div>
                            ) : (
                                <Chip color={'PRIMARY'} type={'INDICATOR'} disabled={true}>
                                    À venir
                                </Chip>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={'col-span-2 flex flex-col gap-1 ft-sm text-gray-500'}>
                <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                        <div className={'ft-sm text-gray-900 font-medium'}>
                            {i.day_hours ? (i.day_hours !== '0' && i.day_hours !== '0h' && i.day_hours !== '0h0') ? `${i.day_hours} travaillées` : null : null}
                        </div>
                    </div>
                </div>
                <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                        {
                            period !== 0 && (
                                <>
                                    {period + (pause !== 0 ? ' (' + pause + ' de pause)' : '')}
                                </>
                            )
                        }
                    </div>
                    {
                        (i.isHoursSuggested && period) ? (
                            <>
                                <div className={'my-auto'}>
                                    <Tooltips text={'Heures suggérées par l\'entreprise'} placement='top'>
                                        <div>
                                            <InformationCircle color={'#9CA3AF'} wh={16}/>
                                        </div>
                                    </Tooltips>
                                </div>
                            </>
                        ) : null
                    }
                </div>
            </div>
        </div>
        <Divider w={100}/>
        </>
    );
};

export default Cardfichehour;