import React, {useState} from 'react';
import MediaQuery from "react-responsive";
import {JobbizLogo, CloseModal, MenuBar} from "assets/icons";
import NavbarMobile from "./NavbarComponents/NavbarMobile/NavbarMobile";
import NavbarDesktop from "./NavbarComponents/NavbarDesktop/NavbarDesktop";
import {useLocation} from "react-router-dom";


const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const route =useLocation()
    const {pathname}=route
    const path = pathname.split('/')
    const page=path[1]
    const PAGE ={
        'dashboard':'Tableau de bord',
        'profil':'Profil',
        'searchMission':'Recherche de missions',
        'ficheMissionSearch':'Recherche de missions',
        'documents':'Mes documents',
        'mission':'Mes missions',
        'ficheMission':'Mes missions',
        'cv':'Mon Profil',
    }
    return (
        <>
            <header className='fixed tab:sticky z-30 top-0 bg-white border-b border-gray-200 w-full'>
                <div className='tab:px-8'>
                    <div className='flex items-center justify-between h-16 -mb-px'>
                        {/* Header: Left side */}
                        <MediaQuery maxWidth={768}>
                            <div className="flex px-2">
                                <JobbizLogo wh={74}/>
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={769}>
                            <div className="flex">
                                <span className='ft-lg text-gray-700'>{PAGE[page]}</span>
                            </div>
                        </MediaQuery>
                        {/* Header: Right side */}
                        <div className='flex items-center px-2 gap-4'>
                            {/* Hamburger button   */}
                            <button className='z-50 text-gray-600 hover:text-gray-500 tab:hidden'
                                    onClick={handleMenu}>
                                {menuOpen ? <CloseModal/> : <MenuBar/>}
                            </button>
                            <MediaQuery minWidth={769}>
                                <NavbarDesktop/>
                            </MediaQuery>

                        </div>
                    </div>
                </div>
                <MediaQuery maxWidth={768}>
                    <NavbarMobile menuOpen={menuOpen}/>
                </MediaQuery>
            </header>
        </>
    );
};

export default Navbar;