import React from 'react';

const IndicatorIdentity = ({formIndex, pi,nationality}) => {
    const test = pi.nationality || nationality
    return (
        <>
        {test === 'NUE'?
        <div className={'flex gap-4 justify-around mb-8 overflow-x-auto'}>
            <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 1 ? 'border-yellow-200' : 'border-yellow-500'}`}>
                Pièce d’identité
            </div>
            <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 3 ? 'border-yellow-200' :
                formIndex === 4 || formIndex === 5 || formIndex === 6 || formIndex=== 7 || formIndex=== 8 ? 'border-yellow-500' : ''}`}>
                Selfie de vous en tenant votre pièce d’identité
            </div>
            <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 5 ? 'border-yellow-200' :
                formIndex === 6 ||formIndex === 10 || formIndex === 7 ? 'border-yellow-500' : ''}`}>
                Autorisation de travail
            </div>
            <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 7 ||formIndex === 8 ? 'border-yellow-200' :''}`}>
                Confirmation
            </div>
        </div>
            :
            <div className={'flex gap-4 justify-around mb-8 '}>
                <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 1 || formIndex === 6 ? 'border-yellow-200' : 'border-yellow-500'}`}>
                    Pièce d’identité
                </div>
                <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 3 ? 'border-yellow-200' :
                    formIndex === 4 || formIndex === 7 || formIndex=== 5 ? 'border-yellow-500' : ''}`}>
                    Selfie de vous en tenant votre pièce d’identité
                </div>
                <div className={`pt-4 w-40 ft-sm font-medium text-gray-900 border-t-4 min-w-[110px]
            ${formIndex === 5 || formIndex=== 6 ? 'border-yellow-200' :''}`}>
                    Confirmation
                </div>
            </div>
        }

        </>
    );
};

export default IndicatorIdentity;