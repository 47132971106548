import React from 'react';
import {Tabs, Tab} from "components/tabs/tabs";


const SubNavP = () => {
    return (
        <>
            <Tabs>
                <Tab label={'compte'} tabName={'Mon compte'}/>
                <Tab label={'savoir_faire'} tabName={'Mon savoir Faire'}/>
                <Tab label={'justificatifs'} tabName={'Mes justificatifs'}/>
                <Tab label={'disponibilites'} tabName={'Mes disponibilités'}/>
                <Tab label={'training_guides'} tabName={'Formations & Guides'}/>
                <Tab label={'parametres'} tabName={'Paramètres'}/>

            </Tabs>
        </>
    );
};

export default SubNavP;