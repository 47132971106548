import React, {useEffect, useState} from "react";
import axios from "axios";
import {Plus} from "assets/icons";
import Button from "components/button";
import {toast} from "react-toastify";
import UseContext from "../../hooks/useContext";
import ApiDatabase from "../../server";

const AddressGeoApi = (props) => {
    const {companyData}=UseContext()
    //For the address in relation to the API of the FR State
    const [addressGeoClick, setAddressGeoClick] = useState('');
    const [addressGeo, setAddressGeo] = useState('');
    const [addressGeoResult, setAddressGeoResult] = useState('');
    const [addressGeoSuggestion, setAddressGeoSuggestion] = useState({
        received: false,
        loading: false,
        data: [],
    });
    const [formAddress, setFormAddress] = useState(false);

    //For the form address
    const [street, setStreet] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [departmentList, setDepartmentList] = useState([]);
    const [department, setDepartment] = useState('');
    const [cityList, setCityList] = useState([]);
    const [cityPostCode, setCityPostCode] = useState('');
    const [isWaitingDepartments, setIsWaitingDepartments] = useState(false);
    const [isWaitingCities, setIsWaitingCities] = useState(false);
    const [companyAddress, setCompanyAddress] = useState({});
    const [isWaitingCompanyAddress, setIsWaitingCompanyAddress] = useState(false);
    const [isErrorCompanyAddress, setIsErrorCompanyAddress] = useState(false);
    const authorizedDepartments = {
        'createMission': ['Martinique', 'Guadeloupe', 'Saint-Martin'],
        'profileAddress': [],
        'createCompany': [],
    }

    const [addressValue, setAddressValue] = useState({});

    const [isAddressCompany, setIsAddressCompany] = useState(false);

    let timerAddressSuggestion = '';

    const manageAddressSuggestion = async (address) => {
        setAddressGeoSuggestion(
            {
                received: false,
                loading: true,
            }
        );
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}address/addressGeoApi`,
            withCredentials: true,
            data: {
                address: address,
                departments: authorizedDepartments[props.page]
            },
        })
            .then((res) => {
                setAddressGeoSuggestion(
                    {
                        received: true,
                        loading: false,
                        data: res.data,
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                setAddressGeoSuggestion(
                    {
                        loading: false,
                        received: false,
                    }
                );
            });
    }

    const getDepartmentList = async () => {
        setIsWaitingDepartments(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}department/?departments=${authorizedDepartments[props.page]}`,
            withCredentials: true,
        })
            .then((res) => {
                setDepartmentList(res.data);
                setIsWaitingDepartments(false);
            })
            .catch((err) => {
                console.log(err);
                setIsWaitingDepartments(false);
            });
    }

    const getCitiesByDepartment = async (department) => {
        if (department) {
            setIsWaitingCities(true);
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}department/citiesByDepartment?department=${department}`,
                withCredentials: true,
            })
                .then((res) => {
                    setCityList(res.data);
                    setIsWaitingCities(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsWaitingCities(false);
                });
        }
    }

    useEffect(() => {
        if (addressGeo) {

            setAddressGeoSuggestion(
                {
                    received: false,
                    loading: true,
                }
            );

            //Allows changing the value of the input without triggering the FR State api
            setAddressGeoResult(addressGeo);
            setStreet(addressGeo);

            if (addressGeo.length > 6) {
                timerAddressSuggestion = setTimeout(async () => {
                    await manageAddressSuggestion(addressGeo);
                }, 300);
            } else {
                //this_input_result.html('');
            }
            return () => clearTimeout(timerAddressSuggestion);
        }
    }, [addressGeo]);

    useEffect(() => {
        if (addressGeoClick) {
            //Return the information to the parent component
            setAddressValue(addressGeoClick);
        }
    }, [addressGeoClick]);

    useEffect(() => {
        if(formAddress){
            getDepartmentList();
        }
    }, [formAddress]);

    const handleAddressForm = () => {
        if(street && city && postalCode && department){
            const address = {
                street: street,
                complement: complement,
                city: city,
                postcode: postalCode,
                department: department,
            }

            setAddressValue(address);

            setIsErrorCompanyAddress(false);
        } else {
            toast.error('Veuillez remplir tous les champs obligatoires du formulaire');
        }
    }

    useEffect(() => {
        if(addressValue){
            //Hide the form
            setFormAddress(false);

            //Delete suggestions
            setAddressGeoSuggestion(
                {
                    received: false,
                    loading: false,
                }
            );

            //Update the input value
            let addressGeoString = '';
            if(addressValue.street){
                addressGeoString += addressValue.street;
            }
            if(addressValue.complement){
                addressGeoString += ' ' + addressValue.complement;
            }
            if(addressValue.postcode){
                addressGeoString += ' ' + addressValue.postcode;
            }
            if(addressValue.city){
                addressGeoString += ' ' + addressValue.city;
            }

            //Allows changing the value of the input without triggering the FR State api
            setAddressGeoResult(addressGeoString);

            //Update addressValue from parent component
            props.addressGeoValue(addressValue);

            setIsErrorCompanyAddress(false);
        }
    }, [addressValue]);

    useEffect(() => {
        if(props.addressValue && props.addressValue !== addressValue){
            if (props.addressValue.department) {
                if (authorizedDepartments[props.page].length > 0) {
                    if (authorizedDepartments[props.page].includes(props.addressValue.department)) {
                        setAddressValue(props.addressValue);
                        setIsErrorCompanyAddress(false);
                    } else {
                        setAddressValue({});
                        setIsErrorCompanyAddress(true);
                    }
                } else {
                    setAddressValue(props.addressValue);
                }
            }
        }
    }, [props.addressValue]);

    useEffect(() => {
        setIsAddressCompany(props.isAddressCompany);
    }, [props.isAddressCompany]);

    const useCompanyAddress = (e) => {
        e.preventDefault();
        if (Object.keys(companyAddress).length > 0) {
            setAddressValue(companyAddress);
        }
    }

    useEffect(() => {
        getCitiesByDepartment(department);
    }, [department]);

    useEffect(() => {
        if (cityPostCode) {
            cityList.map((city) => {
                if (city.city + '-' + city.postcode === cityPostCode) {
                    setCity(city.city);
                    setPostalCode(city.postcode);
                }
            });
        }
    }, [cityPostCode]);

    useEffect(() => {
        if (isAddressCompany) {
            setIsWaitingCompanyAddress(true);
            ApiDatabase.getAddress({id: companyData.location}, (res) => {
                if(res){
                    if (authorizedDepartments[props.page].includes(res.department)) {
                        setCompanyAddress(res);
                    }
                }
                setIsWaitingCompanyAddress(false);
            })
        }
    }, [isAddressCompany])

    return (
        <>
            <div>
                {
                    formAddress ? (
                        <div className="address-geo-api grid gap-4">
                            <div>
                                <label className='ft-sm text-gray-500'>
                                    <div>
                                        Rue
                                    </div>
                                    <div>
                                        <input type="text"
                                               className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                               value={street}
                                               onChange={e => setStreet(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label className='ft-sm text-gray-500'>
                                    <div>
                                        Complément <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                    </div>
                                    <div>
                                        <input type="text"
                                               className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                               value={complement}
                                               onChange={e => setComplement(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            {isWaitingDepartments ? (
                                <div>
                                    <label className='ft-sm text-gray-500'>
                                        <div>
                                            Département
                                        </div>
                                        <div>
                                            <input type="text"
                                                   className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                                   value={''}
                                                   disabled={true}
                                            />
                                        </div>
                                    </label>
                                </div>
                            ) : (
                                <div>
                                    <label className='ft-sm text-gray-500'>
                                        <div>
                                            Département
                                        </div>
                                        <div>
                                            <select
                                                className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                value={department}
                                                onChange={e => setDepartment(e.target.value)}
                                            >
                                                <option value={''}>Sélectionnez un département</option>
                                                {
                                                    departmentList.map((department, index) => {
                                                        return (
                                                            <option key={index}
                                                                    value={department.name}>{department.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </label>
                                </div>
                            )}
                            {isWaitingCities ? (
                                <div>
                                    <label className='ft-sm text-gray-500'>
                                        <div>
                                            Ville
                                        </div>
                                        <div className={'animate-pulse'}>
                                            <input type="text"
                                                   className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                                   value={''}
                                                   disabled={true}
                                            />
                                        </div>
                                    </label>
                                </div>
                            ) : (
                                <>
                                    {cityList.length > 0 ? (
                                        <div>
                                            <label className='ft-sm text-gray-500'>
                                                <div>
                                                    Ville
                                                </div>
                                                <div>
                                                    <select
                                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                        value={cityPostCode}
                                                        onChange={e => setCityPostCode(e.target.value)}
                                                    >
                                                        <option value={''}>Sélectionnez une ville</option>
                                                        {
                                                            cityList.map((cities, index) => {
                                                                return (
                                                                    <option key={index}
                                                                            value={cities.city + '-' + cities.postcode}>{cities.city} - {cities.postcode}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </label>
                                        </div>
                                    ) : (
                                        <div>
                                            <label className='ft-sm text-gray-500'>
                                                <div>
                                                    Ville
                                                </div>
                                                <div>
                                                    <input type="text"
                                                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                                           value={''}
                                                           disabled={true}
                                                           placeholder={'Sélectionnez une ville'}
                                                    />
                                                </div>
                                            </label>
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="flex justify-center items-center gap-2">
                                <Button size={'LG'} color={'SECONDARY'}
                                        onClick={() => setFormAddress(false)}>Annuler</Button>
                                <Button size={'LG'} onClick={() => handleAddressForm()}>Valider</Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Adresse
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           value={addressGeoResult}
                                           onChange={e => setAddressGeo(e.target.value)}
                                    />
                                </div>
                            </label>
                            <div id="result-address-geo-code-api-gouv" className={"rounded shadow-2xl"}>
                                {
                                    addressGeoSuggestion.loading && (
                                        <div className={'my-5 mx-4 h-3 bg-gray-200 rounded w-2/5'}></div>
                                    )
                                }
                                {
                                    addressGeoSuggestion.received && (
                                        addressGeoSuggestion.data.length > 0 ? (
                                            addressGeoSuggestion.data.map((feature, key) => (
                                                <div key={key}
                                                     className="py-3 px-4 cursor-pointer text-gray-700 address-gouv-suggestion-choice"
                                                     data-street={feature.name}
                                                     data-department={feature.context}
                                                     data-postcode={feature.postcode}
                                                     data-city={feature.city}
                                                     data-lon={feature.lon}
                                                     data-lat={feature.lat}
                                                     onClick={e => setAddressGeoClick(e.target.dataset)}
                                                >
                                                    {feature.label}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="py-3 px-4">Aucun résultat</div>
                                        )
                                    )
                                }
                                {
                                    (addressGeoSuggestion.received || addressGeoSuggestion.loading) && (
                                        <div
                                            className="py-5 px-6 cursor-pointer text-gray-500 border-solid border-1 border-t border-gray-100 add-address-gouv-suggestion flex"
                                            onClick={() => setFormAddress(true)}
                                        >
                                            <div className="my-auto">
                                                <Plus wh="22" color="#374151"/>
                                            </div>
                                            <div className="ml-2 my-auto">Ajouter une adresse</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )}
                {isAddressCompany && (
                    <>
                        {!isWaitingCompanyAddress && Object.keys(companyAddress).length > 0 && (
                            <div className="flex my-2">
                                <button type={'button'} className={'my-auto ml-auto underline'}
                                        onClick={useCompanyAddress}>
                                    Utiliser l'adresse de l'entreprise
                                </button>
                            </div>
                        )}
                    </>
                )}
                {isErrorCompanyAddress && (
                    <div className={'flex my-2 justify-end text-red-500 ft-sm'}>
                        Veuillez indiquez une adresse en {authorizedDepartments[props.page].join(', ')}
                    </div>
                )}
            </div>
        </>
    );
}

export default AddressGeoApi;