import React from 'react';
import Button from "components/button";
import useModal from "components/modal/useModal";
const LogoutNavMobile = () => {
    const {toggle}=useModal()
    const handleLogout=e=>{
        e.preventDefault()
        toggle('log_d_logout')
    }
    return (
        <>
            <div className="w-full flex items-center justify-center p-4" >
                <Button size={'LG'} onClick={handleLogout}>Se déconnecter</Button>
            </div>

        </>
    );
};

export default LogoutNavMobile;