import React from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import useModal from "components/modal/useModal";
import {dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";

const Tbodycontract = ({champsBody}) => {
    const {toggle} = useModal()
    const rUser = localStorage.getItem('rUser')
    const {
        setSignatureLink,
        setDataYousign
    } = UseContext()

    const colorContract = {
        '0': 'ALERT',
        '1': 'INFO'
    }
    const contentContract = {
        '0': 'À signer',
        '1': 'Signé'
    }
    const handleSign = (e, contract, item) => {
        e.preventDefault()
        console.clear()

        setDataYousign({
            yousign_id: contract.yousign_id,
            contract_id: contract._id,
            mission_id: item,
            filename: contract.fileName,
            filepath: contract.filePath
        })
        setSignatureLink(contract.link_signature)
        toggle('doc_c_signature')
    }
    return (
        <>
            {champsBody?.map(item =>
                <tr className={'border-b-2 border-gray-100 '}>
                    <td className={'m-3'}>{item.contract_tempworker[0].id_bestt}</td>
                    <td className={'m-3'}>{!!item.companyCollection[0]?.name_commercial ? item.companyCollection[0]?.name_commercial : ''}</td>
                    <td className={'m-3'}>{item.title}</td>
                    <td className={'m-3'}>{item.id_mission}</td>
                    {item?.contract_tempworker.map(contract =>
                        <td className={'m-3'}>
                            <Chip type={'INDICATOR'} color={colorContract[contract.status]}>{contentContract[contract.status]}</Chip>
                        </td>
                    )}
                    <td className={'m-3'}>{dateWordShort(item.contract_tempworker[0].createdAt)}</td>
                    <td className={'m-3 flex items-center justify-center'}>
                        {item?.contract_tempworker.map(contract =>
                            <td className={'m-3 flex items-center justify-center'}>{
                                contract.status === '0' && rUser !== '200' ?
                                    <span onClick={(e) => {
                                        handleSign(e, contract, item.id_mission)
                                    }}>
                                        <Signer wh={24}/>
                                    </span>
                                    :
                                    <a target='_blank'
                                       href={`${process.env.REACT_APP_INTERIM_PDF}${contract.filePath}`}>
                                        <DocumentIcon wh={35}
                                                      color={'#111827'}/>
                                    </a>
                            }</td>
                        )}</td>
                </tr>
            )}
        </>
    );
};

export default Tbodycontract;