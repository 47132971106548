import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import UseModal from "../../../useModal";
import DatePicker from "react-datepicker";
import {Calendar} from "../../../../../assets/icons";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import usefetchdata from "../../../../../hooks/usefetchdata";
import UseContext from "../../../../../hooks/useContext";
import {dateToEpoch} from "../../../../../helper/helper";
import ReactGA from "react-ga4";

const ModalDisponibility = () => {
    const {tempWorkerData} = usefetchdata();
    const {setRefreshWorker, setAddAvailability, isPostulateWithoutAvailability, setIsPostulateWithoutAvailability} = UseContext()
    const {toggle} = UseModal()
    const [dateStart, setDateStart] = useState()
    const [dateEnd, setDateEnd] = useState()
    const [isAvailableCheck, setIsAvailableCheck] = useState(true)
    const token = localStorage.getItem('xsrfToken')

    const confirmDiponibility = () => {
        if(!isAvailableCheck){
            if((!dateStart && dateEnd)){
                toast.error('Veuillez remplir la date de début')
                return
            }
        }

        if(isPostulateWithoutAvailability?._id) {
            ApiDatabase.getInfoSearchMission({token, idMission: isPostulateWithoutAvailability._id},
                (data) => {
                    if(data.duration.datestart && data.duration.dateend){
                        ReactGA.event({
                            category: 'Postuler à une mission - Disponibilités modifiées',
                            action: 'postulate_mission',
                            label: `${isPostulateWithoutAvailability?.isUserInvited ? 'Mission' : 'Invitation mission'} ${isPostulateWithoutAvailability?.title} n° ${isPostulateWithoutAvailability?.id_mission}`
                        });
                        let isUserCanPostulate = true;

                        const availableDateStart = dateStart ? dateToEpoch(dateStart) : false;
                        const availableDateEnd = dateEnd ? dateToEpoch(dateEnd) : false;
                        const missionDateStart = dateToEpoch(data.duration.datestart);
                        const missionDateEnd = dateToEpoch(data.duration.dateend);

                        let isUserCanPostulateDateStart = false;
                        let isUserCanPostulateDateEnd = false;

                        if (availableDateStart instanceof Date && availableDateEnd instanceof Date) {
                            isUserCanPostulateDateEnd = (availableDateEnd.getTime() >= missionDateStart.getTime() && availableDateEnd.getTime() <= missionDateEnd.getTime()) || (availableDateStart.getTime() >= missionDateStart.getTime() && availableDateStart.getTime() <= missionDateEnd.getTime());
                            isUserCanPostulateDateStart = isUserCanPostulateDateEnd
                        }

                        if (availableDateStart instanceof Date && !availableDateEnd) {
                            isUserCanPostulateDateStart = availableDateStart.getTime() >= missionDateStart.getTime()
                        }

                        if (availableDateEnd instanceof Date && !availableDateStart) {
                            isUserCanPostulateDateEnd = missionDateStart.getTime() <= availableDateEnd.getTime()
                        }

                        isUserCanPostulate = isUserCanPostulateDateStart || isUserCanPostulateDateEnd;

                        if (!isUserCanPostulate && isAvailableCheck && !availableDateStart && !availableDateEnd) {
                            isUserCanPostulate = true;
                        }

                        if (!isUserCanPostulate) {
                            toast.error('Les disponibilités transmises ne sont pas compatibles avec la mission sélectionnée');
                        } else {
                            addAvailability();
                        }
                    }
                },
                (err) => {
                    console.error(err)
                })
        } else {
            addAvailability()
        }
    }

    const addAvailability = () => {
        const dateStartIso = dateStart ? dateToEpoch(new Date(dateStart)).toString().split(' ').slice(0, 4).join(' ') : null;
        const dateEndIso = dateEnd ? dateToEpoch(new Date(dateEnd)).toString().split(' ').slice(0, 4).join(' ') : null;
        ApiDatabase.putAvailability({token, dateStart: dateStartIso, dateEnd: dateEndIso, is_available: isAvailableCheck}, (data) => {
            setRefreshWorker(prev => !prev)
            toggle()
            if (isPostulateWithoutAvailability?._id) {
                setAddAvailability(true)
                setIsPostulateWithoutAvailability({})
            } else {
                toast.success('Votre disponibilité a bien été enregistrée')
            }
        }, err => {
            toast.error('Une erreur est survenue')
            console.log(err)
        });
    }

    useEffect(() => {
        if(tempWorkerData?.availability){
            if(tempWorkerData?.availability?.date_start){
                setDateStart(dateToEpoch(tempWorkerData.availability.date_start))
            }
            if(tempWorkerData?.availability?.date_end){
                setDateEnd(dateToEpoch(tempWorkerData.availability.date_end))
            }
            if(typeof tempWorkerData?.availability?.is_available === 'boolean'){
                setIsAvailableCheck(tempWorkerData.availability.is_available)
            }
        }
    }, [tempWorkerData]);

    const manageAvalaibilitySwitch = () => {
        const originalAvailableCheck = isAvailableCheck
        const today = dateToEpoch(new Date())
        if(originalAvailableCheck){
            //if date start < today delete date start and date end
            if(dateStart) {
                if(dateToEpoch(new Date(dateStart)).getTime() <= today.getTime()){
                    setDateStart(null)
                    setDateEnd(null)
                }
            } else {
                setDateStart(null)
                setDateEnd(null)
            }
        } else {
            setDateStart(null)
            setDateEnd(null)
        }
        setIsAvailableCheck(!originalAvailableCheck)
    }

    useEffect(() => {
        if (dateToEpoch(dateStart).getTime() === dateToEpoch(new Date()).getTime()){
            setIsAvailableCheck(true)
            setDateStart(null)
        }
    }, [dateStart]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={false}>
                Confirmer mes disponibilités
            </Modalheader>
            <ModalBody>
                <div className="flex flex-col gap-5">
                    <div className={'text-gray-500 text-sm'}>
                        {
                            isPostulateWithoutAvailability?._id
                                ? 'Vous avez indiqué ne pas être disponible pour cette mission, modifiez vos disponibilités pour postuler'
                                : 'Définissez votre période de disponibilités pour nous indiquer quand vous êtes disponibles pour travailler et recevoir des propositions de missions.'
                        }
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className={'flex justify-between'}>
                            <div className={'my-auto'}>Je suis disponible dès maintenant</div>
                            <div className={'my-auto flex'}>
                                <label className="relative inline-flex items-center cursor-pointer my-auto">
                                    <input type="checkbox"
                                           onChange={() => manageAvalaibilitySwitch()}
                                           className="sr-only peer"
                                           checked={isAvailableCheck}
                                    />
                                    <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-500"></div>
                                </label>
                            </div>
                        </div>
                        {
                            !isAvailableCheck && (
                                <div className="col-span-1">
                                    <label
                                        htmlFor="dateStart"
                                        className="block ft-sm font-medium  text-gray-500"
                                    >
                                        Je suis disponible à partir de - (Optionnel)
                                    </label>
                                    <div className='relative'>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={dateStart}
                                            onChange={(date) => setDateStart(date)}
                                            disabledKeyboardNavigation
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            minDate={new Date()}
                                            maxDate={dateEnd}
                                            dropdownMode="select"
                                            popperPlacement="bottom"
                                            className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                                        />
                                        <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                            <Calendar wh={18} color={'#666666'} strokeW={2}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-span-1">
                            <label
                                htmlFor="dateStart"
                                className="block ft-sm font-medium  text-gray-500"
                            >
                                Jusqu'au... - (Optionnel)
                            </label>
                            <div className='relative'>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateEnd}
                                    onChange={(date) => setDateEnd(date)}
                                    disabledKeyboardNavigation
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={!isAvailableCheck ? (dateStart ?? new Date().setDate(new Date().getDate() + 1)) : new Date()}
                                    dropdownMode="select"
                                    popperPlacement="bottom"
                                    className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                                />
                                <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                    <Calendar wh={18} color={'#666666'} strokeW={2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter >
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={() => confirmDiponibility()}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default ModalDisponibility;