import React, {useState} from 'react';
import {ArrowLeft} from "assets/icons";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "components/button";
import UseContext from "hooks/useContext";

const SubNavMobileFile = () => {
    const {objID}=UseContext()
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleText = (e) => {
        e.preventDefault()
        switch (e.target.textContent){
            case 'À signer':
                navigate('./contracts/nosign')
                break
            case 'Signé':
                navigate('./contracts/sign')
                break
            case 'Complet':
                navigate('./hours/completed')
                break
            case 'À compléter':
                navigate('./hours/nocompleted')
                break
            default:
                break
        }
    }
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()
    const pRoute = {
        '/documents/contracts': 'Mes Contrats',
        '/documents/contracts/sign': 'Mes Contrats',
        '/documents/contracts/nosign': 'Mes Contrats',
        '/documents/hours': 'Relevés d’heures',
        '/documents/hours/nocompleted': 'Relevés d’heures',
        '/documents/hours/completed': 'Relevés d’heures',
        '/documents/payslip':'Fiche de paie'
    }
    const pButton ={
        '/documents/contracts': 'Menu',
        '/documents/hours': 'Menu',
        '/documents/contracts/sign': 'Signé',
        '/documents/contracts/nosign': 'À signer',
        '/documents/hours/completed': 'Complet',
        '/documents/hours/nocompleted': 'À compléter',

    }
    const pContentT={
        '/documents/contracts/sign': 'Signé',
        '/documents/contracts/nosign': 'Signé',
        '/documents/hours/completed': 'Complet',
        '/documents/hours/nocompleted': 'Complet',
        '/documents/contracts': 'Signé',
        '/documents/hours': 'Complet',
    }
    const pContentF={
        '/documents/contracts': 'À signer',
        '/documents/hours': 'À compléter',
        '/documents/contracts/nosign': 'À signer',
        '/documents/contracts/sign': 'À signer',
        '/documents/hours/completed': 'À compléter',
        '/documents/hours/nocompleted': 'À compléter',
    }
    return (
        <div
            className={`col-span-full h-14 w-full flex items-center ${pathname === '/documents'?'justify-center':'justify-between' }  gap-2 ft-b font-bold text-gray-700 border-b b-gray-500`}>
            {pathname === '/documents' ? 'Mes documents' :
                <div className='flex items-center justify-center'>
                <span className=' left-0 pl-4 mr-3' onClick={e => {
                    pathname.includes('ficheHour')?navigate(-1):navigate('/documents')
                }
                }>
            <ArrowLeft w={15} h={12}/>
                </span>
                    <h1 className='text-center'>{pathname.includes('ficheHour') ?`Relevé n° ${objID}`:pRoute[pathname]}</h1>
                < /div>
            }
            {pathname === '/documents/contracts' || pathname === '/documents/contracts/sign' ||pathname === '/documents/contracts/nosign'  || pathname ==='/documents/hours' || pathname ==='/documents/hours/nocompleted' || pathname ==='/documents/hours/completed'?
            <div className='relative flex items-center justify-center'
                 onClick={handleDots}>
                <Button size={'SMI'} color={'DEFAULT'}
                        onClick={handleDots}>{pButton[pathname] }</Button>
                <div className={`${
                    dropdownOpen ? "" : "hidden"
                }  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-1  bg-gray-50 flex flex-col gap-2`}>
                    <div
                        className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                        onClick={handleText}>{pContentT[pathname]}
                    </div>
                    <div
                        className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                        onClick={handleText}>{pContentF[pathname]}
                    </div>
                </div>

            </div>
                :null}
        </div>
    );
};


export default SubNavMobileFile;