import React, { useEffect, useState } from "react";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import ApiDatabase from "server";
import { toast } from "react-toastify";
import usefetchdata from "hooks/usefetchdata";
import Banner from "components/banner/banner";
import UseContext from "hooks/useContext";
import Iban from "components/Iban/Iban";
import LoadingButton from "components/button/loading.js";

const ModifyiIban = () => {
    const { toggle } = useModal();
    const { tempWorkerData } = usefetchdata();
    const { language, refreshLoadWorker } = UseContext();
    const [bank, setBank] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tempWorkerData?.bank?.iban) {
            setBank(tempWorkerData?.bank?.iban);
        }
    }, [tempWorkerData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (bank) {
            setLoading(true);
            ApiDatabase.postUpdateIban(
                {
                    token: localStorage.getItem("xsrfToken"),
                    iban: bank,
                },
                (data) => {
                    setLoading(false);
                    refreshLoadWorker();
                    toast.success("L'identité bancaire est modifiée.");
                    toggle();
                },
                (err) => {
                    setLoading(false);
                    toast.error("Un problème survenu");
                }
            );
        } else {
            setLoading(false);
            toast.error("Veuillez remplir le champ");
        }
    };

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue("justify.m_iban_title_u")}
            </Modalheader>
            <ModalBody>
                <div className={'flex flex-col gap-3'}>
                    <div className={''}>
                        <Banner type={"info"}>Votre paie vous sera versée le 11 du mois.</Banner>
                    </div>
                    {tempWorkerData?.bank?.validated === "3" ? (
                        <Banner type={"alert"}>Motif a venir</Banner>
                    ) : (
                        ""
                    )}
                    {tempWorkerData?.bank?.validated === "1" ? (
                        <Banner type={"warning"}>
                            Un document est déjà en cours d’observation, la
                            modification reste possible.
                        </Banner>
                    ) : (
                        ""
                    )}
                    <form className="grid gap-2">
                        <Iban iban={bank} setIban={setBank} />
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        <Button
                            size={"LG"}
                            color={"SECONDARY"}
                            onClick={toggle}
                        >
                            Annuler
                        </Button>
                        <Button size={"LG"} onClick={handleSubmit}>
                            Confirmer
                        </Button>
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default ModifyiIban;
