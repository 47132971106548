import React from 'react';
import Divider from "components/divider/divider";
import {DocumentIcon, Signer} from "assets/icons";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import Chip from "components/chip/chip";
import {dateWordShort} from "helper/helper";

const Cardcontract = ({champsBody, isWaiting}) => {
    const {toggle} = useModal()
    const rUser = localStorage.getItem('rUser')
    const {
        setSignatureLink,
        setDataYousign
    } = UseContext()

    const colorContract = {
        '0': 'ALERT',
        '1': 'INFO'
    }
    const contentContract = {
        '0': 'À signer',
        '1': 'Signé'
    }
    const handleSign = (e, contract, item) => {
        e.preventDefault()

        setDataYousign({
            yousign_id: contract.yousign_id,
            contract_id: contract._id,
            mission_id: item,
            filename: contract.fileName,
            filepath: contract.filePath
        })
        setSignatureLink(contract.link_signature)
        toggle('doc_c_signature')
    }
    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={'flex flex-col gap-4 px-4 py-5 animate-fadeIn animate-pulse'}>
                            <p className={'h-2 bg-gray-200 rounded w-2/4'}></p>
                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                            <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                        </div>
                        <Divider w={100}/>
                    </>
                ) : (
                    <>
                        <div className={'flex justify-between h-28 px-4 py-5 animate-fadeIn'}>
                            <div className={'flex flex-col gap-2'}>
                                <div className={'ft-b text-gray-900 font-medium'}>
                                    Contrat N° {champsBody?.contract_tempworker[0].id_bestt}
                                </div>
                                <div>
                                    {champsBody?.contract_tempworker.map(contract =>
                                        <Chip type={'INDICATOR'}
                                              color={colorContract[contract.status]}>{contentContract[contract.status]}</Chip>
                                    )}
                                </div>
                                <div className={'flex items-center'}>
                                    {!!champsBody.companyCollection[0]?.name_commercial ? champsBody.companyCollection[0]?.name_commercial : ''} . {dateWordShort(champsBody.createdAt)}
                                </div>

                            </div>

                            {champsBody?.contract_tempworker.map(contract =>
                                <div
                                    className={'m-3 flex items-center justify-center'}>
                                    {contract.status === '0' && rUser !== '200' ?
                                        <span onClick={(e) => {
                                            handleSign(e, contract, champsBody._id)
                                        }}>
                                        <Signer wh={24}/>
                                    </span>
                                        :
                                        <a target='_blank'
                                           href={`${process.env.REACT_APP_INTERIM_PDF}${contract.filePath}`}>
                                            <DocumentIcon wh={35}
                                                          color={'#111827'}/>
                                        </a>

                                    }
                                </div>)}

                        </div>
                        <Divider w={100}/>
                    </>
                )
            }
        </>
    );
};

export default Cardcontract;