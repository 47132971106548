import React, {useEffect, useState} from 'react';
import Table from "components/table/table";
import EmptyState from "components/EmptyState/EmptyState";
import ApiDataBase from "server";
import Cardhour from "components/card/cardhour";
import MediaQuery from "react-responsive";
import {dateToEpoch} from "../../../../helper/helper";
import useTitle from "../../../../hooks/TitleManager";

const Nocompleted = () => {
    useTitle({ title: "Relevés d'heures à compléter" });
    const [arrayTimeSheets,setArrayTimeSheets]=useState([])
    const [dataTimeSheets,setDataTimeSheets]=useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(()=>{
        const token = localStorage.getItem('xsrfToken')
        ApiDataBase.getTimeSheets({token:token},
            (data)=>{
                setArrayTimeSheets(data)
                setIsWaiting(false)
            },
            (err)=>{
                console.log(err)
            })
    },[])
    const titles=['N° de relevé','MISSION','N° De mission', 'ENTREPRISE','ÉTAT','période']
    useEffect(() => {
        let tampon = []
        for (let m in arrayTimeSheets) {
            if (arrayTimeSheets[m].validated === 0 && dateToEpoch(new Date(arrayTimeSheets[m].periode_start)) <= dateToEpoch(new Date())) {
                tampon.push(arrayTimeSheets[m])
            }
        }

        setDataTimeSheets(tampon)
    }, [arrayTimeSheets])
    return (
        <>
               <MediaQuery minWidth={770}>
                   {
                       isWaiting ? (
                           <>
                                {
                                    [1, 2, 3, 4, 5].map((i, index)=>(
                                        <Table champsBody={i} champsHeader={titles} isWaiting={isWaiting}/>
                                    ))
                                }
                           </>
                       ) : (
                           <>
                               {
                                   dataTimeSheets.length > 0 ?
                                       <Table champsBody={dataTimeSheets} champsHeader={titles}/>
                                       :
                                       <EmptyState type={'time_sheet'}/>
                               }
                           </>
                       )
                   }
               </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index)=>(
                                    <Cardhour item={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {dataTimeSheets?.length > 0 ?
                                dataTimeSheets.map(item =>
                                    <Cardhour item={item}/>
                                )
                                :
                                <EmptyState type={'time_sheet'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            </>
    );
};

export default Nocompleted;