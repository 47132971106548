import React from 'react';
import {Outlet} from "react-router-dom";
import useTitle from "../../../../hooks/TitleManager";
const Hourscontent = () => {
    useTitle({ title: "Relevés d'heures" });
    return (
        <>
            <Outlet/>
        </>
    );
};

export default Hourscontent;