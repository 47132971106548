import {forwardRef} from "react";

const BadgeSquare = ({type, children,...props})=> {
    const body={
        '0': `bg-red-100`,
        '3': `bg-red-100`,
        '2': ``,
        '1': `bg-yellow-100`,
    }
    const content={
        '0': `text-red-800`,
        '3': `text-red-800`,
        '2': ``,
        '1': `text-yellow-800`,
    }
    const text={
        '0': `À importer`,
        '3': `Refusé`,
        '2': ``,
        '1': `Vérification en cours`,
    }

    if(body[type] === undefined && content[type] === undefined && text[type] === undefined) return null

    return (
        <>
            <div className={`flex justify-center items-center ft-sm rounded ${body[type]}`}>
                <div className={`px-2 py-1 ${content[type]}`}>{text[type]}</div>
            </div>
        </>
        )
}

export default BadgeSquare;