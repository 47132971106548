import React from 'react';

const Modalstructure = ({isShowing, children}) => {
    return (
        <>
            {isShowing ?
                <>

                    <div className="flex fixed justify-center items-center inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full max-w-3xl my-6 mx-auto h-full py-6 overflow-y-auto no-scrollbar">
                            <div
                                className="relative flex flex-col max-w-3xl rounded-lg border-0 sh-lg bg-white outline-none focus:outline-none max-h-full overflow-y-auto">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-70 fixed inset-0 z-40 bg-gray-700"></div>
                </> : null}
        </>
    );
};

export default Modalstructure;