import React, {useEffect, useState} from 'react';

import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {Calendar, Localisation} from "assets/icons";
import DatePicker from "react-datepicker";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";


const Modalexperience = () => {
    const {refreshLoadWorker} = UseContext()
    const {toggle} = useModal()
    const [experience, setExperience] = useState({
        metier: '',
        entreprise: '',
        localisation: '',
        dateStart: '',
        dateEnd: ''
    })
    const [error, setError] = useState(false)
    const [check, setCheck] = useState(false)
    useEffect(() => {
        if (experience.dateStart > experience.dateEnd && experience.dateEnd !== '') setError(true)
        else setError(false)
    }, [experience.dateStart, experience.dateEnd])

    const handleChange = e => {
        e.preventDefault()
        const type = e.type.target
        const name = e.target.name

        const value = type === 'checkbox'
            ? e.target.checked
            : e.target.value

        setExperience(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const cond1 = (p) => (p.dateStart !== '' && !error && check)
    const cond2 = (p) => (p.dateStart !== '' && p.dateEnd !== '' && !error && !check)
    const handleSubmit = e => {
        if(check && experience.dateEnd !=='') experience.dateEnd = ''

        if (cond1(experience) || cond2(experience)) {
            ApiDatabase.postCreateExp({
                    token: localStorage.getItem('xsrfToken'),
                    experience: experience,
                    isParsing: false,
                },
                (data) => {
                    if (data.success) {
                        refreshLoadWorker()
                        toast.success(`Votre expérience ${experience.metier} est ajoutée`)
                        setExperience({
                            metier: '',
                            entreprise: '',
                            localisation: '',
                            dateStart: '',
                            dateEnd: ''
                        })
                        toggle()
                    }
                },
                (err) => {
                    toast.error('une erreur est survenu')

                })
        } else {
            toast.error('Veuillez remplir tous les champs')
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Ajouter une expérience</Modalheader>
            <ModalBody>
                <form className='grid grid-cols-2 gap-2'>
                    <div className="col-span-2">
                        <label
                            htmlFor="metier"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Métier
                        </label>
                        <div className=" text-gray-500">
                            <input
                                name="metier"
                                pattern="([A-Z])[\w+.]{1,}"
                                value={experience.metier}
                                onChange={handleChange}
                                className="w-full h-10 px-3 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="entreprise"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Nom de l'entreprise
                        </label>
                        <div className="text-gray-500">
                            <input
                                name="entreprise"
                                value={experience.entreprise}
                                onChange={handleChange}
                                className="w-full h-10 px-3 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                pattern="([A-Z])[\w+.]{1,}"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="localisation"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Lieu de travail
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="localisation"
                                value={experience.localisation}
                                onChange={handleChange}
                                className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                required
                            />

                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Localisation wh={18} color={'#666666'}
                                              strokeW={2}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label
                            htmlFor="dateStart"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Date de début
                        </label>
                        <div className='relative'>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={experience.dateStart}
                                onChange={(date) => setExperience({
                                    ...experience,
                                    dateStart: date
                                })} disabledKeyboardNavigation
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                popperPlacement="bottom"
                                className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Calendar wh={18} color={'#666666'}
                                          strokeW={2}/>
                            </div>
                        </div>
                    </div>
                    {check ? '' :
                        <div className="col-span-1">
                            <label
                                htmlFor="dateStart"
                                className="block ft-sm font-medium  text-gray-500"
                            >
                                Date de Fin
                            </label>
                            <div className='relative'>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={experience.dateEnd}
                                    onChange={(date) => setExperience({
                                        ...experience,
                                        dateEnd: date
                                    })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    popperPlacement="bottom"
                                    className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                                />
                                <div
                                    className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                    <Calendar wh={18} color={'#666666'}
                                              strokeW={2}/>
                                </div>
                            </div>
                            {error ?
                                <span className='ft-sm text-red-500'>Veuillez indiquer une date valide</span> : ''}
                        </div>
                    }
                    <div className='col-span-2 flex gap-2'>
                        <input name='check' type="checkbox"
                               onChange={() => setCheck(!check)}/>
                        <label className='ft-sm text-gray-700' htmlFor="check">J’occupe
                            actuellement ce poste</label>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Modalexperience;