import React from "react";
import Button from "components/button";

const Footerbuttonupdate = ({
    pi,
    nationality,
    formIndex,
    oC1,
    oC2,
    oC3,
    oC4,
    oC5,
}) => {
    const test = pi || nationality;
    const elementsNUE = [
        <>
            <Button key={0} size={"LG"} onClick={oC1}>
                Fermer
            </Button>
        </>,
        <>
            <Button key={1} size={"LG"} onClick={oC5}>
                Suivant
            </Button>
        </>,
        <>
            <Button key={1} size={"LG"} onClick={oC2}>
                Modifier
            </Button>
        </>,
        <>
            <Button key={2} size={"LG"} onClick={oC5}>
                Suivant
            </Button>
        </>,
        <>
            <Button key={2} size={"LG"} onClick={oC3}>
                Modifier
            </Button>
        </>,
        <>
            <Button key={3} size={"LG"} onClick={oC5}>
                Suivant
            </Button>
        </>,
        <>
            <Button key={3} size={"LG"} onClick={oC4}>
                Modifier
            </Button>
        </>,
    ];

    const elementsUE = [
        <>
            <Button key={0} size={"LG"} onClick={oC1}>
                Fermer
            </Button>
        </>,
        <>
            <Button key={1} size={"LG"} onClick={oC5}>
                Suivant
            </Button>
        </>,
        <>
            <Button key={1} size={"LG"} onClick={oC2}>
                Modifier
            </Button>
        </>,
        <>
            <Button key={2} size={"LG"} onClick={oC5}>
                Suivant
            </Button>
        </>,
        <>
            <Button key={2} size={"LG"} onClick={oC3}>
                Modifier
            </Button>
        </>,
    ];

    return (
        <>
            {(test === "NUE" ? elementsNUE : elementsUE).filter(
                (item, index) => index + 1 === formIndex
            )}
        </>
    );
};

export default Footerbuttonupdate;
