import React from 'react';
import {dateToEpoch, dateWordShort} from "helper/helper";
import Divider from "components/divider/divider";
import {useNavigate} from "react-router-dom";
import Badge from "components/badge/badge";

const Cardmissionmobile = ({data, isWaiting}) => {
    const navigate = useNavigate()
    const d0 = dateToEpoch(new Date())
    const d1 = dateToEpoch(data?.duration?.datestart)
    const d2 = dateToEpoch(data?.duration?.dateend)
    const statutMission = () => {
        if (d0.getTime() > d2.getTime()) return 'ALERT'
        if ((d0.getTime() >= d1.getTime()) && (d0.getTime() <= d2.getTime())) return 'VALID'
        if (d0.getTime() < d1.getTime()) return 'PRIMARY'
    }
    const contentFooter = {
        'ALERT': 'Terminée',
        'VALID': 'En cours',
        'PRIMARY': 'À venir'
    }
    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={'flex flex-col h-36 gap-4  px-2 py-2 animate-fadeIn'}>
                            <p>
                                <div className={'h-4 bg-gray-200 rounded w-full'}></div>
                            </p>
                            <div className={'w-1/4'}>
                                <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                            </div>
                            <div className={'ft-sm text-gray-700'}>
                                <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                            </div>
                            <div className={'ft-sm text-gray-700'}>
                                <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                            </div>
                        </div>
                        <div className={'my-5'}>
                            <Divider w={100}/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'flex flex-col h-36 gap-4  px-2 py-2 animate-fadeIn'}
                             onClick={(e) => {
                                 navigate(`/ficheMission/${data?._id}`)
                             }}>

                            <p>{data?.title}</p>
                            <div className={'w-1/4'}>
                                <Badge type={statutMission()}>
                                    {contentFooter[statutMission()]}
                                </Badge>
                            </div>
                            <div className={'ft-sm text-gray-700'}>
                                {data?.company[0].name_commercial} - {data?.address[0]?.city??null}
                            </div>
                            <div className={'ft-sm text-gray-700'}>
                                {dateWordShort(data?.duration?.datestart)} > {dateWordShort(data?.duration?.dateend)}
                            </div>
                        </div>
                        <div className={'my-5'}>
                            <Divider w={100}/>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default Cardmissionmobile;