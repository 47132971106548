import React from 'react';
import {MissionIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";

const MissionDl = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitDesktop routeName={"Mes Missions"} routeSidebar={"/mission/in_progress"}>
            <span className='shrink-0'>
            <MissionIcon wh={40} color={`${pathname.includes("mission") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("mission") ? "text-yellow-500" : ''
                }`}
            >
          {language.getValue('sidebar.mission','Mes missions')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default MissionDl;