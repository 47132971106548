import React from 'react';
import {TabsM,TabM} from "components/tabs/tabmission";
const SubNavMission = () => {
    return (
        <>
            <TabsM>
                <TabM label={"completed"} tabName={'Terminées'}/>
                <TabM label={"in_progress"} tabName={'En cours'}/>
                <TabM label={"coming_soon"} tabName={'À venir'}/>
            </TabsM>
        </>
    );
};

export default SubNavMission;