import React from "react";
import { DocumentIcon, Pencil } from "assets/icons";
import BadgeSquare from "components/badge/badgeSquare";

const Confirmationimport = ({ setFormIndex, userData, nationality }) => {

    const pIndex = (p) => p === "NUE";
    const handleModifyPI = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(1);
        else {
            setFormIndex(1);
        }
    };

    const handleModifySelfie = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(3);
        else {
            setFormIndex(2);
        }
    };

    const handleModifyAttest = (e) => {
        e.preventDefault();
        if (pIndex(nationality)) setFormIndex(5);
    };
    return (
        <div className={"flex flex-col gap-4"}>
            <div className="flex items-center justify-between">
                <div className="flex gap-2">
                    <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                        <DocumentIcon wh={35} color={"#111827"} />
                    </div>
                    <div className="col-span-3 ft-b text-gray-900 my-auto">
                        <div className={`text-gray-900`}>Pièce d'identité</div>
                    </div>
                </div>
                <div className="col-span-1 flex">
                    <div className="my-auto mr-2">
                        <BadgeSquare type={0} />
                    </div>
                    <div className="my-auto">
                        <button
                            onClick={handleModifyPI}
                            className="rounded-full w-10 h-10 flex items-center justify-center"
                        >
                            <Pencil wh={22} color={"#374151"} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className="flex gap-2">
                    <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                        <DocumentIcon wh={35} color={"#111827"} />
                    </div>
                    <div className="col-span-3 ft-b text-gray-900 my-auto">
                        <div className={`text-gray-900`}>Selfie d'identité</div>
                    </div>
                </div>
                <div className="col-span-1 flex">
                    <div className="my-auto mr-2">
                        <BadgeSquare type={0} />
                    </div>
                    <div className="my-auto">
                        <button
                            onClick={handleModifySelfie}
                            className="rounded-full w-10 h-10 flex items-center justify-center"
                        >
                            <Pencil wh={22} color={"#374151"} />
                        </button>
                    </div>
                </div>
            </div>
            {nationality === "NUE" ? (
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className="relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full">
                            <DocumentIcon wh={35} color={"#111827"} />
                        </div>
                        <div className="col-span-3 ft-b text-gray-900 my-auto">
                            <div className={`text-gray-900`}>
                                Autorisation de travail
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex">
                        <div className="my-auto mr-2">
                            <BadgeSquare type={0} />
                        </div>
                        <div className="my-auto">
                            <button
                                onClick={handleModifyAttest}
                                className="rounded-full w-10 h-10 flex items-center justify-center"
                            >
                                <Pencil wh={22} color={"#374151"} />
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Confirmationimport;
