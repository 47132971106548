import React, {useEffect, useState} from 'react';
import {Cadenas, Delete, Mail, TrashIcon} from "assets/icons";
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";
import useTitle from "../../../../hooks/TitleManager";
import ApiDatabase from "../../../../server";

const Parametre = () => {
    useTitle({ title: "Profil - Paramètres" });
    const {userData} = usefetchdata()
    const {toggle} = useModal()
    const [isDeleteAccount, setIsDeleteAccount] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    useEffect(()=>{
        if(userData.tokenmail) toggle('tok_u_tokenEmail')
    },[userData])
    const handleEmail = (e) => {
        e.preventDefault()
        toggle('par_u_email')
    }
    const handlePwd = (e) => {
        e.preventDefault()
        toggle('par_u_pwd')
    }
    const handleDelete = (e) => {
        e.preventDefault();
        toggle('par_u_compte')
    }

    useEffect(() => {
        ApiDatabase.isDeleteAccount({token},(data) => {
            setIsDeleteAccount(!data.userMissionCheck)
        }, (err) => {
            console.log('err', err)
        })
    }, []);

    return (
        <>
            <div className={'flex  flex-col mb-6 mt-32 md:mt-0'}>
            <div className='col-span-full flex flex-col items-center justify-start gap-6 '>
                <div className='inline-flex items-center gap-6 w-[300px] cursor-pointer '
                     onClick={handlePwd}>
                    <div
                        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                        <Cadenas wh={18} color={'#111827'}/>
                    </div>
                    <p>Changer le mot de passe</p>
                </div>
                <div
                    className='inline-flex items-center justify-start gap-6 w-[300px] cursor-pointer'
                    onClick={handleEmail}>
                    <div
                        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                        <Mail wh={18} color={'#111827'} strokeW={2}/>
                    </div>

                    <p>Changer le mail</p>
                </div>
                {isDeleteAccount ? (
                    <div
                        className='inline-flex items-center justify-start gap-6 w-[300px] cursor-pointer'
                        onClick={handleDelete}>
                        <div
                            className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                            <TrashIcon wh={20} color={'#111827'}/>
                        </div>
                        <p>Supprimer le compte</p>
                    </div>
                ) : (
                    <div className='inline-flex items-center justify-start gap-6 w-[300px] cursor-pointer text-gray-500'>
                        <div className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                            <TrashIcon wh={20} color={'#6b7280'}/>
                        </div>
                        <p>Supprimer le compte</p>
                    </div>
                )}
                {/*<div*/}
                {/*    className='inline-flex items-center gap-6 justify-start w-[300px] cursor-pointer'*/}
                {/*    onClick={handleDelete}>*/}
                {/*    <div*/}
                {/*        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>*/}
                {/*        <Delete wh={18} color={'#111827'}/>*/}

                {/*    </div>*/}
                {/*    <p>Supprimer le compte</p>*/}
                {/*</div>*/}
            </div>
            </div>
        </>
    );
};

export default Parametre;