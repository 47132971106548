import React, {useEffect, useState} from 'react';
import Table from "components/table/table";
import EmptyState from "components/EmptyState/EmptyState";
import ApiDatabase from "server";
import MediaQuery from "react-responsive";
import Cardcontract from "components/card/cardcontract";
import UseContext from "hooks/useContext";
import useTitle from "../../../../hooks/TitleManager";


const Nosign = () => {
    useTitle({ title: "Contrats à signer" });
    const {refreshContract}=UseContext()
    const champsHeader=['N° de contrat','Entreprise','MISSION','N° De mission','ÉTAT','DATE']
    const [arrayContracts, setArrayContracts] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        const idU = localStorage.getItem('xsrfToken')
        ApiDatabase.getContracts({id: idU, pStatus: '0'},
            (data) => {
                setArrayContracts(data)
                setIsWaiting(false)
            },
            (err) => {
                console.log(err)
            })
    }, [])
    return (
        <>
            <MediaQuery minWidth={770}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <Table champsBody={i} champsHeader={champsHeader} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                arrayContracts.length ?
                                    <Table champsHeader={champsHeader} champsBody={arrayContracts}/>
                                    :
                                    <EmptyState type={'contract_nosign'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            <div className={'animate-pulse w-full'}>
                                {
                                    [1, 2, 3, 4, 5].map((i, index) => (
                                        <Cardcontract item={i} isWaiting={isWaiting}/>
                                    ))
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                arrayContracts.length ?
                                    arrayContracts?.map(item =>
                                        <Cardcontract champsBody={item}/>
                                    )
                                    :
                                    <EmptyState type={'contract_nosign'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
        </>
    );
};

export default Nosign;