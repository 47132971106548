import React from 'react';
import {MissionIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitMobile from "components/sidebar/components/SidebarUnitMobile";

const MissionML = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitMobile routeName={"Mes missions"} routeSidebar={"/mission/in_progress"}>
            <div className="flex flex-col justify-center items-center ">

            <span className='shrink-0'>
                            <MissionIcon wh={45} color={`${pathname.includes( '/mission') ? '#FF9600' : '#6B7280'}`}/>
            </span>
                <span
                    className={`text-[9px] font-medium whitespace-normal text-center ${
                        pathname.includes("mission") ? "text-yellow-500" : ''
                    }`}
                >
                    {language.getValue('sidebar.mission',' Mes missions')}
          </span>
            </div>
        </SidebarUnitMobile>
    );
};

export default MissionML;