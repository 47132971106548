import React,{useState} from 'react';
import {Import, Warning} from "assets/icons";
import Divider from "components/divider/divider";
import Banner from "components/banner/banner";
import Usefetchdata from "hooks/usefetchdata";


const Secondform = ({attest}) => {
    const {tempWorkerData}=Usefetchdata()
    const [titleFile,setTitleFile]=useState('')

    const handlerSingleFile =e=>{
        attest(e.target.files[0]);
        setTitleFile(e.target.files[0].name)
    }
    return (
        <>
            {tempWorkerData?.accommodation_of_address?.validated === '1' ?
                <Banner type={'warning'}>Un document est déjà en cours d’observation, la modification reste possible.</Banner>
                :''}
            <p className='ft-sm text-gray-500 my-3'>Critères de validation du document :</p>
            <span className='flex  gap-2 items-center'>
                <div className={'my-3'}>
                    <Warning wh={17} color={"#EF4444"}/>
                </div>
                Le document doit être de bonne qualité
            </span>
            <Divider w={100}/>
            <span className='flex  gap-2 items-center'>
                <div className={'my-3'}>
                    <Warning wh={17} color={"#EF4444"}/>
                </div>
                Les informations doivent être lisibles
            </span>
            <Banner type={'disabledInfo'}>
                <a href="https://www.service-public.fr/particuliers/vosdroits/F14807" target={"_blank"}>
                    Voir la liste des justificatifs acceptés
                </a>
            </Banner>
            <div className="flex flex-col w-full gap-2">
                <form id='uploadForm'>
                    <label
                        className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                        <div
                            className='ft-sm text-gray-700 w-1/4 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                            <Import color={'#374151'} wh={14}/>
                            Importer
                        </div>
                        <div
                            className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                            {titleFile ? titleFile : 'Aucun fichier'}
                        </div>

                        <input type="file" id='file'
                               accept='image/jpeg,image/jpg,application/pdf'
                               className='hidden'
                               onChange={(e) => handlerSingleFile(e)}/>
                    </label>

                    <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
                </form>
            </div>
        </>
    );
};

export default Secondform;