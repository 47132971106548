import {Add, ChevronDown, ChevronTop, Warning} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import useModal from "../modal/useModal";

const Accordion = ({type, title, additional, isWarning, children}) => {
    const [open, setOpen] = useState(false);
    const {toggle} = useModal()

    const handleExp = (e) => {
        e.preventDefault()
        toggle('sf_c_exp')
    }

    const handleFormation = (e) => {
        e.preventDefault()
        toggle('j_c_formation')
    }

    const handleLanguage = (e) => {
        e.preventDefault()
        toggle('sf_c_lang')
    }

    const handleLicence = e => {
        e.preventDefault()
        toggle('j_c_licence')
    }

    return (
        <>
            <div className={''}>
                <div className="px-5 rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                    <h2 className="mb-0">
                        <div className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 text-left box-shadow:inset_0_-1px_0_rgba(75,85,99)">
                            {title}
                            <div className={'ml-auto flex gap-2'}>
                                {isWarning && (
                                    <div className={'my-auto'}>
                                        <Warning wh={17} color={"#EF4444"}/>
                                    </div>
                                )}
                                {
                                    additional && (
                                        <div className={'my-auto ft-xs text-gray-400'}>
                                            {additional}
                                        </div>
                                    )
                                }
                                {
                                    type === 1 && (
                                        <button onClick={handleExp}
                                                className='rounded-full w-9 h-9 bg-blue-50 flex items-center justify-center'
                                        >
                                            <Add wh={18} color={'#3B82F6'}/>
                                        </button>
                                    )
                                }
                                {
                                    type === 2 && (
                                        <button
                                            onClick={handleLanguage}
                                            className='rounded-full w-9 h-9 bg-blue-50 flex items-center justify-center'
                                        >
                                            <Add wh={18} color={'#3B82F6'}/>
                                        </button>
                                    )
                                }
                                {
                                    type === 3 && (
                                        <button onClick={handleFormation}
                                                className='rounded-full w-9 h-9 bg-blue-50 flex items-center justify-center'
                                        >
                                            <Add wh={18} color={'#3B82F6'}/>
                                        </button>
                                    )
                                }
                                {
                                    type === 4 && (
                                        <button onClick={handleLicence}
                                                className='rounded-full w-9 h-9 bg-blue-50 flex items-center justify-center'
                                        >
                                            <Add wh={18} color={'#3B82F6'}/>
                                        </button>
                                    )
                                }
                                <button className={'my-auto border rounded w-8 h-8 flex justify-center items-center'}
                                        type={'button'}
                                        onClick={() => setOpen(!open)}
                                >
                                    <div className={`${open ? 'rotate-180' : ''}`}>
                                        <ChevronDown w={18} h={8} color={'#111827'}/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </h2>
                    {
                        open && (
                            <div className="border-0">
                                {children}
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default Accordion;