import React, { useState, useEffect } from "react";
import usefetchdata from "hooks/usefetchdata";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingButton from "components/button/loading.js";
import Modalheader from "components/modal/components/header.js";
import ModalBody from "components/modal/components/body.js";
import ModalFooter from "components/modal/components/footer.js";
import UseContext from "hooks/useContext";
import useModal from "components/modal/useModal.js";
import Button from "components/button/index.js";
import Selfie from "components/modal/dataModal/data/modaljustify/identity/components/body/selfie";
import Autorisationtravail from "components/modal/dataModal/data/modaljustify/identity/components/body/autorisationtravail";
import Indicatorupdate from "./components/body/update/indicatorupdate.js";
import Confirmationupdate from "./components/body/update/confirmationupdate.js";
import Footerbuttonupdate from "./components/body/update/footerbuttonupdate.js";
import PiUpdate from "./components/body/update/piupdate.js";
import CheckFilesIdentity from "./components/body/checkFilesIdentity";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
const Modalidentitymodify = () => {
    const { tempWorkerData } = usefetchdata();
    const { refreshLoadWorker, language, isWebcamToUse, setIsWebcamToUse } = UseContext();
    const [formIndex, setFormIndex] = useState(1);
    const [nationality, setNationality] = useState("");
    const [forceNationality, setForceNationality] = useState(false);
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detectCaptureWebcam, setDetectCaptureWebcam] = useState(false);
    const {height, width} = useWindowDimensions();
    const { toggle } = useModal();

    const [pi, setPi] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });
    const [selfie, setSelfie] = useState(null);
    const [visa, setVisa] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });

    const elements = [
        <Confirmationupdate
            key={Confirmationupdate}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
            forceNationality={forceNationality}
        />,
        <PiUpdate
            key={PiUpdate}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
            nationality={nationality}
            setForceNationality={setForceNationality}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[pi.fileR, pi.fileV]} />,
        <Selfie key={Selfie} setSelfie={setSelfie} setDetectCaptureWebcam={setDetectCaptureWebcam} />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[selfie]} />,
    ];
    const elements2 = [
        <Confirmationupdate
            key={Confirmationupdate}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
            forceNationality={forceNationality}
        />,
        <PiUpdate
            key={PiUpdate}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
            nationality={nationality}
            setForceNationality={setForceNationality}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[pi.fileR, pi.fileV]} />,
        <Selfie key={Selfie} setSelfie={setSelfie} setDetectCaptureWebcam={setDetectCaptureWebcam} />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[selfie]} />,
        <Autorisationtravail
            key={Autorisationtravail}
            setVisa={setVisa}
            visa={visa}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[visa.fileR, visa.fileV]} />,
    ];

    const effect1 = (f) =>
        f.identity?.validated === "1" && f.visa?.validated === "1";
    const effect2 = (f) => f.identity?.validated === "1";
    useEffect(() => {
        if (pi.nationality !== "" && pi.nationality !== nationality) {
            setNationality(pi.nationality);
        } else {
            if (effect2(tempWorkerData) || effect1(tempWorkerData)) {
                if (effect1(tempWorkerData)) {
                    setNationality("NUE");
                } else {
                    setNationality("UE");
                }
            } else {
                setFormIndex(1);
            }
        }
    }, [tempWorkerData, pi.nationality]);

    const m = async (files, successMsg) => {
        let isAllFilesSent = false;
        setLoading(true);
        const idToastLoad = toast.loading("Envoi des fichiers en cours");
        for (let i = 0; i < files.length; i++) {
            const result = await axios.post(files[i].url, files[i].file);
            if (result.status === 201) {
                isAllFilesSent = true;
            } else {
                isAllFilesSent = false;
                break;
            }
        }
        if (isAllFilesSent) {
            setLoading(false);
            setFormIndex(1);
            toast.update(idToastLoad, {
                render: "Fichiers uploadés",
                type: "success",
                autoClose: 3000,
                isLoading: false,
            });
            refreshLoadWorker();
            setForceNationality(nationality);
        } else {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Erreur d'envoi des fichiers",
                type: "error",
                autoClose: 3000,
                isLoading: false,
            });
        }
    };
    // * Section Modify File
    const modifyPIR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "Identité" est' + " ajouté.");
    };

    const modifyPIRV = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: forms[1],
            },
        ];
        await m(files, 'Le document "Identité" est' + " modifié.");
    };

    const modifySelfie = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "selfie" est' + " modifié.");
    };

    const modifyVisaR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[0],
            },
        ];
        await m(files, 'Le document "Identité" est' + " modifié.");
    };

    const modifyVisaRV = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: forms[1],
            },
        ];
        await m(files, 'Le document "Identité" est modifié.');
    };
    // *Section onClick modify
    const handleModifyPI = async (e) => {
        e.preventDefault();
        if (
            (check && pi.type === "Passeport" && pi.fileR !== null) ||
            (check &&
                pi.type === "CNI" &&
                pi.fileR !== null &&
                pi.fileV !== null)
        ) {
            const token = localStorage.getItem("xsrfToken");
            const first = new FormData();
            const second = new FormData();
            first.append("file", pi.fileR);
            first.append("identityType", pi.type);
            first.append("nationality", nationality);
            second.append("nationality", nationality);

            const docs = [first, second];
            docs.map((f) => f.append("token", token));

            if (pi.type === "Passeport") {
                await modifyPIR(first);
            } else if (pi.type === "CNI") {
                second.append("file", pi.fileV);
                await modifyPIRV(first, second);
            }
        } else {
            toast.warning("Veuillez remplir tous les champs");
        }
    };

    const handleModifySelfie = async (e) => {
        e.preventDefault();
        if (selfie !== null) {
            const token = localStorage.getItem("xsrfToken");
            const first = new FormData();
            first.append("token", token);
            first.append("file", selfie);
            await modifySelfie(first);
        } else {
            toast.warning("Veuillez importer un fichier");
        }
    };
    const handleModifyVisa = async (e) => {
        e.preventDefault();
        if (
            (visa.type !== "" && visa.type === "RTS" && visa.fileR !== null) ||
            (visa.type !== "" &&
                visa.type === "Visa" &&
                visa.fileR !== null &&
                visa.fileV !== null)
        ) {
            const token = localStorage.getItem("xsrfToken");
            const first = new FormData();
            const second = new FormData();
            const docs = [first, second];
            docs.map((f) => f.append("token", token));
            first.append("visaType", visa.type);
            first.append("file", visa.fileR);
            if (visa.type === "RTS") {
                await modifyVisaR(first);
            } else {
                second.append("file", visa.fileV);
                await modifyVisaRV(first, second);
            }
        } else {
            toast.warning("Veuillez remplir tous les champs");
        }
    };

    const state1 = (f) =>
        f.type !== "" && (f.nationality !== "" || forceNationality) && f.fileR !== "" && f.fileR !== null;
    const state2 = (f) => f;
    const state3 = (f) =>
        f.type !== "" &&
        f.fileR !== "" &&
        ((f.type === "Visa" && f.fileV !== "") || f.type === "RTS");

    const handleIndex = (e) => {
        if (formIndex < 1) setFormIndex(1);

        if (
            (formIndex === 2 && state1(pi) && check) ||
            (formIndex === 4 && state2(selfie)) ||
            (formIndex === 6 && state3(visa))
        ) {
            setFormIndex(formIndex + 1);
        } else {
            if (formIndex === 2 && !check) toast.warning("Veuillez cocher l'attestation sur l'honneur");
            else toast.warning("Veuillez remplir les champs");
        }
    };

    useEffect(() => {
        if(detectCaptureWebcam){
            handleIndex()
            setDetectCaptureWebcam(false)
        }
    }, [detectCaptureWebcam]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue("justify.m_identity_title_c")}
            </Modalheader>
            <ModalBody>
                <Indicatorupdate
                    pi={pi}
                    nationality={nationality}
                    formIndex={formIndex}
                />
                {(pi.nationality === "NUE" || nationality === "NUE"
                    ? elements2
                    : elements
                ).filter((item, index) => index + 1 === formIndex)}
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        <div className={'w-full'}>
                            {(formIndex === 4 && width <= 605) && (
                                <div
                                    className="text-yellow-500 underline cursor-pointer mr-auto mb-2"
                                    onClick={() => {
                                        setIsWebcamToUse(!isWebcamToUse);
                                    }}
                                >
                                    {isWebcamToUse ? 'Importer un fichier' : 'Prendre une photo'}
                                </div>
                            )}
                            <div className={'flex items-center justify-end rounded-b gap-4 w-full'}>
                                {formIndex === 1 ? null : (
                                    <>
                                        {(formIndex === 4 && width > 605) && (
                                            <div
                                                className="text-yellow-500 underline cursor-pointer mr-auto"
                                                onClick={() => {
                                                    setIsWebcamToUse(!isWebcamToUse);
                                                }}
                                            >
                                                {isWebcamToUse ? 'Importer un fichier' : 'Prendre une photo'}
                                            </div>
                                        )}
                                        <Button
                                            size={"LG"}
                                            color={"SECONDARY"}
                                            onClick={() => {
                                                setFormIndex(1);
                                            }}
                                        >
                                            Annuler la modification
                                        </Button>
                                    </>
                                )}
                                <Footerbuttonupdate
                                    formIndex={formIndex}
                                    pi={pi.nationality}
                                    nationality={nationality}
                                    oC1={toggle}
                                    oC2={handleModifyPI}
                                    oC3={handleModifySelfie}
                                    oC4={handleModifyVisa}
                                    oC5={handleIndex}
                                />
                            </div>
                        </div>
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default Modalidentitymodify;
