import {
    ContractDisabledIcon, ContractIcon,
    PayslipDisabledIcon,
    PayslipIcon,
    TimeSheetDisabledIcon,
    TimeSheetIcon
} from "assets/icons";
import Divider from "components/divider/divider";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import usefetchdata from "hooks/usefetchdata";
import ApiDatabase from "../../server";
import ApiDataBase from "../../server";

const ProfileDocuments = () => {
    const {userData} = usefetchdata();
    const {tempWorkerData} = usefetchdata()

    const [nbContract, setNbContract] = useState(0);
    const [nbPayslip, setNbPayslip] = useState(0);
    const [nbTimesheet, setNbTimesheet] = useState(0);
    const token = localStorage.getItem('xsrfToken')

    const documentsToComplete = [
        {
            text: 'Contrat à signer',
            textPlural: 'Contrats à signer',
            number: nbContract,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/documents/contracts/nosign',
            icon: <ContractIcon wh={64}/>,
            iconDisabled: <ContractDisabledIcon wh={64}/>,
        },
        {
            text: 'Nouvelle fiche de paie',
            textPlural: 'Nouvelles fiches de paie',
            number: nbPayslip,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/documents/payslip',
            icon: <PayslipIcon wh={64}/>,
            iconDisabled: <PayslipDisabledIcon wh={64}/>,
        },
        {
            text: 'Relevé d\'heure à compléter',
            textPlural: 'Relevés d\'heures à compléter',
            number: nbTimesheet,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/documents/hours/nocompleted',
            icon: <TimeSheetIcon wh={64}/>,
            iconDisabled: <TimeSheetDisabledIcon wh={64}/>,
        },
    ];

    useEffect(() => {
        let nbPayslipRead = 0;
        if (tempWorkerData?.payslip) {
            tempWorkerData.payslip.forEach(payslip => {
                if (!payslip.is_read) {
                    nbPayslipRead++;
                }
            });
        }
        setNbPayslip(nbPayslipRead);

        ApiDatabase.getContracts({id: token, pStatus: 0},
            (data) => {
                setNbContract(data.length ? data.length : 0);
            },
            (err) => {
                console.log(err)
            })
        ApiDataBase.getTimeSheets({token:token},
            (data)=>{

                let tampon = []
                for (let m in data) {
                    if (data[m].validated === 0 && new Date(data[m].periode_start) <= new Date()) {
                        tampon.push(data[m])
                    }
                }

                setNbTimesheet(tampon.length);
            },
            (err)=>{
                console.log(err)
            })
    }, [tempWorkerData]);

    return (
        <>
            <div className={'lg:flex lg:justify-between gap-4'}>
                {
                    documentsToComplete.map((document, key) => {
                        return (
                            <NavLink to={document.link} key={key} className={'flex justify-between grow px-5 py-4'}>
                                <div className={`my-auto ${document.number > 0 ? document.textColor : document.textColorDisabled}`}>
                                    <div className={'text-3xl font-bold'}>{document.number}</div>
                                    <div className={'text-xs'}>{document.number > 1 ? document.textPlural : document.text}</div>
                                </div>
                                <div className={'my-auto ml-3'}>
                                    {document.number > 0 ? document.icon : document.iconDisabled}
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
            <div className={'my-4'}>
                <Divider/>
            </div>
        </>
    )
}

export default ProfileDocuments;