import {dateWordShort} from "../../../helper/helper";
import {DocumentIcon} from "../../../assets/icons";
import React from "react";
import ApiDatabase from "server";
import {toast} from "react-toastify";

const tbodyPayslip = ({champsBody}) => {
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const token = localStorage.getItem('xsrfToken');

    const openPayslip = (id, url) => {
        window.open(url, '_blank');

        ApiDatabase.putReadPayslip({token, idPayslip: id}, (data) => {}, (err) => {})
    }

    return (
        <>
            {
                champsBody.map(item => {
                    //format amount to add 2 digits after comma if it doesn't exist
                    let amount = item.amount;
                    if (amount) {
                        amount = amount.toString();
                        if (amount.indexOf('.') === -1) {
                            amount += '.00';
                        } else {
                            const amountSplit = amount.split('.');
                            if (amountSplit[1].length === 1) {
                                amount += '0';
                            }
                        }
                    }

                    //format amount to add space every 3 digits
                    amount = amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null;

                    return (
                        <tr className={'border-b border-gray-300'}>
                            <td className={'py-5 uppercase text-left'}>{monthNames[new Date(item.year, item.month, 0).getMonth()]}</td>
                            <td className={'py-5 text-left'}>{amount ? `${amount} €` : ''}</td>
                            <td className={'py-5 text-left'}>{dateWordShort(item.createdAt)}</td>
                            <td className={'py-5 flex items-center justify-center'}>
                                <div className={'cursor-pointer'} onClick={() => openPayslip(item._id, `${process.env.REACT_APP_INTERIM_PDF}${item.filePath}`)}>
                                    <DocumentIcon wh={35} color={'#111827'}/>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export default tbodyPayslip;