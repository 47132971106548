import React from 'react';
import {ProfilIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";

const ProfilDl = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitDesktop routeName={"Mon Profil"} routeSidebar={"/profil/compte"}>
            <span className='shrink-0'>
            <ProfilIcon wh={40} color={`${pathname.includes("profil") || pathname.includes("cv/details") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("profil") || pathname.includes("cv/details") ? "text-yellow-500" : ''
                }`}
            >
          {language.getValue('sidebar.profil','Mon profil')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default ProfilDl;