import UseContext from "../../../../../../hooks/useContext";
import useModal from "../../../../useModal";
import ApiDatabase from "../../../../../../server";
import { toast } from "react-toastify";
import Modalheader from "../../../../components/header";
import ModalBody from "../../../../components/body";
import { Warning } from "../../../../../../assets/icons";
import Divider from "../../../../../divider/divider";
import ModalFooter from "../../../../components/footer";
import Button from "../../../../../button";
import { useEffect, useState } from "react";
import LoadingButton from "components/button/loading.js";

const ModalUpdateFormation = () => {
    const { refreshLoadWorker, objID } = UseContext();
    const { toggle } = useModal();
    const [typeFormation, setTypeFormation] = useState("");
    const [description, setDescription] = useState("");
    const token = localStorage.getItem("xsrfToken");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ApiDatabase.getFormationById(
            {
                token: token,
                formationID: objID,
            },
            (data) => {
                setTypeFormation(data.name);
                setDescription(data.description);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [objID]);

    const handleSubmit = async () => {
        if (typeFormation && description) {
            setLoading(true);
            ApiDatabase.putUpdateFormation(
                {
                    token: token,
                    formationID: objID,
                    formation: typeFormation,
                    description: description,
                },
                (data) => {
                    setLoading(false);
                    //console.log(data)
                    toast.success("Formation modifiée avec succès");
                    toggle();
                    refreshLoadWorker();
                },
                (err) => {
                    setLoading(false);
                    console.log(err);
                    toast.error("Une erreur est survenue");
                }
            );
        } else {
            setLoading(false);
            toast.error("Veuillez remplir tous les champs");
        }
    };

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Modifier une formation
            </Modalheader>
            <ModalBody>
                <div className="flex flex-col w-full gap-2">
                    <select
                        name=""
                        id=""
                        onChange={(e) => setTypeFormation(e.target.value)}
                        className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                        value={typeFormation}
                    >
                        <option value="">Sélectionner</option>
                        <option value="CAP ou équivalents">
                            CAP ou équivalents
                        </option>
                        <option value="BEP ou équivalents">
                            BEP ou équivalents
                        </option>
                        <option value="Bac (général, technique ou professionnel) ou équivalents">
                            Bac (général, technique ou professionnel) ou
                            équivalents
                        </option>
                        <option value="Bac+2 (BTS, DUT ou équivalents)">
                            Bac+2 (BTS, DUT ou équivalents)
                        </option>
                        <option value="Bac+3 (Licence ou équivalents)">
                            Bac+3 (Licence ou équivalents)
                        </option>
                        <option value="Bac+4 (Maitrise ou équivalents)">
                            Bac+4 (Maitrise ou équivalents)
                        </option>
                        <option value="Bac+5 et plus (Master ou équivalents)">
                            Bac+5 et plus (Master ou équivalents)
                        </option>
                    </select>
                </div>
                <div className={"w-full mt-3"}>
                    <label className="ft-sm text-gray-500">
                        <div>Description</div>
                        <div>
                            <input
                                type="text"
                                className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </label>
                </div>
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        <Button
                            size={"LG"}
                            color={"SECONDARY"}
                            onClick={toggle}
                        >
                            Annuler
                        </Button>
                        <Button size={"LG"} onClick={handleSubmit}>
                            Confirmer
                        </Button>
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default ModalUpdateFormation;
