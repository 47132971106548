import React from 'react';
import Chip from "components/chip/chip";
import {useNavigate} from "react-router-dom";
import {dateWordShort, numberFormat} from "helper/helper";
import Divider from "components/divider/divider";

const Cardhour = ({item, isWaiting}) => {
    const navigate = useNavigate()

    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={'flex flex-col gap-4 px-4 py-5 animate-fadeIn animate-pulse'}>
                            <p className={'h-2 bg-gray-200 rounded w-2/4'}></p>
                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                            <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                        </div>
                        <Divider w={100}/>
                    </>
                ) : (
                    <>
                        <div className={'flex justify-between gap-2 px-5 py-4 animate-fadeIn'}
                             onClick={(e) => {
                                 navigate(`../../ficheHour/${item._id}`)
                             }}>
                            <div className={'flex-col'}>
                                <p>{item.infoMission[0].title}</p>
                                <div className={'ft-sm text-gray-700'}>
                                    {item.nameCompany} · {dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}
                                </div>
                                {
                                    item.validated > 0 && (
                                        <div className={'ft-sm text-gray-500'}>
                                            <span className={'border-dotted border-b-2'}>
                                                {numberFormat(item.estimation_hours_time_sheet) + ' €'}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={'flex-col'}>
                                <div className={'ft-sm'}>
                                    <Chip type={'INDICATOR'} color={item.validated === 0 ? 'ALERT' : 'INFO'}>N° {item.id_time_sheet}</Chip>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default Cardhour;