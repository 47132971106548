import React from 'react';
import {Add} from "assets/icons";
import Chip from "components/chip/chip";
import usefetchdata from "hooks/usefetchdata";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import CardProfile from "components/card/cardProfile/cardProfile";
import Divider from "components/divider/divider";
import useTitle from "../../../hooks/TitleManager";


const Langues = () => {
    useTitle({ title: "Profil - Mon savoir faire - Langues" });
    const {tempWorkerData}=usefetchdata()
    const {setObjID}=UseContext()
    const {toggle}=useModal()


    const handleDeletedLanguage = (e,item) =>{
        e.preventDefault()
        setObjID(item)
        toggle('sf_d_lang')
    }
    const handleLanguage = (e) => {
        e.preventDefault()
        toggle('sf_c_lang')
    }
    return (
        <>
            <CardProfile>
                <div className="grid grid-cols-4 flex items-center">
                    <div className="col-span-3 ft-b text-gray-900">Langues</div>
                    <div className='col-span-1 flex justify-end'>
                        <button
                            onClick={handleLanguage}
                            className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'>
                            <Add wh={18} color={'#3B82F6'}/>
                        </button>
                    </div>
                </div>
                <div className={'my-5'}>
                    <Divider w={95}/>
                </div>
                <div className='flex flex-wrap gap-2 items-center justify-start my-4'>
                    {tempWorkerData.language?.map((item, index) =>
                        <Chip key={index} onClick={e=>{handleDeletedLanguage(e,item)}} icon={true} select={true} type={'SELECTON'}
                        >{item}</Chip>
                    )}
                </div>
            </CardProfile>
        </>
    );
};

export default Langues;