import {useLocation} from "react-router-dom";
import {ContextProvider} from "context/Context";
import Modal from 'components/modal/portal'
import Routes from "routes";
import {useEffect} from "react";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation();
  const tokenURL=location.search.split('=')[1]

    if(process.env.REACT_APP_IS_PROD === 'true') {
        useEffect(() => {
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
            ReactGA.send('pageview');
        }, []);
    }

  return (
      <ContextProvider>
        <Modal/>
        <Routes/>
      </ContextProvider>
  );
}

export default App;
