import React from 'react';
import selfieImageCNI from 'assets/images/selfie_cni.png'
import selfieImagePasseport from 'assets/images/selfie_passeport.png'

const Selfieimage = () => {
    return (
        <>
            <div className={'flex flex-col items-center' +
                ' justify-center ' +
                ' gap-2' +
                ' tab:flex-row' +
                ' justify-around my-3'}>
                <img src={selfieImagePasseport} alt="selfie avec le passeport"
                     className='object-cover object-center w-80 h-40 drop-shadow-sm rounded-lg'/>
                <img src={selfieImageCNI} alt="selfie avec la carte d'identité"
                     className='object-cover object-center w-80 h-40 drop-shadow-sm rounded-lg'/>
            </div>
        </>
    );
};

export default Selfieimage;