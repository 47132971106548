import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import usefetchdata from "hooks/usefetchdata";
import {dateExp, dateToEpoch, dateWordShort, statutMission} from "helper/helper";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import {
    ArrowLeft, Camion,
    ChevronDown,
    ChevronTop,
    Drapeau, Localisation,
    Warning
} from "assets/icons";
import Button from "components/button";
import Cardheader from "components/card/components/cardheader";
import AvatarCompagny from "components/avatar/avatarcompany";
import Cardbody from "components/card/components/cardbody";
import Divider from "components/divider/divider";
import Cardfooter from "components/card/components/cardfooter";
import PageForm from "components/pageForm/PageForm";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import MediaQuery from "react-responsive";
import useModal from "../../../components/modal/useModal";
import useContext from "../../../hooks/useContext";
import ScheduleWeekListMission from "../../../components/ScheduleWeekList/ScheduleWeekListMission";
import NotFound from "../../../components/notFound/notFound";
import useTitle from "../../../hooks/TitleManager";
import ReactGA from "react-ga4";

const MissionfileSearch = () => {
    useTitle({ title: "Recherche de missions - Détail" });
    const navigate = useNavigate()
    const {userData, tempWorkerData} = usefetchdata()
    const {addAvailability, setAddAvailability, setIsPostulateWithoutAvailability, setIdMission} = useContext()
    const {toggle} = useModal()
    const [infoMission, setInfoMission] = useState({})
    const [candidate, setCandidate] = useState(false)
    const [deleteCandidate, setDeleteCandidate] = useState('')
    const [showTools, setShowTools] = useState(false)
    const [isWaiting, setIsWaiting] = useState(true);
    const [visibilityTools, setVisibilityTools] = useState(3)
    const [isUserInvited, setIsUserInvited] = useState(false)
    const [scheduleWeeks, setScheduleWeeks] = useState([])
    const [scheduleWeeksProv, setScheduleWeeksProv] = useState([])
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0)
    const {height, width} = useWindowDimensions();
    const {id} = useParams()
    const route = useLocation()
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if(token && id){
            ApiDatabase.getInfoSearchMission({token, idMission: id},
                (data) => {
                    setInfoMission(data ?? {})
                    setIsWaiting(false)
                },
                (err) => {
                    console.error(err)
                })
        }
    }, [id, candidate])

    useEffect(() => {
        ApiDatabase.postVerifyUser({mission: id, token: token},
            (data) => {
                if (data.id) {
                    const idUser = infoMission.users?.find(item => item.user_id === data.id)
                    const result = !!idUser
                    result && setDeleteCandidate(idUser?._id)
                    setCandidate(result)
                }
            },
            (err) => {
                console.error(err)
            })
    }, [infoMission])

    useEffect(() => {
        if (userData._id) {
            if(infoMission?.users_invite) {
                for (let i = 0; i < infoMission?.users_invite?.length; i++) {
                    if(infoMission?.users_invite[i]?.user_id.toString() === userData._id.toString() && infoMission?.users_invite[i]?.status === 'pending') {
                        setIsUserInvited(true)
                    }
                }
            }
        }
    }, [infoMission, userData]);

    const handleShowExp = () => {
        setShowTools(!showTools)
        if (showTools) {
            setVisibilityTools((prevValue) => prevValue - 3)
        } else {
            setVisibilityTools((prevValue) => prevValue + 3)
        }
    }

    useEffect(() => {
        if(addAvailability){
            !candidate && ApiDatabase.postCandidate({mission: id, token: token},
                (data) => {
                    setCandidate(true)
                    toast.success(`Votre candidature a bien été prise en compte`)
                    setAddAvailability(false)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [addAvailability]);

    const handleSubmit = (e) => {
        e.preventDefault()
        const availableDateStart = tempWorkerData?.availability?.date_start ? dateToEpoch(tempWorkerData?.availability?.date_start) : false
        const availableDateEnd = tempWorkerData?.availability?.date_end ? dateToEpoch(tempWorkerData?.availability?.date_end) : false
        const missionDateStart = dateToEpoch(infoMission?.duration?.datestart)
        const missionDateEnd = dateToEpoch(infoMission?.duration?.dateend)

        let isUserCanPostulate = true
        if(availableDateStart || availableDateEnd) {
            let isUserCanPostulateDateStart = false
            let isUserCanPostulateDateEnd = false
            if(availableDateStart instanceof Date) {
                isUserCanPostulateDateStart = true
                if(availableDateStart.getTime() < missionDateStart.getTime() || availableDateStart.getTime() > missionDateEnd.getTime()) {
                    isUserCanPostulateDateStart = false
                }
            }
            if(availableDateEnd instanceof Date) {
                isUserCanPostulateDateEnd = true
                if(availableDateEnd.getTime() > missionDateEnd.getTime() || availableDateEnd.getTime() < missionDateStart.getTime()) {
                    isUserCanPostulateDateEnd = false
                }
            }

            isUserCanPostulate = isUserCanPostulateDateStart || isUserCanPostulateDateEnd
        } else {
            isUserCanPostulate = false

            if(tempWorkerData?.availability?.is_available === true) {
                isUserCanPostulate = true
            }
        }

        if(!isUserCanPostulate) {
            ReactGA.event({
                category: 'Postuler à une mission - Modifier disponibilités',
                action: 'postulate_mission',
                label: `${isUserInvited ? 'Mission' : 'Invitation mission'} ${infoMission?.title} n° ${infoMission?.id_mission}`
            });

            toggle('disponibility')
            setIsPostulateWithoutAvailability({_id: id, id_mission: infoMission?.id_mission, title: infoMission?.title, isUserInvited})
        }
        else {
            !candidate && ApiDatabase.postCandidate({mission: id, token: token},
                (data) => {
                    ReactGA.event({
                        category: 'Postuler à une mission',
                        action: 'postulate_mission',
                        label: `${isUserInvited ? 'Mission' : 'Invitation mission'} ${infoMission?.title} n° ${infoMission?.id_mission}`
                    });
                    setCandidate(true)
                    toast.success(`Votre candidature a bien été prise en compte`)
                },
                (err) => {
                    console.log(err)
                })
        }
    }

    const handleSubmitDelete = e => {

        e.preventDefault()
        candidate && ApiDatabase.deleteUserMission({
                mission: id,
                missionUser: deleteCandidate
            },
            (data) => {
                setCandidate(false)
                // setCandidate(false)
                toast.success(`Votre candidature a bien été supprimée`)
            },
            (err) => {
                console.error(err)
            })
    }

    const handleInviteAccept = e => {
        e.preventDefault()
        setIdMission({_id: id, id_mission: infoMission?.id_mission, title: infoMission?.title, isUserInvited})
        toggle('mission_accept_invite')
    }

    const handleInviteRefuse = e => {
        e.preventDefault()
        setIdMission({_id: id, id_mission: infoMission?.id_mission, title: infoMission?.title, isUserInvited})
        toggle('mission_refuse_invite')
    }

    useEffect(() => {
        ApiDatabase.getScheduleByMission({id: id},
            (data) => {
                setScheduleWeeksProv(data)
            },
            (err) => {
                console.log(err)
            })
    }, [infoMission])

    useEffect(() => {
        if (scheduleWeeksProv.length > 0) {
            scheduleWeeksProv.sort((a, b) => a.week_number - b.week_number)

            let isAWeekChosen = false;
            for (let i = 0; i < scheduleWeeksProv.length; i++) {
                scheduleWeeksProv[i].days.sort((a, b) => a.date - b.date)

                const number_week = ('0' + (parseInt(scheduleWeeksProv[i].week_number) + 1)).slice(-2)
                scheduleWeeksProv[i].name = `Semaine ${number_week}`
                scheduleWeeksProv[i].isNext = !!scheduleWeeksProv[i + 1];
                scheduleWeeksProv[i].isPrev = !!scheduleWeeksProv[i - 1];

                let isHidden = true;
                for (let j = 0; j < scheduleWeeksProv[i].days.length; j++) {
                    const scheduleDate = dateToEpoch(scheduleWeeksProv[i].days[j].date)
                    const today = dateToEpoch(new Date())

                    if (scheduleDate === today) {
                        setScheduleWeekDisplay(i)
                        isHidden = false;
                        isAWeekChosen = true;
                    }
                }
                scheduleWeeksProv[i].isHidden = isHidden;
                scheduleWeeksProv[i].isActualWeek = !isHidden;
            }
            if (!isAWeekChosen) {
                scheduleWeeksProv[0].isHidden = false;
            }

            setScheduleWeeks(scheduleWeeksProv);
        }
    }, [scheduleWeeksProv])

    return (
        <PageForm>
            {
                isWaiting ? (
                    <>
                        <div className={'animate-fadeIn animate-pulse'}>
                            <div className="col-span-12 bg-white sticky z-10 mt-lg-10">
                                <div className='flex items-center justify-between h-14 px-4'>
                                    <div className='flex items-center gap-2 cursor-pointer' onClick={e => navigate(-1)}>
                                        <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                    </div>
                                    <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                </div>
                            </div>
                            <div className="flex justify-center col-span-full md:col-start-3 md:col-end-11">
                                <div className="block rounded-lg shadow-lg bg-white  w-full lg:w-[60%]  text-center mb-36 tab:mb-0 ">
                                    <Cardheader>
                                        <div className={`flex gap-3 ${width <= 769 ? 'flex-col' : ''}`}>
                                            <div className="col-span-1 flex items-center justify-between w-[64px] h-[64px]">
                                                <div className={'h-full w-full bg-gray-200 rounded w-full'}></div>
                                            </div>
                                            <div className="col-span-2  order-last tab:order-none w-full">
                                                <div className="grid grid-col-3 text-left">
                                                    <div className="col-span-1 ft-sm text-gray-500 mb-1">
                                                        <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-2xl font-medium text-gray-700 mb-1">
                                                        <div className={'h-4 bg-gray-200 rounded w-2/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-xs text-gray-500">
                                                        <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Cardheader>

                                    <Cardbody>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-1 tab:grid-cols-2 text-left ">
                                            <div className="col-span-1 tab:col-span-2 ">
                                                <h5 className="text-gray-900 ft-b font-medium mb-2">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4 mb-1'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 ">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
                                            <div className="col-span-1 tab:col-span-3">
                                                <h5 className="ft-xl text-gray-700">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                            <div className="col-span-2 ">
                                                <h5 className="ft-xl text-gray-900">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="ft-xl text-gray-900 ml-2 mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2 mb-1">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 flex justify-end px-5 py-8items-start">
                                                <div className={'h-5 bg-gray-200 rounded w-2/4'}></div>
                                            </div>
                                        </div>
                                    </Cardbody>
                                    <Cardfooter>
                                        <div className="ft-sm text-center flex justify-end text-gray-500">
                                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                        </div>
                                    </Cardfooter>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {infoMission ? (
                            <div className={'animate-fadeIn'}>
                                <div className="col-span-12 bg-white sticky z-10">
                                    <div className='flex items-center justify-between h-14 px-4'>
                                        <div className='flex items-center gap-2 cursor-pointer' onClick={e => navigate(-1)}>
                                            <ArrowLeft w={15} h={12} color={'#111827'}/>
                                            {infoMission?.title}
                                        </div>
                                        <div className='flex items-center justify-center gap-4'>
                                            {candidate ?
                                                <Button size={'SM'} color={'SECONDARY'}
                                                        onClick={handleSubmitDelete}>
                                                    Annuler la candidature
                                                </Button>
                                                : (
                                                    <>
                                                        {isUserInvited ? (
                                                            <>
                                                                <Button size={'SM'}
                                                                        color={'SECONDARY'}
                                                                        onClick={handleInviteRefuse}
                                                                >
                                                                    Refuser
                                                                </Button>
                                                                <Button size={'SM'}
                                                                        color={'PRIMARY'}
                                                                        onClick={handleInviteAccept}
                                                                >
                                                                    Accepter
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button size={'SM'}
                                                                    onClick={handleSubmit}>
                                                                Postuler
                                                            </Button>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center col-span-full md:col-start-3 md:col-end-11">
                                    <div className="block rounded-lg shadow-lg bg-white  w-full lg:w-[60%]  text-center mb-36 tab:mb-0 ">
                                        {isUserInvited && (
                                            <div className={'bg-yellow-500 w-full py-3 rounded-t-lg text-white text-sm'}>
                                                ⏰ {infoMission.infoCompany?.reason_social} vous a invité à participer à cette mission
                                            </div>
                                        )}
                                        <Cardheader>
                                            <div className={`flex gap-3 ${width <= 769 ? 'flex-col' : ''}`}>
                                                <div className="col-span-1 flex items-center justify-between ">
                                                    <AvatarCompagny size={"LG"}/>
                                                </div>
                                                <div className="col-span-2  order-last tab:order-none ">
                                                    <div className="grid grid-col-3 text-left">
                                                        <div className="col-span-1 ft-sm text-gray-500">
                                                            {dateExp(infoMission?.duration?.datestart)} - {dateExp(infoMission?.duration?.dateend)}
                                                        </div>
                                                        <div className="col-span-1 ft-2xl font-medium text-gray-700 ">
                                                            {infoMission?.title} - n°{infoMission?.id_mission}
                                                        </div>
                                                        <div className="col-span-1 ft-xs text-gray-500">
                                                            {infoMission?.infoCompany?.name_commercial} · {infoMission?.activity} ·
                                                            {infoMission?.address?.city} , {infoMission?.address?.department}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Cardheader>

                                        <Cardbody>
                                            <div className={'my-5'}>
                                                <Divider w={100}/>
                                            </div>
                                            <div className="grid grid-cols-1 tab:grid-cols-2 text-left">
                                                <div className="col-span-1 tab:col-span-2">
                                                    <h5 className="text-gray-900 ft-b font-medium">
                                                        Description
                                                    </h5>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4">
                                                        <div dangerouslySetInnerHTML={{ __html: infoMission?.desc ?? '' }}></div>
                                                    </p>
                                                </div>
                                                <div className="col-span-1">
                                                    <h6 className="text-gray-900 ft-b font-medium">
                                                        Période
                                                    </h6>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4">
                                                        {new Date(infoMission?.duration?.datestart).toLocaleDateString('FR-fr', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            timeZone: "Europe/Paris"
                                                        })} - {new Date(infoMission?.duration?.dateend).toLocaleDateString('FR-fr', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                        timeZone: "Europe/Paris"
                                                    })}
                                                    </p>
                                                </div>
                                                <div className="col-span-1">
                                                    <h6 className="text-gray-900 ft-b font-medium ">
                                                        Salaire horaire brut
                                                    </h6>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                        {infoMission?.salary?.wage_per_hour !== 0 ? infoMission?.salary?.wage_per_hour + ' €/h' : 'Selon expérience'}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                scheduleWeeks.length > 0 ? (
                                                    <>
                                                        <div className={'my-5'}>
                                                            <Divider w={100}/>
                                                        </div>
                                                        <div className={'text-left'}>
                                                            {
                                                                scheduleWeeks.map((scheduleWeek, index) => {
                                                                    if (scheduleWeek.isNext !== undefined && scheduleWeek.isPrev !== undefined && scheduleWeek.isHidden !== undefined && scheduleWeek.isActualWeek !== undefined) {
                                                                        return (
                                                                            <ScheduleWeekListMission
                                                                                key={index}
                                                                                indexScheduleWeek={index}
                                                                                scheduleWeek={scheduleWeek}
                                                                                isNext={scheduleWeek.isNext}
                                                                                isPrev={scheduleWeek.isPrev}
                                                                                isHidden={scheduleWeekDisplay !== index}
                                                                                isActualWeek={scheduleWeek.isActualWeek}
                                                                                setScheduleWeekDisplay={setScheduleWeekDisplay}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                            <div className={'my-5'}>
                                                <Divider w={100}/>
                                            </div>
                                            {(infoMission?.language?.length > 0 || infoMission?.tools?.length > 0 || infoMission?.licences?.length > 0) && (
                                                <>
                                                    <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
                                                        <div className="col-span-1 tab:col-span-3">
                                                            <h5 className="ft-xl text-gray-700">Pré-requis</h5>
                                                        </div>
                                                        {infoMission?.language?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Drapeau wh={18} color={'#111827'}/>
                                                                    <h6 className="ft-xl text-gray-900 ml-2">Langues</h6>
                                                                </div>
                                                                <div className="grid grid-cols-1">
                                                                    {infoMission?.language ?
                                                                        infoMission?.language.map((item, index) =>
                                                                            <div key={index} className="col-span-1 ft-sm text-gray-800">
                                                                                {item}
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {infoMission?.tools?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Warning wh={18} color={'#111827'}/>
                                                                    <h6 className="text-xl text-gray-900 ml-2">Matériels</h6>
                                                                </div>
                                                                <div className="grid grid-cols-1 flex gap-3">
                                                                    {
                                                                        infoMission?.tools && Array.isArray(infoMission?.tools) ?
                                                                            infoMission?.tools.slice(0, visibilityTools).map((item, index) =>
                                                                                <div key={index} className="col-span-1 ft-sm text-gray-800">
                                                                                    {item}
                                                                                </div>
                                                                            ) : ''

                                                                    }
                                                                    {infoMission?.tools.length < 3 ?
                                                                        '' :
                                                                        <div
                                                                            className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3"
                                                                            onClick={handleShowExp}>
                                                                            {showTools ?
                                                                                <ChevronTop w={18} h={8}
                                                                                            color={'#FF9600'}/> :
                                                                                <ChevronDown w={18} h={8}
                                                                                             color={'#FF9600'}/>}
                                                                            En
                                                                            savoir {showTools ? ' moins' : ' plus'}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {infoMission?.licences?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Camion w={18} h={14} color={'#111827'}/>
                                                                    <h6 className="ft-xl text-gray-900 ml-2">
                                                                        Permis
                                                                    </h6>
                                                                </div>
                                                                <div className="grid grid-cols-1">
                                                                    {infoMission?.licences ?
                                                                        infoMission?.licences.map((item, index) =>
                                                                            <div key={index} className="col-span-1 ft-sm text-gray-800">
                                                                                {item}
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={'my-5'}>
                                                        <Divider w={100}/>
                                                    </div>
                                                </>
                                            )}
                                            <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                                <div className="col-span-2 ">
                                                    <h5 className="ft-xl text-gray-900">
                                                        Lieu de travail
                                                    </h5>
                                                </div>
                                                <div className="col-span-1">
                                                    <h6 className="ft-xl text-gray-900 ml-2">
                                                        {infoMission?.infoCompany?.name_commercial}
                                                    </h6>
                                                    <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                        {infoMission?.address?.street}
                                                    </p>
                                                    <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                        {infoMission?.address?.city}, {infoMission?.address?.postcode}, {infoMission?.address?.department?.split(',')[1]}</p>
                                                </div>
                                                <div className="col-span-1 flex justify-end px-5 py-8items-start">
                                                    <a href={`https://www.google.com/maps/search/?api=1&query=${infoMission?.address?.street} ${infoMission?.address?.postcode} ${infoMission?.address?.city}`}
                                                       target='_blank'>
                                                        <Button color={'SECONDARY'}
                                                                size={'LG'}>
                                                            <Localisation wh={15}
                                                                          color={'#111827'}/>
                                                            Voir l'adresse
                                                        </Button>
                                                    </a>
                                                </div>
                                            </div>
                                        </Cardbody>
                                        <Cardfooter>
                                            <div className="ft-sm text-center tab:text-right text-gray-500">
                                                Mission créée le {dateWordShort(infoMission?.createdAt)}
                                            </div>
                                        </Cardfooter>
                                    </div>
                                </div>
                                <MediaQuery maxWidth={769}>
                                    <div className={'bg-white h-16 w-full fixed flex items-center justify-center bottom-20 px-5 py-2'}>
                                        <div className={'w-80 flex gap-2'}>
                                            {candidate ?
                                                <Button size={'FULL'}
                                                        color={'SECONDARY'}
                                                        onClick={handleSubmitDelete}>
                                                    Annuler la candidature
                                                </Button>
                                                :
                                                (
                                                    <>
                                                        {isUserInvited ? (
                                                            <>
                                                                <Button size={'FULL'}
                                                                        color={'SECONDARY'}
                                                                        onClick={handleInviteRefuse}
                                                                >
                                                                    Refuser
                                                                </Button>
                                                                <Button size={'FULL'}
                                                                        color={'PRIMARY'}
                                                                        onClick={handleInviteAccept}
                                                                >
                                                                    Accepter
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button size={'FULL'}
                                                                    onClick={handleSubmit}>
                                                                Postuler
                                                            </Button>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </MediaQuery>
                            </div>
                        ) : (
                            <NotFound/>
                        )}
                    </>
                )
            }
        </PageForm>
    );
};

export default MissionfileSearch;