import React from 'react';

const Divider = ({w}) => {
    return (
        <>
            <hr className={`w-[${w}%] h-px bg-gray-20`} />
        </>
    );
};

export default Divider;