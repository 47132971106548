import React, { useState, useEffect } from "react";
import usefetchdata from "hooks/usefetchdata";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingButton from "components/button/loading.js";
import Modalheader from "components/modal/components/header.js";
import ModalBody from "components/modal/components/body.js";
import ModalFooter from "components/modal/components/footer.js";
import UseContext from "hooks/useContext";
import useModal from "components/modal/useModal.js";
import Button from "components/button/index.js";
import Pi from "components/modal/dataModal/data/modaljustify/identity/components/body/import/pi.js";
import Selfie from "components/modal/dataModal/data/modaljustify/identity/components/body/selfie";
import Autorisationtravail from "components/modal/dataModal/data/modaljustify/identity/components/body/autorisationtravail";
import IndicatorIdentity from "./components/body/import/indicator.js";
import Footerbutton from "components/modal/dataModal/data/modaljustify/identity/components/body/import/footerbutton.js";
import Confirmationimport from "./components/body/import/confirmationimport.js";
import CheckFilesIdentity from "./components/body/checkFilesIdentity";
const Modalidentityimport = () => {
    const { tempWorkerData } = usefetchdata();
    const { refreshLoadWorker, language, isWebcamToUse, setIsWebcamToUse } = UseContext();
    const [formIndex, setFormIndex] = useState(1);
    const [nationality, setNationality] = useState("");
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detectCaptureWebcam, setDetectCaptureWebcam] = useState(false);
    const { toggle } = useModal();

    const [pi, setPi] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });
    const [selfie, setSelfie] = useState(null);
    const [visa, setVisa] = useState({
        type: "",
        fileR: null,
        fileV: null,
        nationality: "",
    });

    const prev = () => {
        setFormIndex(formIndex - 1);
    };
    useEffect(() => {
        if (pi.nationality !== "" && pi.nationality !== nationality) {
            setNationality(pi.nationality);
        }
    }, [pi.nationality]);
    const elements = [
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[pi.fileR, pi.fileV]} />,
        <Selfie key={Selfie} setSelfie={setSelfie} setDetectCaptureWebcam={setDetectCaptureWebcam} />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[selfie]} />,
        <Confirmationimport
            key={Confirmationimport}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
    ];
    const elements2 = [
        <Pi
            key={Pi}
            pi={pi}
            setPi={setPi}
            check={check}
            setCheck={setCheck}
            formIndex={formIndex}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[pi.fileR, pi.fileV]} />,
        <Selfie key={Selfie} setSelfie={setSelfie} setDetectCaptureWebcam={setDetectCaptureWebcam} />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[selfie]} />,
        <Autorisationtravail
            key={Autorisationtravail}
            setVisa={setVisa}
            visa={visa}
        />,
        <CheckFilesIdentity key={CheckFilesIdentity} files={[visa.fileR, visa.fileV]} />,
        <Confirmationimport
            key={Confirmationimport}
            userData={tempWorkerData}
            setFormIndex={setFormIndex}
            nationality={nationality}
        />,
    ];

    const state1 = (f) =>
        f.type !== "" && f.nationality !== "" && f.fileR !== "";
    const state2 = (f) => f;
    const state3 = (f) =>
        f.type !== "" &&
        f.fileR !== "" &&
        ((f.type === "Visa" && f.fileV !== "") || f.type === "RTS");
    const handleIndex = (e) => {
        if (check) {
            if (formIndex < 1) setFormIndex(1);

            if (
                ((formIndex === 1 || formIndex === 2) && state1(pi)) ||
                ((formIndex === 3 || formIndex === 4) && state2(selfie)) ||
                ((formIndex === 5 || formIndex === 6) && state3(visa))
            )
                setFormIndex(formIndex + 1);
            else toast.warning("Veuillez remplir les champs");
        } else {
            toast.warning("Veuillez cocher l'attestation sur l'honneur");
        }
    };
    const f = async (files, successMsg) => {
        let isAllFilesSent = false;
        setLoading(true);
        const idToastLoad = toast.loading("Envoi des fichiers en cours");
        for (let i = 0; i < files.length; i++) {
            const result = await axios.post(files[i].url, files[i].file);
            if (result.status === 201) {
                isAllFilesSent = true;
            } else {
                isAllFilesSent = false;
                break;
            }
        }
        if (isAllFilesSent) {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Fichiers uploadés",
                type: "success",
                autoClose: 3000,
                isLoading: false,
            });
            refreshLoadWorker();
            toggle();
        } else {
            setLoading(false);
            toast.update(idToastLoad, {
                render: "Erreur d'envoi des fichiers",
                type: "error",
                isLoading: false,
            });
        }
    };

    //UE - PASSEPORT -SELFIE
    const uePassSelfie = async (firstFile, secondFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondFile,
            },
        ];

        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    //UE - CNI RECTO - CNI VERSO -SELFIE
    const ueCniSelfie = async (firstFile, secondFile, threeFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: secondFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: threeFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    //NUE PASSEPORT - SELFIE - RTS
    const etrPasseportSelfieRts = async (firstFile, secondeFile, threeFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: threeFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE PASSEPORT - SELFIE - VISA R - VISA V
    const etrPasseportSelfieVisaRV = async (
        firstFile,
        secondeFile,
        threeFile,
        fourFile
    ) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: secondeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: threeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: fourFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE CNI R - CNI V - SELFIE - RTS
    const etrCniSelfieVisaR = async (...forms) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: forms[0],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: forms[1],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: forms[2],
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: forms[3],
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };
    //NUE CNI R - CNI V - SELFIE - VISA R - VISA V
    const etrCniSelfieVisaRV = async (
        firstFile,
        secondFile,
        threeFile,
        fourFile,
        fiveFile
    ) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityR`,
                file: firstFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/identityV`,
                file: secondFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/selfie`,
                file: threeFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaR`,
                file: fourFile,
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/visaV`,
                file: fiveFile,
            },
        ];
        await f(files, 'Le document "Identité" est' + " ajouté.");
    };

    const handleSubmitMulti = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("xsrfToken");
        const first = new FormData();
        const second = new FormData();
        const three = new FormData();
        const four = new FormData();
        const five = new FormData();
        const docs = [first, second, three, four, five];
        docs.map((f) => f.append("token", token));

        if (check && pi.fileR !== "") {
            first.append("file", pi.fileR);
            first.append("identityType", pi.type);

            if (pi.type === "Passeport" && pi.nationality === "UE") {
                second.append("file", selfie);
                await uePassSelfie(first, second);
            } else if (pi.type === "CNI" && pi.nationality === "UE") {
                second.append("file", pi.fileV);
                three.append("file", selfie);
                await ueCniSelfie(first, second, three);
            } else if (pi.type === "Passeport" && pi.nationality === "NUE") {
                if (visa.type === "RTS") {
                    second.append("file", selfie);
                    three.append("file", visa.fileR);
                    three.append("visaType", visa.type);
                    await etrPasseportSelfieRts(first, second, three);
                } else {
                    second.append("file", selfie);
                    three.append("file", visa.fileR);
                    three.append("visaType", visa.type);
                    four.append("file", visa.fileV);
                    await etrPasseportSelfieVisaRV(first, second, three, four);
                }
            } else if (pi.type === "CNI" && pi.nationality === "NUE") {
                second.append("file", pi.fileV);
                three.append("file", selfie);
                four.append("file", visa.fileR);
                four.append("visaType", visa.type);
                if (visa.type === "RTS") {
                    await etrCniSelfieVisaR(first, second, three, four);
                } else {
                    five.append("file", visa.fileV);
                    await etrCniSelfieVisaRV(first, second, three, four, five);
                }
            }
        }
    };

    useEffect(() => {
        if(detectCaptureWebcam){
            handleIndex()
            setDetectCaptureWebcam(false)
        }
    }, [detectCaptureWebcam]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue("justify.m_identity_title_c")}
            </Modalheader>
            <ModalBody>
                <IndicatorIdentity
                    pi={pi}
                    nationality={nationality}
                    formIndex={formIndex}
                />
                {(pi.nationality === "NUE" || nationality === "NUE"
                    ? elements2
                    : elements
                ).filter((item, index) => index + 1 === formIndex)}
            </ModalBody>
            <ModalFooter>
                {loading ? (
                    <LoadingButton size={"LG"} />
                ) : (
                    <>
                        {formIndex === 1 ? (
                            <Button
                                size={"LG"}
                                color={"SECONDARY"}
                                onClick={toggle}
                            >
                                Annuler
                            </Button>
                        ) : (
                            <>
                                {formIndex === 3 && (
                                    <div
                                        className="text-yellow-500 underline cursor-pointer mr-auto"
                                        onClick={() => {
                                            setIsWebcamToUse(!isWebcamToUse);
                                        }}
                                    >
                                        {isWebcamToUse ? 'Importer un fichier' : 'Prendre une photo'}
                                    </div>
                                )}
                                <Button
                                    size={"LG"}
                                    color={"SECONDARY"}
                                    onClick={prev}
                                >
                                    Précédent
                                </Button>
                            </>
                        )}
                        <Footerbutton
                            formIndex={formIndex}
                            pi={pi.nationality}
                            nationality={nationality}
                            oC1={handleIndex}
                            oC2={handleSubmitMulti}
                        />
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default Modalidentityimport;
