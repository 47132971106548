import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import {LetterMail} from "assets/icons";
import Button from "components/button";
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";


const ValidMessage = ({setValidEmail,setMessage,message,hide}) => {
    const {toggle}=useModal()
    const {userData}=usefetchdata();

    const handleRefresh = () => {
        setValidEmail(false);
        setMessage({ ...message, motif: "" });
        setMessage({ ...message, message: "" });
    };
    const handleHide = () => {
        toggle()
        setValidEmail(false);
        setMessage({ ...message, motif: "" });
        setMessage({ ...message, message: "" });
    };

    return (
        <>
            <Modalheader hide={toggle} hideShow={toggle}></Modalheader>
            {/*body*/}
            <ModalBody>
                <div className="flex items-center justify-center flex-col gap-5">
                    <LetterMail />
                    <h4 className="text-[#5C616D] text-xl">Merci {userData.firstname}</h4>
                    <p className="text-center text-gray-600 ft-sm">
                        Votre message a été envoyé avec succès. Un conseiller
                        Jobbiz répondra à votre question dans moins de 24
                        heures.
                    </p>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={handleRefresh}>Envoyer un autre</Button>
                <Button size={'LG'} onClick={handleHide}>Quitter</Button>
            </ModalFooter>
        </>
    );
};

export default ValidMessage;