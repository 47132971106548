import React, {useEffect} from "react";

// IMPORT COMPONENTS
import Navbar from "components/Navbar/Navbar";
import MediaQuery from "react-responsive";
import SidebarDesktop from "components/sidebar/SidebarDesktop";
import SidebarMobile from "components/sidebar/SidebarMobile";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Intercom from '@intercom/messenger-js-sdk';
import usefetchdata from "../../hooks/usefetchdata";
import CryptoJS from 'crypto-js';

const PageForm = (props) => {
    const {userData} = usefetchdata();

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            const userIdentifier = userData._id.toString();
            const hash = CryptoJS.HmacSHA256(userIdentifier, process.env.REACT_APP_INTERCOM_ID).toString();
            Intercom({
                app_id: 'hq7jtuxz',
                user_id: userData._id,
                user_hash: hash,
                name: userData.firstname + ' ' + userData.lastname,
                email: userData.email,
                created_at: userData.createdAt,
                custom_attributes: {
                    userType: "tempWorker",
                },
            });
        }
    }, [userData]);

    return (
        <>
            <div className="flex h-screen overflow-y-hidden tab:overflow-hidden">
                {/* Sidebar */}
                <MediaQuery minWidth={769}><SidebarDesktop/></MediaQuery>
                <MediaQuery maxWidth={768}><SidebarMobile/></MediaQuery>
                {/* Content area */}
                <div className="flex flex-col flex-1 overflow-y-hidden overflow-x-hidden">
                    {/* Navbar */}
                    <Navbar></Navbar>
                    <main className={'overflow-y-auto'}>
                        {/* SubNav */}
                        {/*<div className="w-full">*/}
                        {/*    <SecondNavBar/>*/}
                        {/*</div>*/}
                        {/* Main Content */}
                        <div className="flex flex-col gap-6 overflow-y-auto h-full">{props.children}</div>
                    </main>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
};

export default PageForm;