import React from "react";
import Button from "components/button";
import {
    EmptyStateComingSoon,
    EmptyStateContract,
    EmptyStateMission,
    EmptyStateMissionSearch, EmptyStatePayslip, EmptyStateTimeRecord
} from "assets/icons";
import {useNavigate} from "react-router-dom";

const EmptyState = ({type, children, ...props}) => {
    const navigate = useNavigate()

    const logo = {
        'mission_done': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/>
        </div>,
        'mission_in_progress': <div className={'mx-auto my-2'}><EmptyStateMission
            wh={256}/></div>,
        'mission_soon': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/>
        </div>,
        'mission_search': <div className={'mx-auto my-2'}><EmptyStateMissionSearch
            wh={256}/></div>,
        'contract': <div className={'mx-auto my-2'}><EmptyStateContract wh={256}/></div>,
        'contract_nosign': <div className={'mx-auto my-2'}><EmptyStateContract wh={256}/></div>,
        'time_sheet': <div className={'mx-auto my-2'}><EmptyStateTimeRecord wh={256}/></div>,
        'payslip': <div className={'mx-auto my-2'}><EmptyStatePayslip wh={256}/></div>,
        'coming_soon': <div className={'mx-auto my-2'}><EmptyStateComingSoon wh={256}/></div>,
    }

    const title = {
        'mission_done': 'Aucune missions terminée',
        'mission_in_progress': 'Aucune missions en cours',
        'mission_soon': 'Aucune missions à venir',
        'mission_search': 'Pas de missions disponibles',
        'contract': 'Aucun contrats signés disponible',
        'contract_nosign':'Aucun contrats à signer disponible',
        'time_sheet': 'Aucun relevés d’heure à compléter',
        'time_sheet_completed':'Aucun relevés d’heure complets',
        'payslip': 'Aucune fiches de paie',
        'coming_soon': 'Fonctionnalité bientôt disponible',
        'webcam_waiting': 'En attente de la webcam',
        'webcam_denied': 'Caméra désactivée',
    }

    const description = {
        'mission_done': 'Il n’y a pas de missions terminées disponibles pour le moment.',
        'mission_in_progress': 'Il n’y a pas de missions en cours disponibles pour le moment.',
        'mission_soon': 'Il n’y a pas de missions à venir disponibles pour le moment.',
        'mission_search': 'Oops, il n’y a pas encore de missions disponibles pour le moment, un peu de patience',
        'contract': 'Il n’y a pas de contrats signés disponibles pour le moment. ',
        'contract_nosign':'Il n’y a pas de contrats à signer disponibles pour le moment. ',
        'time_sheet': 'Il n’y a pas de relevés d’heures à compléter disponibles pour le moment. ',
        'time_sheet_completed':'Il n’y a pas de relevés d’heures complets disponibles pour le moment. ',
        'payslip': 'Il n’y a pas de fiches de paie disponibles pour le moment. ',
        'coming_soon': 'Vous serez notifié une fois que cette fonctionnalité sera disponible.',
        'webcam_waiting': 'Une autorisation est en attente pour accéder à votre appareil photo.',
        'webcam_denied': 'Pour utiliser la caméra, veuillez rendre dans les paramètres et autoriser l\'accès à l\'appareil photo.',
    }

    const button = {
        'mission_done': <div className={'my-3'}><Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/searchMission')}>Rechercher des missions</Button></div>,
        'mission_in_progress': <div className={'my-3'}><Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/searchMission')}>Rechercher des missions</Button></div>,
        'mission_soon': <div className={'my-3'}><Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/searchMission')}>Rechercher des missions</Button></div>,
        'contract': '',
        'time_sheet': '',
        'payslip': '',
        'coming_soon': '',
    }

    return (
        <>
            <div className={'max-w-[535px] my-16 mx-auto text-center px-3'}>
                <div className={'flex'}>
                    {logo[type]}
                </div>
                <div className={'font-medium ft-xl text-[#5C616D]'}>
                    {title[type]}
                </div>
                <div className={'font-normal ft-b mt-2 text-[#757575]'}>
                    {description[type]}
                </div>
                <div className={'flex'}>
                    <div className={'mx-auto'}>
                        {button[type]}
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmptyState;