import {useNavigate, useParams} from "react-router-dom";
import {Close, Download, JobbizAppLogo} from "../../assets/icons";
import Divider from "../../components/divider/divider";
import Button from "../../components/button";
import useTitle from "../../hooks/TitleManager";

const MobileSuggestion = () => {
    useTitle({ title: "Télécharger l'app mobile" });
    const navigate = useNavigate()
    let linkApp = null
    let { redirect } = useParams();

    let OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Android") !== -1) OSName = "Android";
    if (navigator.appVersion.indexOf("like Mac") !== -1) OSName = "iOS";

    if(OSName !== "Android" && OSName !== "iOS"){
        navigate(redirect ?? '../dashboard')
    } else {
        if(OSName === "Android"){
            linkApp = 'https://play.google.com/store/apps/details?id=com.mycompany.jobbizapp'
        } else {
            linkApp = 'https://apps.apple.com/fr/app/jobbiz/id6450902588'
        }
    }

    return (
        <>
            <div className={'flex flex-col p-5 h-screen'}>
                <div className={'flex justify-end'}>
                    <div className={'p-2'} onClick={() => navigate(redirect ?? '../dashboard')}>
                        <Close wh={20} color={'#374151'}/>
                    </div>
                </div>
                <div className={'flex flex-1 flex-col gap-5 justify-center items-center text-center'}>
                    <div className={'flex flex-col justify-center items-center'}>
                        <div className={'shadow-sm'}>
                            <JobbizAppLogo/>
                        </div>
                    </div>
                    <div className={'ft-lg'}>
                        Télécharger l’app Jobbiz pour une meilleure expérience mobile
                    </div>
                    <Divider/>
                    <Button size={'FULL'} color={'PRIMARY'} onClick={() => window.location.href = linkApp}>
                        <div className={'flex gap-2'}>
                            <Download wh={24} color={'#FFFFFF'}/>
                            <div>
                                Télécharger l'app
                            </div>
                        </div>
                    </Button>
                    <Button size={'FULL'} color={'SECONDARY'} onClick={() => navigate(redirect ?? '../dashboard')}>
                        Accéder quand même
                    </Button>
                </div>
            </div>
        </>
    );
}

export default MobileSuggestion;