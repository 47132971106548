import React from 'react';
import { useLocation} from "react-router-dom";
import Thead from "components/table/components/thead";
import Tbodycontract from "components/table/components/tbodycontract";
import Tbodyhours from "components/table/components/tbodyhours";
import Tbodypayslip from "./components/tbodypayslip";

const Table = ({champsHeader, champsBody, isWaiting}) => {
    const route = useLocation()
    const {pathname}=route

    return (
        <div className='overflow-auto lg:overflow-visible '>
            <table className={`table-auto w-full text-gray-500 border-collapse ft-sm animate-fadeIn ${isWaiting ? 'animate-pulse' : ''}`}>
                <thead>
                <Thead champsHeader={champsHeader} isWaiting={isWaiting}/>
                </thead>
                <tbody>
                {
                    !isWaiting ? (
                        <>
                            {pathname.includes('contract')?<Tbodycontract champsBody={champsBody}/>:null}
                            {pathname.includes('hours')?<Tbodyhours champsBody={champsBody}/>:null}
                            {pathname.includes('payslip')?<Tbodypayslip champsBody={champsBody}/>:null}
                        </>
                    ) : null
                }
                </tbody>
            </table>
        </div>
    );
};

export default Table;