import React from 'react';
import Cardheader from "components/card/components/cardheader";
import AvatarCompagny from "components/avatar/avatarcompany";
import Button from "components/button";
import {
    Building,
    Calendar,
    CreditCard,
    DotsV,
    Localisation,
    ProfilIcon
} from "assets/icons";
import Cardbody from "components/card/components/cardbody";
import Cardfooter from "components/card/components/cardfooter";
import Badge from "components/badge/badge";
import Divider from "components/divider/divider";
import {useNavigate} from "react-router-dom";
import {dateToEpoch, dateWordShort} from "helper/helper";

const CardMission = ({data, isWaiting}) => {
    const navigate = useNavigate()
    const d0 = dateToEpoch(new Date())
    const d1 = dateToEpoch(new Date(data?.duration?.datestart))
    const d2 = dateToEpoch(new Date(data?.duration?.dateend))

    //ALERT PRIMARY VALID
    const statutMission = () => {
        if (d0.getTime() > d2.getTime()) return 'ALERT'
        if ((d0.getTime() >= d1.getTime()) && (d0.getTime() <= d2.getTime())) return 'VALID'
        if (d0.getTime() < d1.getTime()) return 'PRIMARY'
    }
    const colorFooter = {
        'ALERT': 'bg-red-300',
        'VALID': 'bg-green-300',
        'PRIMARY': 'bg-gray-300'
    }
    const contentFooter = {
        'ALERT': 'Terminée',
        'VALID': 'En cours',
        'PRIMARY': 'À venir'
    }
    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className='bg-white shadow-md w-72 cursor-pointer animate-pulse animate-fadeIn'>
                            <Cardbody>
                                <div className={'flex flex-col gap-4'}>
                                    <h4 className='ft-2xl font-bold text-gray-900 truncate my-3'>
                                        <div className={'h-4 bg-gray-200 rounded w-full'}></div>
                                    </h4>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                </div>
                                <div className={'my-5'}>
                                    <Divider w={100}/>
                                </div>
                                <div className={'flex flex-col gap-4'}>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                </div>

                            </Cardbody>
                            <Cardfooter add={` flex items-center justify-end rounded-b-md bg-gray-300`}>
                                <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                            </Cardfooter>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='bg-white shadow-md w-72 cursor-pointer animate-fadeIn'
                             onClick={(e) => {
                                 navigate(`/ficheMission/${data?._id}`)
                             }}
                        >
                            <Cardbody>
                                <div className={'flex flex-col gap-4'}>
                                    <h4 className='ft-2xl font-bold text-gray-900 truncate'>
                                        {data?.title}
                                    </h4>
                                    <span className='flex gap-2 items-center'>
                                        <Localisation color={'#374151'} wh={20}/>
                                        {data?.address[0]?.city ? data?.address[0]?.city : null}
                                    </span>
                                </div>
                                <div className={'my-5'}>
                                    <Divider w={100}/>
                                </div>
                                <div className={'flex flex-col gap-4'}>
                                    <span className='flex gap-2 items-center'>
                                        <Building wh={20} color={'#374151'}/>
                                        {data?.company[0]?.name_commercial??''}
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <Calendar color={'#374151'} wh={20}/>
                                        {dateWordShort(data?.duration?.datestart)} > {dateWordShort(data?.duration?.dateend)}
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <CreditCard color={'#374151'} wh={20}/>
                                        {data?.salary.wage_per_hour !== 0 ? data.salary.wage_per_hour + ' €/h' : 'Selon expérience'}
                                    </span>
                                </div>
                            </Cardbody>
                            <Cardfooter add={` flex items-center justify-end rounded-b-md ${colorFooter[statutMission()]}`}>
                                <Badge type={statutMission()}>{contentFooter[statutMission()]}</Badge>
                            </Cardfooter>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default CardMission;