import React, {useEffect, useState} from 'react';
import EmptyState from "components/EmptyState/EmptyState";
import usefetchdata from "hooks/usefetchdata";
import ApiDatabase from "server";
import MediaQuery from "react-responsive";
import CardMission from "components/card/mission";
import Cardmissionmobile from "components/card/cardmissionmobile";
import {dateToEpoch} from "../../../helper/helper";
import useTitle from "../../../hooks/TitleManager";

const Comingsoon = () => {
    useTitle({ title: "Missions - À venir" });
    const [dataMission, setDataMission] = useState([])
    const [comingSoonMission, setComingSoonMission] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.getMissions({token},
            (data) => {
                setDataMission(data)
                setIsWaiting(false)
            },
            (err) => {
                console.warn(err)
            }
        )
    }, [])

    useEffect(() => {
        let tampon = []
        for (let m in dataMission) {
            const d0 = dateToEpoch(new Date())
            const d1 = dateToEpoch(dataMission[m].duration?.datestart)
            if (d0.getTime() < d1.getTime()) {
                tampon.push(dataMission[m])
            }
        }
        setComingSoonMission(tampon)
    }, [dataMission])
    return (
        <>
            <MediaQuery minWidth={770}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <CardMission key={index} data={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                comingSoonMission.length ?
                                    comingSoonMission.map(
                                        (item, index) =>
                                            <>
                                                <CardMission key={index} data={item}/>
                                            </>
                                    )
                                    :
                                    <EmptyState type={'mission_soon'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <Cardmissionmobile key={index} data={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                comingSoonMission.length ?
                                    comingSoonMission.map(
                                        (item, index) =>
                                            <>
                                                <Cardmissionmobile key={index} data={item}/>
                                            </>
                                    )
                                    :
                                    <EmptyState type={'mission_soon'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
        </>
    );
};

export default Comingsoon;