import {useEffect, useState} from "react";
import {Information} from "../../assets/icons";
import {isNumeric} from "../../helper/helper";

const SocialSecurity = ({socialSecurity, setSocialSecurity}) => {
    const [error, setError] = useState(false);
    const [value, setValue] = useState(socialSecurity);

    useEffect(() => {
        if(socialSecurity) {
            if(typeof socialSecurity === 'object') {
                if(socialSecurity.number.toString().length === 10){
                    setValue(socialSecurity.number.toString().replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})/, '$1 $2 $3 $4 $5'));
                }
                else if(socialSecurity.number.toString().length === 15){
                    setValue(socialSecurity.number.toString().replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})(\w{3})(\w{2})/, '$1 $2 $3 $4 $5 $6 $7'));
                }
            }
            else{
                if(socialSecurity.toString().length === 10){
                    setValue(socialSecurity.replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})/, '$1 $2 $3 $4 $5'));
                }
                else if(socialSecurity.toString().length === 15){
                    setValue(socialSecurity.toString().replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})(\w{3})(\w{2})/, '$1 $2 $3 $4 $5 $6 $7'));
                }
                handleChange(socialSecurity.toString());
            }
        }
    }, [socialSecurity]);

    const handleChange = (social_security_number) => {
        //replace all space
        const social_security = social_security_number.replace(/\s/g, '');

        //replace to a-have the pattern x xx xx xx xxx progressively
        setValue(social_security.replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})/, '$1 $2 $3 $4 $5'));

        if(social_security.length === 15) {
            //replace to a-have the pattern x xx xx xx xxx xxx xx progressively
            setValue(social_security.replace(/(\w{1})(\w{2})(\w{2})(\w{2})(\w{3})(\w{3})(\w{2})/, '$1 $2 $3 $4 $5 $6 $7'));
        }

        if(social_security.length === 10 || social_security.length === 15) {
            setError(false);

            //accept only numbers and possibly 1 letter
            if(/^[0-9]*[A-Za-z]?[0-9]*$/.test(social_security)){
                const sex = social_security[0];
                const year = social_security[1] + social_security[2];
                const month = social_security[3] + social_security[4];
                const department_of_birth = social_security[5] + social_security[6];
                const place_of_birth = social_security[7] + social_security[8] + social_security[9];

                let order_number = null;
                let key = null;
                if(social_security.length === 15){
                    order_number = social_security[10] + social_security[11] + social_security[12];
                    key = social_security[13] + social_security[14];
                }

                if(sex && year && month && department_of_birth && place_of_birth) {
                    setSocialSecurity({
                        number: social_security,
                        sex: sex,
                        year: year,
                        month: month,
                        department_of_birth: department_of_birth,
                        place_of_birth: place_of_birth,
                        order_number: order_number,
                        key: key,
                    });
                }
                else{
                    setError('Le numéro de sécurité sociale n\'est pas valide');
                    setSocialSecurity(null);
                }
            }
            else{
                setError('Le numéro de sécurité sociale doit être composé uniquement de chiffres et potentiellement d\'une lettre');
                setSocialSecurity(null);
            }
        }
        else{
            setError('Le numéro de sécurité sociale doit faire 10 ou 15 caractères');
            setSocialSecurity(null);
        }
    };

    /*const isValidateSocialSecurity = (social_security) => {
        if(social_security.length === 15){
            const key = social_security[13] + social_security[14];
            const key_calculated = (97 - (parseInt(social_security.substring(0, 13)) % 97)).toString();
            return key === key_calculated;
        }
    }*/

    return (
        <div>
            <label className='ft-sm text-gray-500'>
                <div>N° de sécurité sociale</div>
                <div>
                    <input
                        type="text"
                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                        value={value}
                        onChange={e => handleChange(e.target.value)}
                    />
                </div>
            </label>
            {
                error && (
                    <div className='text-yellow-500 flex flex-row gap-4 items-center'>
                        <Information wh={14} color={'#FF9600'}/>
                        {error}
                    </div>
                )
            }
        </div>
    );
}

export default SocialSecurity;