import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import useModal from "../../../useModal";
import PdfPageViewer from "../../../../pdfPageViewer/pdfPageViewer";
import {useEffect, useState} from "react";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import usefetchdata from "../../../../../hooks/usefetchdata";
import UseContext from "../../../../../hooks/useContext";

const ModalWelcome = () => {
    const {toggle} = useModal()
    const {tempWorkerData} = usefetchdata()
    const {refreshWorker, setRefreshWorker} = UseContext()
    const [isWelcomeGuideAlreadyRead, setIsWelcomeGuideAlreadyRead] = useState(false)
    const [isLastPageVisible, setIsLastPageVisible] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)
        ApiDatabase.putWelcomeGuide({token}, (data) => {
            setIsButtonLoading(false)
            toast.success('Le guide de bienvenue a bien été consulté')
            setRefreshWorker(!refreshWorker)
            toggle()
        }, (error) => {
            setIsButtonLoading(false)
            toast.error('Une erreur est survenue')
        })
    }

    const lastPageVisible = () => {
        setIsLastPageVisible(true)
    }

    useEffect(() => {
        if(tempWorkerData){
            setIsWelcomeGuideAlreadyRead(tempWorkerData.welcome_guide?.is_read ?? false)
        }
    }, [tempWorkerData]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={false}>
                Guide de bienvenue
            </Modalheader>
            <ModalBody>
                <PdfPageViewer file={`${process.env.REACT_APP_FILE}media/jobbiz_documents/welcome_guide.pdf`} lastPageVisible={() => lastPageVisible()}/>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                {
                    (isLastPageVisible && !isWelcomeGuideAlreadyRead) ? (
                        <>
                            {isButtonLoading ? (
                                <Button size={'LG'} color={'DISABLED'}>Chargement ...</Button>
                            ) : (
                                <Button onClick={handleSubmit} size={'LG'} color={'PRIMARY'}>Confirmer</Button>
                            )}
                        </>
                    ) : (
                        <Button size={'LG'} color={'DISABLED'}>Confirmer</Button>
                    )
                }
            </ModalFooter>
        </>
    )
}

export default ModalWelcome;