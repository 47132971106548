import React, { useContext } from "react";
import TokenPwd from "components/modal/dataModal/data/ModalToken/tokenPwd";
import ModalToken from "components/modal/dataModal/data/ModalToken/token";
import UseContext from "hooks/useContext";
import Modaldelete from "components/modal/dataModal/data/modaljustify/documents/modaldelete";
import Modalformation from "components/modal/dataModal/data/modaljustify/documents/modalformation";
import Modalidentity from "components/modal/dataModal/data/modaljustify/identity/modalidentity";
import Cardvitale from "components/modal/dataModal/data/modaljustify/vitale/cardvitale";
import Modallicence from "components/modal/dataModal/data/modaljustify/documents/modallicence";
import IbanModal from "components/modal/dataModal/data/modaljustify/iban/iban";
import Modifyiban from "components/modal/dataModal/data/modaljustify/iban/modifyiban";
import Proofaddress from "components/modal/dataModal/data/modaljustify/address/proofaddress";
import ModalLogout from "components/modal/dataModal/data/ModalLogout/ModalLogout";
import ModalMessagerie from "components/modal/dataModal/data/ModalMessagerie/ModalMessagerie";
import ModalNotification from "components/modal/dataModal/data/ModalNotification/ModalNotification";
import Modalmodifyemail from "components/modal/dataModal/data/modalparametre/modalmodifyemail";
import Modalmodifypwd from "components/modal/dataModal/data/modalparametre/modalmodifypwd";
import Modalmodifydeleted from "components/modal/dataModal/data/modalparametre/modalmodifydeleted";
import Modalcompetence from "components/modal/dataModal/data/modalsavoirfaire/modalcompetence";
import Modalcv from "components/modal/dataModal/data/modalsavoirfaire/modalcv";
import Modaldeletepdf from "components/modal/dataModal/data/modalsavoirfaire/modaldeletepdf";
import Modalexperience from "components/modal/dataModal/data/modalsavoirfaire/modalexperience";
import Modalexperiencemodify from "components/modal/dataModal/data/modalsavoirfaire/modalexperiencemodifiy";
import Modalexperiencedelete from "components/modal/dataModal/data/modalsavoirfaire/modalexperiencedelete";
import Modallangue from "components/modal/dataModal/data/modalsavoirfaire/modallangue";
import Deletelanguage from "components/modal/dataModal/data/modalsavoirfaire/deletelanguage";
import Modalmateriel from "components/modal/dataModal/data/modalsavoirfaire/modalmateriel";
import Modaltoolsdeleted from "components/modal/dataModal/data/modalsavoirfaire/modaltoolsdeleted";
import ModalTokenPassword from "components/modal/dataModal/data/ModalToken/tokenPwd";
import Tokenemail from "components/modal/dataModal/data/ModalToken/tokenemail";
import ModalSignature from "components/modal/dataModal/data/ModalSignature";
import Completeday from "components/modal/dataModal/data/modaldocuments/hours/completeday";
import Validatesemaine from "components/modal/dataModal/data/modaldocuments/hours/validatesemaine";
import ModalTask from "./data/modalTask";
import ModalUpdateFormation from "./data/modaljustify/documents/modalupdateformation";
import ModalUpdateLicence from "./data/modaljustify/documents/modalupdatelicence";
import Modalidentityimport from "./data/modaljustify/identity/modalidentityimport.js";
import Modalidentitymodify from "./data/modaljustify/identity/modalidentitymodify.js";
import ModalDisponibility from "./data/modalDisponibility/modaDisponibility";
import ModalWelcome from "./data/ModalTrainingGuides/modalWelcome";
import ModalSecurity from "./data/ModalTrainingGuides/modalSecurity";
import ModalMissionAcceptInvite from "./data/modalMission/modalMissionAcceptInvite";
import ModalMissionRefuseInvite from "./data/modalMission/modalMissionRefuseInvite";
import ModalChangePreferenceDepartment from "./data/modalPreferenceDepartment/modalChangePreferenceDepartment";
//Import Modal

// Function
// nomination des modales
//Premiere partie:
// j-> justificatif *** msg->messagerie *** ntf->notification *** par-> parametres ***
// sf-> savoir faire *** tok-> token *** log->logout *** doc->documents
//Seconde partie:
//u-Update *** c->create *** d->delete ***g->get
//Troisieme partie:
//nom de la modale
const dataModal = new Map();
dataModal.set("j_d_delete", <Modaldelete />);
dataModal.set("j_c_formation", <Modalformation />);
dataModal.set("j_u_formation", <ModalUpdateFormation />);
dataModal.set("j_c_identity", <Modalidentity />);
dataModal.set("j_c_identityImport", <Modalidentityimport />);
dataModal.set("j_u_identiy", <Modalidentitymodify />);
dataModal.set("j_c_vitale", <Cardvitale />);
dataModal.set("j_c_licence", <Modallicence />);
dataModal.set("j_u_licence", <ModalUpdateLicence />);
dataModal.set("j_c_iban", <IbanModal />);
dataModal.set("j_u_iban", <Modifyiban />);
dataModal.set("j_c_proof", <Proofaddress />);
dataModal.set("log_d_logout", <ModalLogout />);
dataModal.set("msg_c_messagerie", <ModalMessagerie />);
dataModal.set("ntf_g_notif", <ModalNotification />);
dataModal.set("par_u_email", <Modalmodifyemail />);
dataModal.set("par_u_pwd", <Modalmodifypwd />);
dataModal.set("par_u_compte", <Modalmodifydeleted />);
dataModal.set("sf_c_competence", <Modalcompetence />);
dataModal.set("sf_c_cv", <Modalcv />);
dataModal.set("sf_d_cv", <Modaldeletepdf />);
dataModal.set("sf_c_exp", <Modalexperience />);
dataModal.set("sf_u_exp", <Modalexperiencemodify />);
dataModal.set("sf_d_exp", <Modalexperiencedelete />);
dataModal.set("sf_c_lang", <Modallangue />);
dataModal.set("sf_d_lang", <Deletelanguage />);
dataModal.set("sf_c_tool", <Modalmateriel />);
dataModal.set("sf_d_tool", <Modaltoolsdeleted />);
dataModal.set("tok_u_token", <ModalToken />);
dataModal.set("tok_u_tokenPwd", <ModalTokenPassword />);
dataModal.set("tok_u_tokenEmail", <Tokenemail />);
dataModal.set("doc_c_signature", <ModalSignature />);
dataModal.set("doc_c_completedHours", <Completeday />);
dataModal.set("doc_u_validWeek", <Validatesemaine />);
dataModal.set("tasks", <ModalTask />);
dataModal.set("disponibility", <ModalDisponibility />);
dataModal.set("welcome_guide", <ModalWelcome />);
dataModal.set("safety_booklet", <ModalSecurity />);
dataModal.set("mission_accept_invite", <ModalMissionAcceptInvite />);
dataModal.set("mission_refuse_invite", <ModalMissionRefuseInvite />);
dataModal.set("change_preference_department", <ModalChangePreferenceDepartment />);

const Datamodal = () => {
    const { modalType } = UseContext();
    return <>{dataModal.get(modalType)}</>;
};

export default Datamodal;
