import React from 'react';
import visaImageRecto from 'assets/images/titre_sejour_recto.png'
import visaImageVerso from 'assets/images/titre_sejour_verso.png'
import rtsImageAccept from 'assets/images/recepisse_accepte.png'
import rtsImageRefus from 'assets/images/recepisse_refus.png'


const Visaimage = ({visa}) => {
    return (
        <>
            {visa ==='Visa'?
                <>
                    <div className={'flex flex-col items-center' +
                        ' justify-center ' +
                        ' gap-2' +
                        ' tab:flex-row' +
                        ' justify-around my-3'}>
                        <img src={visaImageRecto} alt="selfie avec le titre de séjour"
                             className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>
                        <img src={visaImageVerso} alt="selfie avec le titre de séjour"
                             className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>

                    </div>
                </>:
                <>
                    <div className={'flex flex-col items-center' +
                        ' justify-center ' +
                        ' gap-2' +
                        ' tab:flex-row' +
                        ' justify-around my-3'}>
                        <img src={rtsImageAccept} alt="le titre de séjour"
                             className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>
                        <img src={rtsImageRefus} alt="le titre de séjour"
                             className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>

                    </div>
                </>
            }

        </>
    );
};

export default Visaimage;