import React from 'react';
import {SearchIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";

const SearchMissionDl = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitDesktop routeName={"Recherche de missions"} routeSidebar={"/searchMission"}>
            <span className='shrink-0'>
            <SearchIcon wh={40} color={`${pathname === '/searchMission' ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("searchMission") ? "text-yellow-500" : ''
                }`}
            >
                {language.getValue('sidebar.search','Recherche de missions')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default SearchMissionDl;