import React from 'react';

const Cardheader = ({children,add}) => {
    return (
        <>
            <div className={`py-3 px-6 ${add}`}>
                {children}
            </div>
        </>

    );
};

export default Cardheader;