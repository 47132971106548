import React, {useEffect, useState} from 'react';
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";
import MediaQuery from "react-responsive";
import {useNavigate} from "react-router-dom";
import Table from "../../../../components/table/table";
import Cardpayslip from "../../../../components/card/cardpayslip";
import useTitle from "../../../../hooks/TitleManager";

const Payslip = () => {
    useTitle({ title: "Fiches de paies" });
    const {tempWorkerData} = usefetchdata()
    const navigate = useNavigate()
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        if(tempWorkerData?.payslip){
            setIsWaiting(false)
        }
    }, [tempWorkerData])

    const month = {
        '1': 'Janvier',
        '2': 'Février',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juillet',
        '08': 'Août',
        '09': 'Septembre',
        '10': 'Octobre',
        '11': 'Novembre',
        '12': 'Décembre'
    }

    const titles = ['MOIS', 'MONTANT', 'DATE']

    return (
        <>
            <MediaQuery minWidth={770}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <Table champsBody={i} champsHeader={titles} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                tempWorkerData?.payslip?.length ?
                                    <>
                                        <Table champsBody={tempWorkerData?.payslip.reverse()} champsHeader={titles}/>
                                    </>
                                    :
                                    <EmptyState type={'payslip'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index)=>(
                                    <Cardpayslip item={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {tempWorkerData?.payslip?.length > 0 ?
                                tempWorkerData?.payslip?.map(item =>
                                    <Cardpayslip item={item}/>
                                )
                                :
                                <EmptyState type={'time_sheet'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
        </>
    );
};

export default Payslip;