import React, {useContext} from 'react';
import {DashboardIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitMobile from "components/sidebar/components/SidebarUnitMobile";

const DashboardML = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitMobile routeName={"Tableau de bord"} routeSidebar={"/dashboard"}>
            <div className="flex flex-col justify-center items-center ">

            <span className='shrink-0'>
                            <DashboardIcon wh={45} color={`${pathname.includes('/dashboard') ? '#FF9600' : '#6B7280'}`}/>
            </span>
                <span
                    className={`text-[9px] font-medium whitespace-normal text-center ${
                        pathname.includes("dashboard") ? "text-yellow-500" : ''
                    }`}
                >
            {language.getValue('sidebar.dashboard','Tableau de Bord')}
          </span>
            </div>
        </SidebarUnitMobile>
    );
};

export default DashboardML;