import React, {useState, useEffect, useRef, useContext} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import DashboardDL from "./Links/Desktop/DashboardDL";
import ProfilDL from "./Links/Desktop/ProfilDL";
import SearchMissionDL from "./Links/Desktop/SearchMissionDL";
import DocumentsDL from "./Links/Desktop/DocumentsDL";
import MissionDL from "./Links/Desktop/MissionDL";
import HelpDL from "./Links/Desktop/HelpDL";
import Tooltips from "components/tooltips/Tooltips";
import {ChevronLeftNav, ChevronRightNav, JobbizLogo} from "assets/icons";
import Logo from 'assets/images/Jobbiz.svg'
import Usefetchdata from "hooks/usefetchdata";
import UseContext from "hooks/useContext";

const SidebarDesktop = () => {
    const {sidebarExpanded, setSidebarExpanded, sidebarOpen, setSidebarOpen} = UseContext();
    const trigger = useRef(null);
    const sidebar = useRef(null);
    const route = useLocation();
    const {pathname} = route;
    const {userData} = Usefetchdata()

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    useEffect(()=>{
        if(userData?.firstname){
            setFirstname(userData.firstname)
        }

        if(userData?.lastname){
            setLastname(userData.lastname)
        }
    },[userData])

    useEffect(() => {
        localStorage.setItem("sidebar-expanded", sidebarExpanded);
        if (sidebarExpanded) {
            document.querySelector("body").classList.add("sidebar-expanded");
        } else {
            document.querySelector("body").classList.remove("sidebar-expanded");
        }
    }, [sidebarExpanded]);

    useEffect(() => {
        const clickHandler = ({target}) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });
    return (
        <>
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute left-0 top-0 tab:static tab:left-auto tab:translate-x-0 transform h-screen overflow-y-scroll tab:overflow-y-auto no-scrollbar w-60 tab:w-[80px] tab:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-[#FCFCFC] p-4 transition-all duration-200 ease-in-out  border-b-2 border-r-[1px]  ${
                    sidebarOpen ? "translate-x-0" : "-translate-x-60"
                } `}
            >
                {/* Sidebar Header */}
                <div className="flex justify-between mb-10 pr-3 mm:px-2">
                    {/* close Button */}
                    <button
                        className="tab:hidden text-gray-600 hover:text-slate-400"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only ">Fermer le menu</span>
                        <ChevronLeftNav/>
                    </button>
                    <NavLink
                        end
                        to="/dashboard"
                        className={`flex items-center justify-center w-24 !sidebar-expanded:hidden`}
                    >
                        <img src={Logo} alt="logo" className="w-full"/>

                    </NavLink>
                    {/* Expand / collapse button */}
                    <div
                        className="pt-3 hidden tab:inline-flex 2xl:hidden justify-end mt-auto">
                        <div className="px-3 py-2">
                            <Tooltips
                                text={sidebarExpanded ? "Reduire" : "Agrandir"}
                                placement="right"
                            >
                                <button
                                    onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                                    <span
                                        className="sr-only">Expand / collapse sidebar</span>
                                    <ChevronRightNav
                                        prop={"sidebar-expanded:rotate-180"}
                                    ></ChevronRightNav>
                                </button>
                            </Tooltips>
                        </div>
                    </div>
                </div>
                <div className="space-y-8">
                    <div>
                        <ul className="mt-3">
                            {/* Links */}
                            <DashboardDL pathname={pathname} tooltip={'right'}/>
                            <ProfilDL pathname={pathname} tooltip={'right'}/>
                            <SearchMissionDL pathname={pathname} tooltip={'right'}/>
                            <DocumentsDL pathname={pathname} tooltip={'right'}/>
                            <MissionDL pathname={pathname} tooltip={'right'}/>
                            {/*<hr className="w-[95%] h-px bg-gray-20 my-2"/>*/}
                            {/*<HelpDL pathname={pathname} tooltip={'right'}/>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarDesktop;