import React, {useEffect, useState} from 'react';
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";
import ApiDatabase from 'server'
import CardMission from "components/card/mission";
import MediaQuery from "react-responsive";
import Cardmissionmobile from "components/card/cardmissionmobile";
import {dateToEpoch} from "../../../helper/helper";
import useTitle from "../../../hooks/TitleManager";

const Progress = () => {
    useTitle({ title: "Missions - En cours" });
    const [dataMission, setDataMission] = useState([])
    const [inProgressMission, setInProgressMission] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.getMissions({token},
            (data) => {
                setDataMission(data)
                setIsWaiting(false)
            },
            (err) => {
                console.warn(err)
            }
        )
    }, [])

    useEffect(() => {
        let tampon = []
        for (let m in dataMission) {
            const d0 = dateToEpoch(new Date())
            const d1 = dateToEpoch(new Date(dataMission[m].duration?.datestart))
            const d2 = dateToEpoch(new Date(dataMission[m].duration?.dateend))
            if ((d0.getTime() >= d1.getTime()) && (d0.getTime() <= d2.getTime())) {
                tampon.push(dataMission[m])
            }
        }
        setInProgressMission(tampon)
    }, [dataMission])

    return (
        <>
            <MediaQuery minWidth={770}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <CardMission key={index} data={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                inProgressMission?.length ?
                                    inProgressMission.map(
                                        (item, index) =>
                                            <>
                                                <CardMission key={index} data={item}/>
                                            </>
                                    )
                                    :
                                    <EmptyState type={'mission_in_progress'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                {
                    isWaiting ? (
                        <>
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <Cardmissionmobile key={index} data={i} isWaiting={isWaiting}/>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                inProgressMission?.length ?
                                    inProgressMission.map(
                                        (item, index) =>
                                            <>
                                                <Cardmissionmobile key={index} data={item}/>
                                            </>
                                    )
                                    :
                                    <EmptyState type={'mission_in_progress'}/>
                            }
                        </>
                    )
                }
            </MediaQuery>
        </>
    );
};

export default Progress;