import React from 'react';
import passeportImage from 'assets/images/passeport_accepte.png'
import passportImageRefuse from 'assets/images/passeport_refus.png'
import cniImageRecto from 'assets/images/carte_identite _ recto.png'
import cniImageVerso from 'assets/images/carte_identite_verso.png'


const Pichoice = ({pi}) => {
    return (
        <>
            {pi.type === 'CNI' ?
                <div className={'flex flex-col items-center' +
                    ' justify-center ' +
                    ' gap-2' +
                    ' tab:flex-row' +
                    ' justify-around my-3'}>


                    <img src={cniImageRecto} alt=""
                         className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>
                    <img src={cniImageVerso} alt=""
                         className='object-cover object-right w-72 h-40 drop-shadow-sm rounded-lg'/>
                </div>
                :
                <div className={'flex flex-col items-center' +
                    ' justify-center ' +
                    ' gap-2' +
                    ' tab:flex-row' +
                    ' justify-around my-3'}>
                    <img src={passeportImage} alt=""
                         className='object-cover object-center w-72 h-40 drop-shadow-sm rounded-lg'/>
                    <img src={passportImageRefuse} alt=""
                         className='object-cover object-center w-72 h-40 drop-shadow-sm rounded-lg'/>
                </div>
            }
            </>
    );
};

export default Pichoice;