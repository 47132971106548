import React, {useState, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function Tabs({children}) {
    const pathname = useLocation().pathname.split('/')[2];
    const [activeTab, setActiveTab] = useState(pathname);
    const handleActiveTab = useCallback(label => setActiveTab(label), [])
    const navigate = useNavigate()

    const tabs = children.map(child => (

        <div onClick={e => {
            e.preventDefault()
            handleActiveTab(child.props.label)
            navigate(`./${child.props.label}`,{replace:true})
        }}
             className={`inline-block  py-3 px-4 cursor-pointer ${child.props.label.includes(activeTab )? 'border-b-2 border-b-yellow-500 text-yellow-500' : 'hover:border-b-2 hover:border-b-gray-500'} `}
             key={child.props.label}>
            {child.props.tabName}
        </div>
    ))

    const tabContent = children.filter(child => child.props.label === activeTab)
    return (
        <div>
            <div className='ml-3 flex gap-4  bg-white border-b-0 border-gray-100'>{tabs}</div>
            <div className=''>{tabContent}</div>
        </div>
    )
}

function Tab(props) {
    return <>{props.children}</>
}

export {Tabs, Tab}