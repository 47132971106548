import {NotFoundSvg} from "../../assets/icons";
import Button from "../button";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const NotFound = () => {
    const navigate = useNavigate()
    const {height, width} = useWindowDimensions();

    return (
        <>
            <div className={`flex ${(width <= 1050) ? 'flex-col-reverse max-w-[450px] text-center' : 'max-w-[750px]'} gap-3 my-20 mx-auto`}>
                <div className={'my-auto flex flex-col gap-6'}>
                    {(width > 1050) && (<div className={'text-8xl font-bold'}>404</div>)}
                    <div className={'flex flex-col gap-4'}>
                        <div className={`${(width <= 1050) ? 'text-xl' : 'text-2xl'} font-bold`}>Page non trouvée.</div>
                        <div className={`${(width <= 1050) ? 'text-sm' : 'text-base'} text-gray-500`}>Nous sommes désolés, mais la page que vous recherchez n'existe pas ou a été déplacée.</div>
                    </div>
                    <div className={`${(width <= 1050) && 'mx-auto'}`}>
                        <Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/dashboard')}>Tableau de bord</Button>
                    </div>
                </div>
                <div className={`my-auto flex items-center ${(width <= 1050) && 'mx-auto'}`}>
                    <NotFoundSvg wh={`${(width <= 1050) ? 256 : 320 }`}/>
                </div>
            </div>
        </>
    )
}

export default NotFound;