import isIBAN from 'validator/lib/isIBAN';
import {useEffect, useState} from "react";
import {Information} from "assets/icons";
const Iban = ({iban, setIban}) => {
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        if(iban){
            setValue(iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim())
        }
    }, [iban])

    const handleChange = (e) => {
        setValue(e.target.value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim());
        const iban = e.target.value.replace(/\s/g, '');

        if(iban.length === 27) {
            setError(false);

            if(isIBAN(iban)) {
                setIban(iban);
            }
            else{
                setError('Le numéro de compte n\'est pas valide');
                setIban(null);
            }
        }
        else{
            setError('Le numéro de compte doit faire 27 caractères');
            setIban(null);
        }
    }

    return (
        <div>
            <label className='ft-sm text-gray-500'>
                <div>Iban</div>
                <div>
                    <input
                        type="text"
                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                        value={value}
                        onChange={handleChange}
                        autoCapitalize="true"
                    />
                </div>
            </label>
            {
                error && (
                    <div className='text-yellow-500 flex flex-row gap-4 items-center'>
                        <Information wh={14} color={'#FF9600'}/>
                        {error}
                    </div>
                )
            }
        </div>
    )
}

export default Iban
