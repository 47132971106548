import {dateDiff} from "helper/helper";
import {Calendar, Euro, Localisation} from "assets/icons";
import {NavLink} from "react-router-dom";
import React from "react";

const CardSearchMissionDashboard = ({data}) => {
    const Date = dateDiff(data.createdAt);

    return (
        <>
            <NavLink to={`/ficheMissionSearch/${data._id}`}>
                <div className={'px-4 py-6 rounded-lg border h-full'}>
                    <div className={'text-2xl font-bold'}>
                        {data.title}
                    </div>
                    <div className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm text-gray-500'>
                        <Localisation wh={18} color={'#374151'}/>
                        {data?.address[0]?.city ?? null}
                    </div>
                </div>
            </NavLink>
        </>
    );

}

export default CardSearchMissionDashboard;