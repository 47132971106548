import React from 'react';
import Button from "components/button";

const Footerbutton = ({formIndex,check,oC1,oC2,oC3,oC4,oC5}) => {

    const elements = [
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC5}>Confirmer</Button>,
    ]

    const elements2 = [
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC1}>Confirmer</Button>,
        <Button size={'LG'} onClick={oC2}>Confirmer</Button>,
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC3}>Modifier</Button>,
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC4}>Modifier</Button>
    ]
    return (
        <>
            {
                check ? (
                    <>
                        {elements.map((item, index) => {
                            if((index + 1) === formIndex){
                                return elements[index]
                            }
                        })}
                    </>
                ) : (
                    <>
                        {elements2.map((item, index) => {
                            if((index + 1) === formIndex){
                                return elements2[index]
                            }
                        })}
                    </>
                )
            }
        </>
    );
};

export default Footerbutton;