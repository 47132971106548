import {useCallback, useEffect, useState} from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { useIntersectionObserver } from "@wojtekmaj/react-hooks";
import { SizeMe } from 'react-sizeme'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const observerConfig = {
    // How much of the page needs to be visible to consider page visible
    threshold: 0
};

const PageWithObserver = ({pageNumber, setPageVisibility, ...otherProps}) => {
    const [page, setPage] = useState();

    const onIntersectionChange = useCallback(
        ([entry]) => {
            setPageVisibility(pageNumber, entry.isIntersecting);
        },
        [pageNumber, setPageVisibility]
    );

    useIntersectionObserver(page, observerConfig, onIntersectionChange);

    return <Page canvasRef={setPage} pageNumber={pageNumber} {...otherProps} />;
}

const PdfPageViewer = ({file, lastPageVisible}) => {
    const [numPages, setNumPages] = useState(null);
    const [visiblePages, setVisiblePages] = useState({});

    useEffect(() => {
        if(numPages){
            if(visiblePages[numPages]){
                lastPageVisible()
            }
        }
    }, [numPages, visiblePages]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
        setVisiblePages((prevVisiblePages) => ({
            ...prevVisiblePages,
            [pageNumber]: isIntersecting
        }));
    }, []);

    return (
        <>
            <SizeMe>
                {({ size }) => (
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <PageWithObserver
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                setPageVisibility={setPageVisibility}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                width={size.width ?? 1}
                            />
                        ))}
                    </Document>
                )}
            </SizeMe>
        </>
    )

}

export default PdfPageViewer;