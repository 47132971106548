import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalFooter from "components/modal/components/footer";
import useModal from "components/modal/useModal";
import ModalBody from "components/modal/components/body";
import usefetchdata from "hooks/usefetchdata";
import ProgressBar from "components/progressBar/progressBar";
import {Close, TrophyIcon} from "assets/icons";
import CardProfileCompletionDashboard
    from "components/card/dashboard/cardProfileCompletionDashboard";

const ModalTask = () => {
    const {tempWorkerData} = usefetchdata();
    const {toggle}=useModal()
    const [completion, setCompletion] = useState(0);
    const [isTrainingGuides, setIsTrainingGuides] = useState(0);
    const [isCv, setIsCv] = useState(0);
    const [isDisponibility, setIsDisponibility] = useState(0);
    const [isTools, setIsTools] = useState(0);
    const [isLanguages, setIsLanguages] = useState(0);
    const [isAddress, setIsAddress] = useState(0);

    const completionSteps = [
        {
            title: 'Guide de bienvenue',
            link:  '../profil/training_guides',
            valid: isTrainingGuides,
        },
        {
            title: 'Ajouter un CV',
            link:  '../profil/savoir_faire/cv?openCv=true',
            valid: isCv,
        },
        {
            title: 'Ajouter une disponibilité',
            link:  '../profil/disponibilites',
            valid: isDisponibility,
        },
        {
            title: 'Ajouter ses matériels possédés',
            link:  '../profil/savoir_faire/tools',
            valid: isTools,
        },
        {
            title: 'Ajouter une langue',
            link:  '../profil/savoir_faire/language',
            valid: isLanguages,
        },
        {
            title: 'Ajouter une adresse',
            link:  '../profil/compte',
            valid: isAddress,
        },
    ];

    useEffect(() => {
        const completionPercent = 100/completionSteps.length * completionSteps.filter(step => step.valid === 1).length
        // rounded 2 decimals
        setCompletion(completionPercent.toFixed(0));
    }, [completionSteps]);

    useEffect(() => {
        if (tempWorkerData?.welcome_guide?.is_read) {
            setIsTrainingGuides(1);
        }
        if (tempWorkerData?.cv?.file) {
            setIsCv(1);
        }
        if (tempWorkerData?.availability?.length > 0) {
            setIsDisponibility(1);
        }
        if (tempWorkerData?.tools?.length > 0) {
            setIsTools(1);
        }
        if (tempWorkerData?.language?.length > 0) {
            setIsLanguages(1);
        }
        if (tempWorkerData?.addresses?.length > 0) {
            setIsAddress(1);
        }
    }, [tempWorkerData]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Compléter mon profil
            </Modalheader>
            <ModalBody>
                {completion < 100 ? (
                    <>
                        <div>
                            <div>
                                Profil complet à {completion}%
                            </div>
                            <div className={'text-xs text-gray-500 mt-2'}>
                                Pour augmenter vos chances de recrutement, complétez vos tâches.
                            </div>
                        </div>
                        <div className={'my-3'}>
                            <div className={'flex'}>
                                <div className={'grow my-auto'}>
                                    <ProgressBar progress={completion}/>
                                </div>
                                <div className={'my-auto ml-2'}>
                                    <TrophyIcon wh={22}/>
                                </div>
                            </div>
                        </div>
                        {
                            completionSteps.length > 0 ? (
                                <div className={'my-6'}>
                                    <div className={'flex flex-col gap-4'}>
                                        {
                                            // completionSteps with only valid === 0
                                            completionSteps.map((step, key) => {
                                                if (step.valid === 0) {
                                                    return (
                                                        <div onClick={toggle}>
                                                            <CardProfileCompletionDashboard key={key} text={step.title} link={step.link} value={parseInt(100/completionSteps.length)}/>
                                                        </div>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            ) : ''
                        }
                    </>
                ) : (
                    <>
                        <div className={'flex justify-between'}>
                            <div className={'my-auto'}>
                                <div className={'flex'}>
                                    <div className={'my-auto'}>
                                        Félicitations, votre profil est complet
                                    </div>
                                    <div className={'ml-2 my-auto'}>
                                        <TrophyIcon wh={22}/>
                                    </div>
                                </div>
                                <div className={'text-xs text-gray-500 mt-2'}>
                                    Continuez de tenir votre profil à jour pour augmenter vos chances de recrutement.
                                </div>
                            </div>
                            <div className={'my-auto'}>
                                <div className={'cursor-pointer'}>
                                    <Close wh={22} color={'#374151'}/>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter></ModalFooter>
        </>
    );
}

export default ModalTask;