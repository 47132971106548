import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Camera, Import} from "assets/icons";
import Webcam from "react-webcam";
import UseContext from "../../hooks/useContext";
import EmptyState from "../EmptyState/EmptyState";

const Importfile = ({name, change, titleFile, webcam = false, setDetectCaptureWebcam}) => {
    const {isWebcamToUse, setIsWebcamToUse } = UseContext();
    const [isWebcam, setIsWebcam] = useState(false);
    const [isUserAllowWebcam, setIsUserAllowWebcam] = useState(false);
    const [isUserAllowWebcamPrompt, setIsUserAllowWebcamPrompt] = useState(false);
    const [isUserAllowWebcamDenied, setIsUserAllowWebcamDenied] = useState(false);

    const getCameraPermission = () => {
        navigator.permissions.query({name: 'camera'})
            .then(function (permissionStatus) {
                if(permissionStatus.state === 'granted') {
                    setIsUserAllowWebcam(true)
                    setIsUserAllowWebcamPrompt(false)
                    setIsUserAllowWebcamDenied(false)
                }
                else {
                    setIsUserAllowWebcam(false)
                    setIsUserAllowWebcamPrompt(false)
                    setIsUserAllowWebcamDenied(true)
                }

                if(permissionStatus.state === 'prompt') {
                    setIsUserAllowWebcam(false)
                    setIsUserAllowWebcamPrompt(true)
                    setIsUserAllowWebcamDenied(false)
                    //ask permission
                    navigator.mediaDevices.getUserMedia({video: true})
                }
            });
    }

    useEffect(() => {
        if(webcam){
            if(isWebcamToUse){
                //lunch getCameraPermission every second
                const interval = setInterval(() => {
                    getCameraPermission()
                }, 1000);
                return () => clearInterval(interval);
            }
        } else {
            setIsWebcamToUse(false)
        }
    }, [webcam, isWebcamToUse]);

    const webcamRef = useRef(null);
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            const file = dataURLtoFile(imageSrc, 'selfie.jpeg');
            change({target: {files: [file]}});
            setDetectCaptureWebcam(true)
        },
        [webcamRef]
    );

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    useEffect(() => {
        if (webcam && isUserAllowWebcam) {
            setIsWebcam(true)
        } else {
            setIsWebcam(false)
        }
    }, [webcam, isUserAllowWebcam]);

    return (
        <>
            <form id='uploadForm'>
                {
                    isWebcamToUse ? (
                        <>
                            {
                                isUserAllowWebcam ? (
                                    <>
                                        {
                                            (webcam && isWebcam) && (
                                                <>
                                                    <div className={'relative'}>
                                                        <Webcam
                                                            audio={false}
                                                            width={1280}
                                                            height={720}
                                                            ref={webcamRef}
                                                            screenshotFormat="image/jpeg"
                                                            className={'rounded'}
                                                            mirrored={true}
                                                            videoConstraints={{
                                                                width: 1280,
                                                                height: 720,
                                                                facingMode: "user"
                                                            }}
                                                        />
                                                        <div
                                                            className='absolute bottom-1 left-1/2 -translate-x-1/2 rounded bg-white p-3 cursor-pointer'
                                                            onClick={capture}>
                                                            <Camera wh={18} color={'#374151'}/>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            isUserAllowWebcamPrompt && (
                                                <EmptyState type={'webcam_waiting'}/>
                                            )
                                        }
                                        {
                                            isUserAllowWebcamDenied && (
                                                <EmptyState type={'webcam_denied'}/>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <label className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                                <div
                                    className='ft-sm text-gray-700 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                                    <Import color={'#374151'} wh={14}/>
                                    {name}
                                </div>
                                <div className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                                    {titleFile ? titleFile : 'Aucun fichier'}
                                </div>
                                <input type="file" id='file'
                                       accept='image/jpeg,image/jpg,application/pdf'
                                       className='hidden'
                                       onChange={(e) => change(e)}/>
                            </label>
                            <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
                        </>
                    )
                }
            </form>
        </>
    );
};

export default Importfile;