import React from 'react';
import useModal from "components/modal/useModal";

const MessagerieNavDesktop = () => {
    const {toggle}=useModal()
    const handleModal=e=>{
        e.preventDefault()
        toggle('msg_c_messagerie')
    }
    return (
        <>
            <div className=" flex flex-row gap-4 items-center p-3 cursor-pointer" onClick={handleModal}>
                <span className="ft-sm font-medium text-gray-900">Messagerie</span>
            </div>

        </>
    );
};

export default MessagerieNavDesktop;