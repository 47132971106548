import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";

const ModalLogout = () => {
    const {toggle}=useModal()
    const handleLogout=()=>{
        localStorage.removeItem('xsrfToken')
        localStorage.removeItem('rUser')
        toggle()
        window.location.href=process.env.REACT_APP_INTERIM_LOGIN
    }
    return (
        <>
                                <Modalheader hide={toggle} hideShow={true}>
                                    Vous nous quittez déjà ?
                                </Modalheader>
                                {/*body*/}
                                <ModalBody>
                                    <p>Êtes-vous sûr de vouloir vous déconnecter de Jobbiz ?</p>
                                </ModalBody>
                                {/*footer*/}
                                <ModalFooter >
                                    <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                                    <Button size={'LG'} onClick={handleLogout}>Confirmer</Button>
                                </ModalFooter>

        </>
    );
};

export default ModalLogout;