import React, {useEffect, useState} from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Modify} from "assets/icons";
import {dateWordShort, getTimeSheetsDifferencesByDay, numberFormat} from "helper/helper";
import {useNavigate} from "react-router-dom";
import UseContext from "hooks/useContext";


const Tbodyhours = ({champsBody}) => {
    const {setContent}=UseContext()
    const navigate= useNavigate()

    const handleNavigate=(e,item)=>{
        e.preventDefault()
        setContent({
            title:item.infoMission[0].title,
            idMission:item.id_time_sheet,
            nameCompany:item.nameCompany
        })
        navigate(`../../ficheHour/${item._id}`)
    }


    return (
        <>
            {champsBody?.map(item =>
                <tr className={'border-b-2 border-gray-100 '}>
                    <td className={'py-5'}>{item.id_time_sheet}</td>
                    <td className={'py-5'}>{item.infoMission[0].title}</td>
                    <td className={'py-5'}>{item.infoMission[0].id_mission}</td>
                    <td className={'py-5'}>{item.nameCompany}</td>
                    {
                        item.validated !== 0 && (
                            <td className={'py-5'}>
                                {numberFormat(item.estimation_hours_time_sheet) + ' €'}
                            </td>
                        )
                    }
                    <td className={'py-5'}>
                        <Chip type={'INDICATOR'} color={item.validated === 0 ? 'ALERT' : 'INFO'}>{item.validated === 0 ? 'À compléter' : 'Complet'}</Chip>
                    </td>
                    <td className={'py-5'}>{dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}</td>
                    <td className={'py-5 flex items-center justify-center cursor-pointer'}>{
                        item.validated === 0 ? <span onClick={(e)=>{handleNavigate(e,item)}}>
                            <Modify wh={18} color={'#111827'}/>
                        </span>
                            :
                            <span onClick={(e)=>{handleNavigate(e,item)}}>
                                <DocumentIcon wh={35} color={'#111827'}/>
                            </span>
                    }</td>
                </tr>
            )}
        </>
    );
};

export default Tbodyhours;