import React, { useState } from "react";
import Requirements from "components/modal/dataModal/data/modaljustify/identity/components/requirements";
import Selfieimage from "components/modal/dataModal/data/modaljustify/identity/components/images/selfieimage";
import Importfile from "components/formcomponents/importfile";
import UseContext from "hooks/useContext.js";
import Banner from "components/banner/banner.js";

const Selfie = ({ setSelfie, setDetectCaptureWebcam }) => {
    const [titleFileR, setTitleFileR] = useState("");
    const { tempData } = UseContext();

    // name,change,titleFile
    const handlerSingleFileR = (e) => {
        setSelfie(e.target.files[0]);
        setTitleFileR(e.target.files[0].name);
    };
    return (
        <div className={"px-2"}>
            {tempData?.selfie?.validated === "1" ? (
                <Banner type={"warning"}>
                    Un document est déjà en cours d’observation, la modification
                    reste possible.
                </Banner>
            ) : (
                ""
            )}
            <Requirements />
            <Selfieimage />
            <div className={"mt-6"}>
                <Importfile
                    name={"Importer"}
                    titleFile={titleFileR}
                    change={handlerSingleFileR}
                    webcam={true}
                    setDetectCaptureWebcam={setDetectCaptureWebcam}
                />
            </div>
        </div>
    );
};

export default Selfie;
