import {useEffect, useState} from "react";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

const checkFilesIdentity = ({files}) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        setDocs([]);
        if(files.length){
            for (let i = 0; i < files.length; i++) {
                if(files[i]){
                    setDocs(docs => [...docs, {uri: URL.createObjectURL(files[i])}]);
                }
            }
        }
    }, [files]);

    return (
        <>
            {
                docs && (
                    <DocViewer documents={docs}
                               config={{
                                   header: {
                                       disableFileName: true,
                                   },
                               }}
                               style={{ maxHeight: 400 }}
                               pluginRenderers={DocViewerRenderers}
                    />
                )
            }
        </>
    )
}

export default checkFilesIdentity;