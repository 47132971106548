import React, {useContext} from 'react';
import SidebarUnitDesktop from "components/sidebar/components/SidebarUnitDesktop";
import {DashboardIcon} from "assets/icons";
import UseContext from "hooks/useContext";



const DashboardDL = ({pathname}) => {
const {language}=UseContext()
    return (
        <SidebarUnitDesktop routeName={"Tableau de Bord"} routeSidebar={"/dashboard"}>
            <span className='shrink-0'>
                            <DashboardIcon wh={40} color={`${pathname.includes('dashboard') ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("dashboard") ? "text-yellow-500" : ''
                }`}
            >
          {language.getValue('sidebar.dashboard','Tableau de bord')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default DashboardDL;