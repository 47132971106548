import {createContext, useState, useEffect} from "react";

import {LangFr} from "assets/locales/fr";
import {LangEn} from "assets/locales/en";
import {Language as LanguageManager} from "assets/locales/fLang";
import {useLocation} from "react-router-dom";

const roadContext = createContext({})

function ContextProvider({children}) {

// *******   Section Sidebar   *******
    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );
// *******   Section Refresh   *******
    const [refreshUser, setRefreshUser] = useState(false)
    const refreshLoadUser = () => {
        setRefreshUser(prev=>!prev)
    }
    const [refreshWorker, setRefreshWorker] = useState(false)
    const refreshLoadWorker = () => {
        setRefreshWorker(prev=>!prev)
    }
    const [tempData,setTempData]=useState(null)
// *******   Section Language   *******
//    instancie le manager
    const language = new LanguageManager()
//    charge les fichiers de langues (ils seront chargés qu'une fois)
    language.loadLanguage(LangFr)
    language.loadLanguage(LangEn)
//    spécifie la langue en cours
    language.setLanguage('fr')

// *******   Section Modal   *******
    const [modalShow, setModalShow] = useState(false)
    const [modalType, setModalType] = useState('')

// *******   Section URL   *******
    const route = useLocation()
    const {pathname} = route
    //1 - interimaire 2- entreprise
    const [typeNav, setTypeNav] = useState(1)
    const [email, setEmail] = useState('')
    const [name, setName] = useState({
        firstname: '',
        lastname: ''
    })
    useEffect(() => {
            if (pathname.includes('entreprise')) setTypeNav(2)
            else setTypeNav(1)
        }
        , [pathname])

    const [auth, setAuth] = useState({})
    const updateUser = (pToken = '-1', pRole) => {
        if (pToken !== '-1') {
            localStorage.setItem('xsrfToken', pToken)
            localStorage.setItem('rUser', pRole)
        }
    }


// *******   Section Time Sheets   *******
    const [timeData, setTimeData] = useState({
        id:'',
        id_time_sheet:'',
        index: '',
        day_hours:'',
        periode_one_start:'',
        periode_one_end:'',
        periode_two_start:'',
        periode_two_end:'',
        periode_three_start:'',
        periode_three_end:'',
    })
    const [totalH, setTotalH] = useState(0)
    const [refreshTime,setRefreshTime]=useState(false)
// *******   Section OBJ   *******
    const [expID, setExpID] = useState('')
    const [objID, setObjID] = useState('')
    const [objType, setObjType] = useState('')
    const [content,setContent]=useState('')
// *******   Section Contract   *******
    const [dataYousign,setDataYousign]=useState({})
    const [signatureLink, setSignatureLink] = useState('')
    const [refreshContract,setRefreshContract]= useState(false)

// *******   Section Cv   *******
    const [cvParsing, setCvParsing]=useState({})

    const [addAvailability, setAddAvailability] = useState(false)
    const [isPostulateWithoutAvailability, setIsPostulateWithoutAvailability] = useState({})

    const [isWebcamToUse, setIsWebcamToUse]=useState(false)

    const [idMission, setIdMission] = useState({})


    return (
        <roadContext.Provider value={{
            language,
            modalShow, setModalShow, modalType, setModalType,
            typeNav,
            email, setEmail,
            name, setName,
            updateUser,
            auth, setAuth,
            refreshUser, setRefreshUser, refreshLoadUser,
            refreshWorker, setRefreshWorker, refreshLoadWorker,
            sidebarOpen, setSidebarOpen,
            sidebarExpanded, setSidebarExpanded,
            expID, setExpID,
            objID, setObjID, objType, setObjType,content,setContent,
            timeData, setTimeData,refreshTime,setRefreshTime,totalH, setTotalH,
            signatureLink, setSignatureLink,dataYousign,setDataYousign,refreshContract,setRefreshContract,
            tempData,setTempData,
            cvParsing, setCvParsing,
            isWebcamToUse, setIsWebcamToUse,
            addAvailability, setAddAvailability,
            isPostulateWithoutAvailability, setIsPostulateWithoutAvailability,
            idMission, setIdMission,
        }}>
            {children}
        </roadContext.Provider>
    )
}

export {ContextProvider, roadContext}