import React, {useContext} from 'react';

import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";

const Modaldeletepdf = () => {
    const{refreshLoadWorker}=UseContext()
    const {tempWorkerData}=usefetchdata()
    const {toggle}=useModal()
    const idFile = tempWorkerData?.cv?.file
    const token = localStorage.getItem('xsrfToken')

const handleDeleted=(e)=>{
        e.preventDefault()
    ApiDatabase.deleteUserCV({token,cv:idFile},
        (data)=>{
            refreshLoadWorker()
            toggle()
            toast.success('Votre fichier a bien été supprimé')
        },
        (err=> console.log(err)))
}

    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>Suppression du fichier CV</Modalheader>
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer ce fichier ? Cette action est irréversible.
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleDeleted}>Confirmer</Button>
                </ModalFooter>
        </>
    );
};

export default Modaldeletepdf;