import {useContext, useEffect, useState} from "react";
import usefetchdata from "hooks/usefetchdata";
import {ProfilValidationIcon} from "assets/icons";
import Divider from "components/divider/divider";
import {NavLink} from "react-router-dom";
import Button from "components/button";

const ProfileValidation = () => {
    const {userData} = usefetchdata();

    const [validation, setValidation] = useState(0);

    useEffect(() => {
        if (userData?.validation) {
            setValidation(parseInt(userData.validation));
        }
    }, [userData]);

    return (
        <>
            {validation === 1 ? (
                <>
                    <div className={'px-4 py-5'}>
                        <div className={'flex flex-col md:flex-row gap-4'}>
                            <div className={'flex grow my-auto gap-4'}>
                                <div className={'my-auto'}>
                                    <ProfilValidationIcon wh={'64'} color={'#000'}/>
                                </div>
                                <div className={'my-auto grow'}>
                                    Validez votre identité pour postuler à des missions
                                </div>
                            </div>
                            <div className={'my-auto min-w-[175px]'}>
                                <NavLink to={'../profil/justificatifs'}>
                                    <div className={'hidden md:block'}>
                                        <Button size={'SM'}>Valider mon identité</Button>
                                    </div>
                                    <div className={'block md:hidden'}>
                                        <Button size={'FULL'}>Valider mon identité</Button>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className={'my-4'}>
                        <Divider/>
                    </div>
                </>
            ) : ''}
        </>
    );
}

export default ProfileValidation;