import React, {useEffect, useState} from 'react';
import ApiDatabase from 'server'
import {useParams} from "react-router-dom";
import UseContext from "hooks/useContext";
import Button from "components/button";
import Divider from "components/divider/divider";
import TbodyHours
    from "pages/content/documents/hours/components/tbodyHours";
import {toast} from "react-toastify";
import UseModal from "components/modal/useModal";
import MediaQuery from "react-responsive";
import Cardfichehour from "components/card/mission/cardfichehour";
import {dateWordMonth, numberFormat} from "helper/helper";
import Tooltips from "../../../../components/tooltips/Tooltips";
import {InformationCircle} from "../../../../assets/icons";
import useTitle from "../../../../hooks/TitleManager";

const FicheHour = () => {
    useTitle({ title: "Détail relevé d'heure" });
    const {
        setObjID,
        setObjType,
        refreshTime,
        setTotalH,
        content,
        setContent
    } = UseContext()
    const {id} = useParams()
    const [datas, setDatas] = useState([])
    const [nMission, setNMission] = useState('')
    const [valid, setValid] = useState(true)
    const {toggle}=UseModal()
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        ApiDatabase.getTimeSheetInfo({id},
            (data) => {
                setDatas(data)
                setContent({
                    idTimeSheet: data[0]?.id_time_sheet,
                })
                setObjID(data[0].id_time_sheet)
            },
            (err) => {
                console.log(err)
            }
        )
    }, [refreshTime])

    useEffect(() => {
        if (datas[0]?.id_mission)
            ApiDatabase.getInfoMyMission({token, idMission: datas[0]?.id_mission},
                (data) => {
                    setNMission(data.title)
                    setObjType(data.title)
                },
                (err) => {
                    console.log(err)
                })
    }, [datas[0]?.id_mission])

    const getSeconds = s => s.split("h").reduce((acc, curr) => acc * 60 + +curr, 0);
    useEffect(() => {
        let res = 0
        datas[0]?.days.map(i => {
            if (i.day_hours) {
                res += getSeconds(i.day_hours)
            }
            if (!i.validated) setValid(false)
            else setValid(true)
        })
        setTotalH(res)
    }, [datas])

    const handleSubmit = (e) => {
        if (valid) {
            toggle('doc_u_validWeek')

        } else {
            toast.error('Veuillez compléter la semaine')
        }
    }

    return (
        <div className={'flex flex-col mb-20 tab:mb-0 gap-6 tab:gap-0 tab:flex-row tab:justify-center tab:items-center'}>
            <MediaQuery minWidth={770}>
            <div className={'tab:w-[750px] bg-white flex flex-col gap-4 p-4'}>
                <div className={'flex justify-between items-center px-2 ft-2xl text-gray-900'}>
                    Relevé n° {datas[0]?.id_time_sheet}
                    {
                        datas[0]?.validated > 0 ? null :
                            <Button size={'SM'} onClick={handleSubmit}>Valider la semaine</Button>
                    }
                </div>
                <div className={'flex justify-between items-center px-2 ft-b text-gray-500'}>
                    {datas[0]?.validated > 0 ? `Voici le relevé d'heure de la mission ${datas[0]?.mission.title} n°${datas[0]?.mission?.id_mission} de ${datas[0]?.company?.name_commercial}, de la semaine du ${dateWordMonth(datas[0]?.periode_start)} au ${dateWordMonth(datas[0]?.periode_end)}`:`Complétez le relevé d'heure de la mission ${datas[0]?.mission.title} n°${datas[0]?.mission.id_mission} de ${datas[0]?.company.name_commercial}, de la semaine du ${dateWordMonth(datas[0]?.periode_start)} au ${dateWordMonth(datas[0]?.periode_end)}`}
                </div>
                <div className={'my-5'}>
                    <Divider w={100}/>
                </div>
                <div
                    className={'flex gap-6 items-center ft-sm text-gray-700 font-medium'}>
                    JOUR
                    <span
                        className={'text-gray-500'}>{datas[0]?.total_hours ? `heures travaillées (${datas[0]?.total_hours})` : null}  </span>
                </div>

                {datas.map(i =>
                    <TbodyHours i={i}/>
                )}
                {
                    datas[0]?.validated > 0 && (
                        <div>
                            <div className={'flex gap-2 ft-sm'}>
                                <div className={'my-auto'}>Salaire brut estimé :</div>
                                <div className={'my-auto text-gray-500'}>
                                    {numberFormat(datas[0]?.estimation_hours_time_sheet) + ' €'}
                                </div>
                                <div className={'my-auto cursor-pointer'}>
                                    <Tooltips
                                        text={`Le salaire brut estimé est basé sur les heures travaillées et n\'inclut pas les heures de nuit ou les primes. BETA`}
                                        placement='top'
                                    >
                                        <div><InformationCircle wh={20} color={'#6B7280'}/></div>
                                    </Tooltips>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={769}>

                {
                    datas.map(item => (
                        <>
                            {
                                item?.validated > 0 && (
                                    <div className={'px-4 flex justify-between gap-2'}>
                                        <div className={'my-auto text-gray-500 flex flex-col'}>
                                            <div className={'ft-sm my-auto'}>
                                                Salaire brut estimé :
                                            </div>
                                            <div className={'ft-xl border-dotted border-b-2 my-auto'}>
                                                {numberFormat(item?.estimation_hours_time_sheet) + ' €'}
                                            </div>
                                        </div>
                                        <div className={'my-auto cursor-pointer'}>
                                            <Tooltips
                                                text={`Le salaire brut estimé est basé sur les heures travaillées et n\'inclut pas les heures de nuit ou les primes. BETA`}
                                                placement='top'
                                            >
                                                <div><InformationCircle wh={24} color={'#6B7280'}/></div>
                                            </Tooltips>
                                        </div>
                                    </div>
                                )
                            }
                           {
                               item?.days.map((i, index) =>
                                   <Cardfichehour key={i.name} i={i} index={index} timeSheet={item}/>
                               )
                           }
                        </>
                    ))
                }
                <div
                    className={'fixed flex bottom-20 items-center justify-center px-2 pt-2 ft-2xl text-gray-900 bg-white w-full border-t-2'}>
                    {datas[0]?.validated > 0 ? null:
                        <Button size={'LG'} onClick={handleSubmit}>Valider la semaine</Button>
                    }
                </div>
            </MediaQuery>
        </div>
    );
};

export default FicheHour;