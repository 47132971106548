import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ApiDatabase from "server";
import useContext from "../../../../../hooks/useContext";
import ReactGA from "react-ga4";

const ModalMissionRefuseInvite = () => {
    const {toggle}=useModal()
    const {idMission, setIdMission} = useContext()
    const navigate = useNavigate()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit=(e)=>{
        e.preventDefault()
        ApiDatabase.postUserInviteMissionRefuse({token: token, idMission: idMission._id}, (data) => {
            ReactGA.event({
                category: 'Postuler à une mission - Refuser une invitation',
                action: 'postulate_mission',
                label: `${idMission?.isUserInvited ? 'Mission' : 'Invitation mission'} ${idMission?.title} n° ${idMission?.id_mission}`
            });
            toast.success(`Vous avez refusé l'invitation`)
            setIdMission({})
            toggle()
            navigate(-1)
        }, (err) => {
            console.error(err)
        })
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Refuser l'invitation
            </Modalheader>
            <ModalBody>
                <div className={'text-gray-500'}>
                    Êtes-vous sûr de refuser la mission Flash ?  La mission sera toujours disponible dans Recherche de missions mais vous ne serez plus placé en priorité.
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default ModalMissionRefuseInvite;