import React, {useEffect} from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {useLocation} from "react-router-dom";
import { saveAs } from 'file-saver';
import useTitle from "../../hooks/TitleManager";

const PdfView = () => {
    useTitle({ title: "Ouverture fichier" });
    const location = useLocation();

    const path = location.search.split('=')[1]
    const docs = [
        {uri: `${process.env.REACT_APP_FILE + path}`}
    ]


    //force download file on page loading in mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //download file on page loading

    useEffect(() => {
        if (isMobile) {
            downloadFile().then(r => console.log(r));
        }
    }, []);

    const downloadFile = async () => {
        const full_link = `${process.env.REACT_APP_FILE + path}`;
        const file_name = full_link.substring(full_link.lastIndexOf('/') + 1);

        try {
            const response = await fetch(full_link);
            const fileBlob = await response.blob();
            saveAs(fileBlob, file_name);
        } catch (error) {
            alert('Erreur lors du téléchargement du fichier :', error);
        }
    };

    return (
        <div className={'h-screen '}>
            {/*<iframe src={`${process.env.REACT_APP_FILE + path}`} width="100%" height="100%"></iframe>*/}
            <DocViewer documents={docs} config={{
                pdfZoom: {
                    defaultZoom: 1, // 1 as default,
                    zoomJump: 0.1, // 0.1 as default,
                },
            }} pluginRenderers={DocViewerRenderers}/>
        </div>
    );
};

export default PdfView;