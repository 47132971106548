import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AvatarCompagny from "components/avatar/avatarcompany";
import Cardheader from "components/card/components/cardheader";
import Cardbody from "components/card/components/cardbody";
import Cardfooter from "components/card/components/cardfooter";
import Chip from "components/chip/chip";
import PageForm from "components/pageForm/PageForm";
import ApiDatabase from 'server'
import {dateToEpoch, dateWordShort, statutMission} from "helper/helper";
import {
    ArrowLeft, BookOpen,
    Camion, Check,
    ChevronDown, ChevronRight,
    ChevronTop,
    Drapeau,
    Mail, Print,
    Warning
} from "assets/icons";
import Button from "components/button";
import Divider from "components/divider/divider";
import Bannertitle from "components/banner/bannertitle";
import usefetchdata from "hooks/usefetchdata";
import {toast} from "react-toastify";
import {Localisation} from "assets/icons";
import ScheduleWeekListMission
    from "../../../components/ScheduleWeekList/ScheduleWeekListMission";
import useModal from "../../../components/modal/useModal";
import NotFound from "../../../components/notFound/notFound";
import useTitle from "../../../hooks/TitleManager";


const Missionfiche = () => {
    useTitle({ title: "Missions - Détail" });
    const {toggle} = useModal()
    const navigate = useNavigate()
    const {userData, tempWorkerData} = usefetchdata()
    const [infoMission, setInfoMission] = useState({})
    const [candidate, setCandidate] = useState(false)
    const [deleteCandidate, setDeleteCandidate] = useState('')
    const [showTools, setShowTools] = useState(false)
    const [visibilityTools, setVisibilityTools] = useState(3)
    const [refresh, setRefresh] = useState(false)
    const [scheduleWeeks, setScheduleWeeks] = useState([])
    const [scheduleWeeksProv, setScheduleWeeksProv] = useState([])
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0)
    const [isWaiting, setIsWaiting] = useState(true);

    const {id} = useParams()
    const route = useLocation()
    const {pathname} = route
    const status = statutMission(infoMission?.duration)
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        ApiDatabase.getInfoMyMission({token, idMission: id},
            (data) => {
                setInfoMission(data ?? [])
                setRefresh(false)
                setIsWaiting(false)
            },
            (err) => {
                console.log(err)
            })
    }, [id, refresh])

    useEffect(() => {
        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.postVerifyUser({mission: id, token: token},
            (data) => {
                if (data.id) {
                    const result = !!infoMission.users.find(item => item.user_id === data.id)
                    const idUser = infoMission.users.find(item => item.user_id === data.id)
                    setDeleteCandidate(idUser?._id)
                    setCandidate(result)
                }
            },
            (err) => {
                console.log(err)
            })
    }, [refresh])

    useEffect(() => {
        ApiDatabase.getScheduleByMission({id: id},
            (data) => {
                setScheduleWeeksProv(data)
            },
            (err) => {
                console.log(err)
            })
    }, [infoMission])

    useEffect(() => {
        if (scheduleWeeksProv.length > 0) {
            scheduleWeeksProv.sort((a, b) => a.week_number - b.week_number)

            let isAWeekChosen = false;
            for (let i = 0; i < scheduleWeeksProv.length; i++) {
                scheduleWeeksProv[i].days.sort((a, b) => a.date - b.date)

                const number_week = ('0' + (parseInt(scheduleWeeksProv[i].week_number) + 1)).slice(-2)
                scheduleWeeksProv[i].name = `Semaine ${number_week}`
                scheduleWeeksProv[i].isNext = !!scheduleWeeksProv[i + 1];
                scheduleWeeksProv[i].isPrev = !!scheduleWeeksProv[i - 1];

                let isHidden = true;
                for (let j = 0; j < scheduleWeeksProv[i].days.length; j++) {
                    const scheduleDate = dateToEpoch(scheduleWeeksProv[i].days[j].date)
                    const today = dateToEpoch(new Date())

                    if (scheduleDate === today) {
                        setScheduleWeekDisplay(i)
                        isHidden = false;
                        isAWeekChosen = true;
                    }
                }
                scheduleWeeksProv[i].isHidden = isHidden;
                scheduleWeeksProv[i].isActualWeek = !isHidden;
            }
            if (!isAWeekChosen) {
                scheduleWeeksProv[0].isHidden = false;
            }

            setScheduleWeeks(scheduleWeeksProv);
        }
    }, [scheduleWeeksProv])

    const handleShowExp = () => {
        setShowTools(!showTools)
        if (showTools) {
            setVisibilityTools((prevValue) => prevValue - 3)
        } else {
            setVisibilityTools((prevValue) => prevValue + 3)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const token = localStorage.getItem('xsrfToken')
        ApiDatabase.postCandidate({mission: id, token: token},
            (data) => {
                setRefresh(!refresh)
                setCandidate(false)
                toast.success(`Votre candidature a bien été prise en compre`)
            },
            (err) => {
                console.log(err)
            })
    }

    const handleSubmitDelete = e => {
        e.preventDefault()
        ApiDatabase.deleteUserMission({
                mission: id,
                missionUser: deleteCandidate
            },
            (data) => {
                setRefresh(!refresh)
                setCandidate(true)
                // setCandidate(false)
                toast.success(`Votre candidature a bien été supprimée`)
            },
            (err) => {
                console.log(err)
            })
    }

    return (
        <PageForm>
            {
                isWaiting ? (
                    <>
                        <div className={'animate-fadeIn animate-pulse'}>
                            <div className="col-span-12 bg-white sticky z-10 mt-lg-10">
                                <div className='flex items-center justify-between h-14 px-4'>
                                    <div className='flex items-center gap-2 cursor-pointer' onClick={e => navigate(-1)}>
                                        <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                    </div>
                                    <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                </div>
                            </div>
                            <div className="flex justify-center col-span-full md:col-start-3 md:col-end-11">
                                <div className="block rounded-lg shadow-lg bg-white  w-full lg:w-[60%]  text-center mb-36 tab:mb-0 ">
                                    <Cardheader>
                                        <div className=" grid grid-cols-2 tab:grid-cols-4 flex gap-1">
                                            <div className="col-span-1 flex items-center justify-between w-[64px] h-[64px]">
                                                <div className={'h-full w-full bg-gray-200 rounded w-full'}></div>
                                            </div>
                                            <div className="col-span-2  order-last tab:order-none w-full">
                                                <div className="grid grid-col-3 text-left">
                                                    <div className="col-span-1 ft-sm text-gray-500 mb-1">
                                                        <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-2xl font-medium text-gray-700 mb-1">
                                                        <div className={'h-4 bg-gray-200 rounded w-2/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-xs text-gray-500">
                                                        <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Cardheader>

                                    <Cardbody>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-1 tab:grid-cols-2 text-left ">
                                            <div className="col-span-1 tab:col-span-2 ">
                                                <h5 className="text-gray-900 ft-b font-medium mb-2">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4 mb-1'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 ">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
                                            <div className="col-span-1 tab:col-span-3">
                                                <h5 className="ft-xl text-gray-700">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'my-5'}>
                                            <Divider w={100}/>
                                        </div>
                                        <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                            <div className="col-span-2 ">
                                                <h5 className="ft-xl text-gray-900">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="ft-xl text-gray-900 ml-2 mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2 mb-1">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 flex justify-end px-5 py-8items-start">
                                                <div className={'h-5 bg-gray-200 rounded w-2/4'}></div>
                                            </div>
                                        </div>
                                    </Cardbody>
                                    <Cardfooter>
                                        <div className="ft-sm text-center flex justify-end text-gray-500">
                                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                        </div>
                                    </Cardfooter>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {infoMission ? (
                            <div className={'animate-fadeIn'}>
                                <div className="col-span-12 bg-white sticky z-10">
                                    <div className='flex items-center justify-between h-14 px-4'>
                                        <div className='flex items-center gap-2 cursor-pointer'>
                                        <span onClick={e => navigate(-1)}>
                                            <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        </span>
                                            {infoMission?.title} - n°{infoMission?.id_mission}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-20 mb-32 justify-center col-span-full md:col-start-3 md:col-end-11">
                                    <div className="block rounded-lg shadow-lg bg-white w-full lg:w-[60%] text-center">
                                        <Cardheader>
                                            <div className=" grid grid-cols-2 tab:grid-cols-4 flex gap-1">
                                                <div className="col-span-1 flex items-center justify-start ">
                                                    <AvatarCompagny size={"LG"}/>
                                                </div>
                                                <div className="col-span-2 xl:-ml-16 order-last tab:order-none ">
                                                    <div className="grid grid-col-3 text-left">
                                                        <div className="col-span-1 ft-sm text-gray-500">
                                                            {new Date(infoMission?.duration?.datestart).toLocaleDateString('FR-fr', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                timeZone: "Europe/Paris"
                                                            })} - {new Date(infoMission?.duration?.dateend).toLocaleDateString('FR-fr', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            timeZone: "Europe/Paris"
                                                        })}
                                                        </div>
                                                        <div className="col-span-1 ft-2xl font-medium text-gray-700 ">
                                                            {infoMission?.title} n°{infoMission?.id_mission}
                                                        </div>
                                                        <div className="col-span-1 ft-xs text-gray-500">
                                                            {infoMission?.infoCompany?.name_commercial} · {infoMission?.activity} ·
                                                            {infoMission?.address?.city} , {infoMission?.address?.department}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    infoMission?.user_assigned !== '' ?
                                                        <div className="col-span-1 flex justify-end">
                                                            <Chip color={status?.color}>{status?.text}</Chip>
                                                        </div>
                                                        : ''
                                                }

                                            </div>
                                        </Cardheader>

                                        <Cardbody>
                                            <div className={'flex flex-col gap-4'}>
                                                <div className={'my-5'}>
                                                    <Divider w={100}/>
                                                </div>
                                                <div className="grid grid-cols-1 tab:grid-cols-2 text-left ">
                                                    <div className="col-span-1 tab:col-span-2 ">
                                                        <h5 className="text-gray-900 ft-b font-medium ">
                                                            Description
                                                        </h5>
                                                        <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                            <div dangerouslySetInnerHTML={{__html: infoMission?.desc ?? ''}}></div>
                                                        </p>
                                                        {
                                                            infoMission?.other ? (
                                                                <p className="text-gray-500 ft-sm font-normal mb-4">
                                                                    <div dangerouslySetInnerHTML={{__html: infoMission?.other ?? ''}}></div>
                                                                </p>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div className="col-span-1 ">
                                                        <h6 className="text-gray-900 ft-b font-medium ">
                                                            Période
                                                        </h6>
                                                        <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                            {new Date(infoMission?.duration?.datestart).toLocaleDateString('FR-fr', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                timeZone: "Europe/Paris"
                                                            })} - {new Date(infoMission?.duration?.dateend).toLocaleDateString('FR-fr', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            timeZone: "Europe/Paris"
                                                        })}
                                                        </p>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <h6 className="text-gray-900 ft-b font-medium ">
                                                            Salaire horaire brut
                                                        </h6>
                                                        <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                            {infoMission?.salary?.wage_per_hour !== 0 ? infoMission?.salary?.wage_per_hour + ' €/h' : 'Selon expérience'}
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    scheduleWeeks.length > 0 ? (
                                                        <>
                                                            <div className={'my-5'}>
                                                                <Divider w={100}/>
                                                            </div>
                                                            <div className={'text-left'}>
                                                                {
                                                                    scheduleWeeks.map((scheduleWeek, index) => {
                                                                        if (scheduleWeek.isNext !== undefined && scheduleWeek.isPrev !== undefined && scheduleWeek.isHidden !== undefined && scheduleWeek.isActualWeek !== undefined) {
                                                                            return (
                                                                                <ScheduleWeekListMission
                                                                                    key={index}
                                                                                    indexScheduleWeek={index}
                                                                                    scheduleWeek={scheduleWeek}
                                                                                    isNext={scheduleWeek.isNext}
                                                                                    isPrev={scheduleWeek.isPrev}
                                                                                    isHidden={scheduleWeekDisplay !== index}
                                                                                    isActualWeek={scheduleWeek.isActualWeek}
                                                                                    setScheduleWeekDisplay={setScheduleWeekDisplay}
                                                                                />
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className={'my-2'}>
                                                    <Divider w={100}/>
                                                </div>
                                                <div className={'flex gap-3 justify-between cursor-pointer'} onClick={() => toggle('safety_booklet')}>
                                                    <div className={'my-auto flex gap-3'}>
                                                        <div className={'my-auto p-3 bg-gray-100 rounded-lg'}>
                                                            <BookOpen wh={20} color={'#111827'}/>
                                                        </div>
                                                        <div className={'my-auto'}>Livret de sécurité</div>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        {tempWorkerData?.safety_booklet?.is_read ? (
                                                            <div className={'rounded p-2 bg-green-50'}>
                                                                <Check wh={20} color={'#10B981'}/>
                                                            </div>
                                                        ) : (
                                                            <div className={'p-2'}>
                                                                <ChevronRight wh={20} color={'#374151'}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={'my-2'}>
                                                    <Divider w={100}/>
                                                </div>
                                                {(infoMission?.language?.length > 0 || infoMission?.tools?.length > 0 || infoMission?.licences?.length > 0) && (
                                                    <>
                                                        <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
                                                            <div className="col-span-1 tab:col-span-3">
                                                                <h5 className="ft-xl text-gray-700">Pré-requis</h5>
                                                            </div>
                                                            {infoMission?.language?.length > 0 && (
                                                                <div className="col-span-1 my-2">
                                                                    <div className="flex flex-nowrap items-center">
                                                                        <Drapeau wh={18} color={'#111827'}/>
                                                                        <h6 className="ft-xl text-gray-900 ml-2">Langues</h6>
                                                                    </div>
                                                                    <div className="grid grid-cols-1">
                                                                        {infoMission?.language ?
                                                                            infoMission?.language.map((item) =>
                                                                                <div className="col-span-1 ft-sm text-gray-800">{item}
                                                                                </div>
                                                                            ) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {infoMission?.tools?.length > 0 && (
                                                                <div className="col-span-1 my-2">
                                                                    <div className="flex flex-nowrap items-center">
                                                                        <Warning wh={18} color={'#111827'}/>
                                                                        <h6 className="text-xl text-gray-900 ml-2">Matériels</h6>
                                                                    </div>
                                                                    <div className="grid grid-cols-1 flex gap-3">
                                                                        {
                                                                            infoMission?.tools && Array.isArray(infoMission?.tools) ?
                                                                                infoMission?.tools.slice(0, visibilityTools).map(item =>
                                                                                    <div className="col-span-1 ft-sm text-gray-800">{item}
                                                                                    </div>
                                                                                ) : ''

                                                                        }
                                                                        {infoMission?.tools.length < 3 ?
                                                                            '' :
                                                                            <div className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3" onClick={handleShowExp}>
                                                                                {showTools ?
                                                                                    <ChevronTop w={18} h={8} color={'#FF9600'}/> :
                                                                                    <ChevronDown w={18} h={8} color={'#FF9600'}/>}
                                                                                En savoir {showTools ? ' moins' : ' plus'}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {infoMission?.licences?.length > 0 && (
                                                                <div className="col-span-1 my-2">
                                                                    <div className="flex flex-nowrap items-center">
                                                                        <Camion w={18} h={14} color={'#111827'}/>
                                                                        <h6 className="ft-xl text-gray-900 ml-2">
                                                                            Permis
                                                                        </h6>
                                                                    </div>
                                                                    <div className="grid grid-cols-1">
                                                                        {infoMission?.licences ?
                                                                            infoMission?.licences.map((item) =>
                                                                                <div className="col-span-1 ft-sm text-gray-800">{item}
                                                                                </div>
                                                                            ) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={'my-5'}>
                                                            <Divider w={100}/>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                                    <div className="col-span-2 ">
                                                        <h5 className="ft-xl text-gray-900">
                                                            Lieu de travail
                                                        </h5>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <h6 className="ft-xl text-gray-900 ml-2">
                                                            {infoMission?.infoCompany?.name_commercial}
                                                        </h6>
                                                        <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                            {infoMission?.address?.street}
                                                        </p>
                                                        <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                            {infoMission?.address?.city}, {infoMission?.address?.postcode}, {infoMission?.address?.department?.split(',')[1]}
                                                        </p>
                                                    </div>
                                                    <div className="col-span-1 flex justify-end items-start">
                                                        <a href={`https://www.google.com/maps/search/?api=1&query=${infoMission?.address?.street} ${infoMission?.address?.postcode} ${infoMission?.address?.city}`}
                                                           target='_blank'>
                                                            <Button color={'SECONDARY'} size={'LG'}>
                                                                <Localisation wh={15} color={'#111827'}/>
                                                                Voir l'adresse
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Cardbody>
                                        <Cardfooter>
                                            <div className="ft-sm text-center tab:text-right text-gray-500">
                                                Mission créée le {dateWordShort(infoMission?.createdAt)}
                                            </div>
                                        </Cardfooter>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NotFound/>
                        )}
                    </>
                )
            }
        </PageForm>
    );
};

export default Missionfiche;