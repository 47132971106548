import React, {useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDataBase from 'server'
import {Close, ExclamationCircle, Plus} from "assets/icons";
import {dateWordDay} from "../../../../../../helper/helper";
import ExternalTooltip from "../../../../../tooltips/ExternalTooltip";
import {toast} from "react-toastify";
import usefetchdata from "../../../../../../hooks/usefetchdata";


const Completeday = () => {
    const { tempWorkerData} = usefetchdata();
    const {toggle} = UseModal()
    const {
        content,
        setTimeData,
        timeData,
        refreshTime,
        setRefreshTime
    } = UseContext()
    const [work, setWork] = useState(true)
    const [iTime, setITime] = useState(1)
    const [pTime, setPTime] = useState(true)
    const [error1, setError1] = useState('')
    const [error2, setError2] = useState('')
    const [error3, setError3] = useState('')
    const [error4, setError4] = useState('')
    const [error5, setError5] = useState('')
    const [error6, setError6] = useState('')
    const [error7, setError7] = useState(false)
    const [error8, setError8] = useState(false)
    const [error9, setError9] = useState(false)
    const [error10, setError10] = useState(false)
    const [error11, setError11] = useState(false)
    const [error12, setError12] = useState(false)
    const [confirmTutoBreaks, setConfirmTutoBreaks] = useState(true)
    const [onClickConfirm, setOnClickConfirm] = useState(false)

    const token = localStorage.getItem('xsrfToken')

    const handleITime = (e) => {
        setITime(iTime + 1)
    }
    const handleSITime = (e) => {
        setITime(iTime - 1)
        switch (iTime) {
            case 2 :
                setError2('')
                timeData.periode_two_start=''
                timeData.periode_two_end=''
                break
            case 3:
                setError3('')
                timeData.periode_three_start=''
                timeData.periode_three_end=''
                break
            default:
                break

        }
    }
    useEffect(() => {
        if (!work) setTimeData(prevData => ({...prevData, day_hours: '0'}))
        else setTimeData(prevData => ({...prevData, day_hours: ''}))
    }, [work])
    // const getMinutes = s => s.split(":").reduce((acc, curr) => acc * 60 + +curr, 0);
    const getTimeDay = s => new Date(2023, 0o1, 0o1, s.split(":")[0], s.split(":")[1], 0o0)

    // Period : hour start < hour end
    const cTime1 = (f) => {
        if (f.periode_one_start !== '' && f.periode_one_end !== '') {
            return (getTimeDay(f.periode_one_start).getTime()) < (getTimeDay(f.periode_one_end).getTime()) && f.periode_one_start !== '' && f.periode_one_end !== ''
        } else {
            return f.periode_one_start === '' && f.periode_one_end === '';
        }
    }

    //Pause 1 : hour start < hour end
    const cTime2 = (f) => {
        if(f.periode_one_end !== '' && f.periode_two_start !== '' && f.periode_two_end !== ''){
            return (getTimeDay(f.periode_two_start).getTime()) < (getTimeDay(f.periode_two_end).getTime()) && ((getTimeDay(f.periode_one_end).getTime()) > (getTimeDay(f.periode_two_end).getTime())) && f.periode_two_start !== '' && f.periode_two_end !== ''
        } else {
            return f.periode_two_start === '' && f.periode_two_end === '';
        }
    }

    //Pause 2 : hour start < hour end
    const cTime3 = (f) => {
        if(f.periode_one_end !== '' && f.periode_three_start !== '' && f.periode_three_end !== ''){
            return (getTimeDay(f.periode_three_start).getTime()) < (getTimeDay(f.periode_three_end).getTime()) && ((getTimeDay(f.periode_one_end).getTime()) > (getTimeDay(f.periode_three_end).getTime())) && f.periode_three_start !== '' && f.periode_three_end !== ''
        } else {
            return f.periode_three_start === '' && f.periode_three_end === '';
        }
    }

    //Period / Pause 1 : period start < pause 1 start
    const cTime4 = (f) => {
        if(f.periode_one_start !== '' && f.periode_two_start !== ''){
            return (getTimeDay(f.periode_one_start).getTime()) < (getTimeDay(f.periode_two_start).getTime()) && f.periode_one_start !== '' && f.periode_two_start !== ''
        } else {
            return true
        }
    }

    //Period / Pause 2 : period start < pause 2 start
    const cTime5 = (f) => {
        if(f.periode_one_start !== '' && f.periode_three_start !== ''){
            return (getTimeDay(f.periode_one_start).getTime()) < (getTimeDay(f.periode_three_start).getTime()) && f.periode_one_start !== '' && f.periode_three_start !== ''
        } else {
            return true
        }
    }

    //Pause 1 / Pause 2 : pause 1 end > pause 2 start
    const cTime6 = (f) => {
        if(f.periode_two_end !== '' && f.periode_three_start !== ''){
            return (getTimeDay(f.periode_two_end).getTime()) < (getTimeDay(f.periode_three_start).getTime()) && f.periode_two_end !== '' && f.periode_three_start !== ''
        } else {
            return true
        }
    }

    const cTime7 = (f) => {
        if(f.periode_one_start === ''){
            return f.day_hours === '0';
        } else {
            return true
        }
    }

    const cTime8 = (f) => {
        if(f.periode_one_end === ''){
            return f.day_hours === '0';
        } else {
            return true
        }
    }

    const cTime9 = (f) => {
        if(f.periode_two_start === ''){
            if(iTime >= 2){
                return f.day_hours === '0';
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime10 = (f) => {
        if(f.periode_two_end === ''){
            if(iTime >= 2){
                return f.day_hours === '0';
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime11 = (f) => {
        if(f.periode_three_start === ''){
            if(iTime >= 3){
                return f.day_hours === '0';
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime12 = (f) => {
        if(f.periode_three_end === ''){
            if(iTime >= 3){
                return f.day_hours === '0';
            } else {
                return true
            }
        } else {
            return true
        }
    }

    //Pause 1 / Pause 2 : pause 1 end >= pause 2 start or pause 2 end >= pause 1 start
    //const cTime6 = (f) => ((getTimeDay(f.periode_two_end).getTime()) >= (getTimeDay(f.periode_three_start).getTime()) || (getTimeDay(f.periode_three_end).getTime()) >= (getTimeDay(f.periode_two_start).getTime()))

    useEffect(() => {
        if (!cTime1(timeData)) {
            setError1('Veuillez indiquer une heure valide de fin pour la periode')
        } else {
            setError1('')
        }
        if (!cTime2(timeData)) {
            setError2('Veuillez indiquer une heure de fin valide pour la pause 1')
        } else {
            setError2('')
        }
        if (!cTime3(timeData)) {
            setError3('Veuillez indiquer une heure de fin valide pour la pause 2')
        } else {
            setError3('')
        }
        if (!cTime4(timeData)) {
            setError4('Veuillez indiquer une heure de début valide pour la pause 1')
        } else {
            setError4('')
        }
        if (!cTime5(timeData)) {
            setError5('Veuillez indiquer une heure de début valide pour la pause 2')
        } else {
            setError5('')
        }
        if (!cTime6(timeData)) {
            setError6('Veuillez indiquer une pause 2 inférieure à la pause 1')
        } else {
            setError6('')
        }
        if(!cTime7(timeData)){
            setError7(true)
        } else {
            setError7(false)
        }
        if(!cTime8(timeData)){
            setError8(true)
        } else {
            setError8(false)
        }
        if(!cTime9(timeData)){
            setError9(true)
        } else {
            setError9(false)
        }
        if(!cTime10(timeData)){
            setError10(true)
        } else {
            setError10(false)
        }
        if(!cTime11(timeData)){
            setError11(true)
        } else {
            setError11(false)
        }
        if(!cTime12(timeData)){
            setError12(true)
        } else {
            setError12(false)
        }

        if(cTime1(timeData) && cTime2(timeData) && cTime3(timeData) && cTime4(timeData) && cTime5(timeData) && cTime6(timeData) && cTime7(timeData) && cTime8(timeData) && cTime9(timeData) && cTime10(timeData) && cTime11(timeData) && cTime12(timeData)){
            setPTime(true)
        }
        else {
            setPTime(false)
        }

    }, [timeData, iTime])

    const handleChange = e => {
        e.preventDefault()
        const type = e.type.target
        const name = e.target.name

        const value = type === 'checkbox'
            ? e.target.checked
            : e.target.value

        setTimeData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    const handleClose = (e) => {
        toggle()
        setPTime(true)
        setTimeData({
            id: '',
            id_time_sheet: '',
            index: '',
            day_hours: '',
            periode_one_start: '',
            periode_one_end: '',
            periode_two_start: '',
            periode_two_end: '',
            periode_three_start: '',
            periode_three_end: '',
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (pTime) {
            ApiDataBase.postUpdateDay({timeData, token},
                (data) => {
                    toggle()
                    setRefreshTime(!refreshTime)
                    setTimeData({
                        id: '',
                        id_time_sheet: '',
                        index: '',
                        day_hours: '',
                        periode_one_start: '',
                        periode_one_end: '',
                        periode_two_start: '',
                        periode_two_end: '',
                        periode_three_start: '',
                        periode_three_end: '',
                    })
                },
                (err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        if(onClickConfirm && !confirmTutoBreaks){
            if(token){
                ApiDataBase.putCheckTutoBreaks({token}, (data) => {
                    setConfirmTutoBreaks(true)
                }, (err) => {
                    setConfirmTutoBreaks(false)
                })
            } else {
                toast.error('Une erreur est survenue, veuillez réessayer plus tard.')
            }
        }
    }, [onClickConfirm])

    useEffect(() => {
        if(Object.keys(tempWorkerData).length > 0){
            setConfirmTutoBreaks(tempWorkerData.tutoBreaks ?? false)
        }
    }, [tempWorkerData])

    return (
        <>

            <Modalheader hide={handleClose} hideShow={true}>
                Relevé n°{content.idMission} - {content.nameCompany}
            </Modalheader>
            <ModalBody>
                {work ?
                    <>
                        <div className={'flex flex-col gap-6'}>
                            <p>Ajoutez les différentes périodes travaillées pour la journée.</p>
                            <div className={`flex flex-col gap-4 ${error1 !== '' ? 'border-2 border-red-600 rounded-md' : ''} p-2`}>
                                {error1 !== '' ? <p className={'ft-b text-red-700'}>{error1}</p> : null}
                                <div className={'flex gap-4'}>
                                    <div className={'w-1/2'}>
                                        <label htmlFor="periode_one_start">Heure de début</label>
                                        <input type="time"
                                               onChange={handleChange}
                                               name={'periode_one_start'}
                                               value={timeData.periode_one_start}
                                               min={'00:00'}
                                               max={'23:59'}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                        {
                                            error7 ? (
                                                <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                    <div className={'my-auto'}>
                                                        <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Ce champ est requis
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className={'w-1/2'}>
                                        <label htmlFor="periode_one_end">Heure de Fin</label>
                                        <input type="time"
                                               name={'periode_one_end'}
                                               value={timeData.periode_one_end}
                                               onChange={handleChange}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                        {
                                            error8 ? (
                                                <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                    <div className={'my-auto'}>
                                                        <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Ce champ est requis
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {iTime > 1 ?
                                <div className={`flex flex-col gap-4 mx-3 rounded border ${(error2 !== '' || error4 !== '') ? 'border-2 border-red-600 rounded-md' : null} p-4`}>
                                    <div className={'flex justify-between'}>
                                        <p>Pause 1</p>
                                        <div onClick={handleSITime}>
                                            <div className={'cursor-pointer'}>
                                                <Close wh={24} color={'#6B7280'}/>
                                            </div>
                                        </div>
                                    </div>
                                    {(error2 !== '' || error4 !== '') ? <p className={' col-span-2 ft-b text-red-700'}>{error2 || error4}</p> : null}
                                    <div className={'flex gap-4'}>
                                        <div className={'w-1/2'}>
                                            <label htmlFor="periode_two_start">Heure de début</label>
                                            <input type="time"
                                                   onChange={handleChange}
                                                   value={timeData.periode_two_start}
                                                   name={'periode_two_start'}
                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                            />
                                            {
                                                error9 ? (
                                                    <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                        <div className={'my-auto'}>
                                                            <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            Ce champ est requis
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className={'w-1/2'}>
                                            <label htmlFor="periode_two_end">Heure de Fin</label>
                                            <input type="time"
                                                   onChange={handleChange}
                                                   name={'periode_two_end'}
                                                   value={timeData.periode_two_end}
                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                            />
                                            {
                                                error10 ? (
                                                    <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                        <div className={'my-auto'}>
                                                            <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            Ce champ est requis
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {iTime > 2 ?
                                <div className={`flex flex-col gap-4 mx-3 rounded border ${(error3 !== '' || error5 !== '' || error6 !== '') ? 'border-2 border-red-600 rounded-md' : null} p-4`}>
                                    <div className={'flex justify-between'}>
                                        <p>Pause 2</p>
                                        <div onClick={handleSITime}>
                                            <div className={'cursor-pointer'}>
                                                <Close wh={24} color={'#6B7280'}/>
                                            </div>
                                        </div>
                                    </div>
                                    {(error3 !== '' || error5 !== '' || error6 !== '') ? <p className={' col-span-2 ft-b text-red-700'}>{error3 || error5 || error6}</p> : null}
                                    <div className={'flex gap-4'}>
                                        <div className={'w-1/2'}>
                                            <label htmlFor="periode_three_start">Heure de début</label>
                                            <input type="time"
                                                   onChange={handleChange}
                                                   name={'periode_three_start'}
                                                   value={timeData.periode_three_start}
                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                            />
                                            {
                                                error11 ? (
                                                    <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                        <div className={'my-auto'}>
                                                            <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            Ce champ est requis
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className={'w-1/2'}>
                                            <label htmlFor="periode_three_end">Heure de Fin</label>
                                            <input type="time"
                                                   onChange={handleChange}
                                                   name={'periode_three_end'}
                                                   value={timeData.periode_three_end}
                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                            />
                                            {
                                                error12 ? (
                                                    <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                        <div className={'my-auto'}>
                                                            <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            Ce champ est requis
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        {iTime < 3 ?
                            <div className={'mt-8 flex'}>
                                {
                                    confirmTutoBreaks ? (
                                        <>
                                            <Button size={'LG'} color={'SECONDARY'} onClick={handleITime}>
                                                <div className={'flex gap-2'}>
                                                    <div className={'my-auto'}>
                                                        <Plus wh={16} color={'#111827'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Ajouter une pause
                                                    </div>
                                                </div>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <ExternalTooltip title={'Ajouter une pause'}
                                                             description={'Maintenant, vous pouvez ajouter vos pauses sur vos relevés d’heures Jobbiz !'}
                                                             confirm={confirmTutoBreaks}
                                                             setOnClickConfirm={setOnClickConfirm}
                                            >
                                                <Button size={'LG'} color={'SECONDARY'} onClick={handleITime}>
                                                    <div className={'flex gap-2'}>
                                                        <div className={'my-auto'}>
                                                            <Plus wh={16} color={'#111827'}/>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            Ajouter une pause
                                                        </div>
                                                    </div>
                                                </Button>
                                            </ExternalTooltip>
                                        </>
                                    )
                                }
                            </div>
                            : null}
                    </>
                    : `Vous avez indiqué ne pas avoir travaillé le ${dateWordDay(timeData.date)}`

                }
            </ModalBody>
            <ModalFooter>
                <div onClick={(e) => {
                    setWork(!work)
                }}
                     className={'absolute left-0 ml-8 cursor-pointer text-yellow-500 underline'}>
                    {work ? 'Jour non travaillé ?' : 'Jour travaillé ?'}
                </div>
                <div className={'flex mt-32 tab:mt-0 tab:gap-8'}>
                    <Button size={'LG'} color={'SECONDARY'} onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button size={'LG'} onClick={handleSubmit}>
                        Confirmer
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default Completeday;