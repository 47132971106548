import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate} from "react-router-dom";
import Availability from "pages/content/disponibilites/availability";
import UseContext from "hooks/useContext";
import CardProfile from "../../../../components/card/cardProfile/cardProfile";
import Divider from "../../../../components/divider/divider";
import Unavailability from "../../../content/disponibilites/unavailability";
import usefetchdata from "../../../../hooks/usefetchdata";
import useTitle from "../../../../hooks/TitleManager";


const Disponibilite = () => {
    useTitle({ title: "Profil - Mes disponibilités" });
    const {tempWorkerData} = usefetchdata();
    const {setIsPostulateWithoutAvailability} = UseContext()
    const [dateStart, setDateStart] = useState()
    const [dateEnd, setDateEnd] = useState()
    const [isAvailable, setIsAvailable] = useState()

    useEffect(() => {
        if(tempWorkerData){
            setDateStart(tempWorkerData.availability?.date_start ? new Date(tempWorkerData.availability.date_start) : null)
            setDateEnd(tempWorkerData.availability?.date_end ? new Date(tempWorkerData.availability.date_end) : null)
            setIsAvailable(tempWorkerData.availability?.is_available)
        }
    }, [tempWorkerData]);

    useEffect(() => {
        setIsPostulateWithoutAvailability({})
    }, []);

    return (
        <>
            <div className='col-span-12 flex justify-center items-center mb-32 tab:mb-0'>
                <div className='md:w-[800px] w-full mx-auto'>
                    <CardProfile>
                        <div className="flex flex-col gap-7">
                            <div className="">
                                <h1 className="flex justify-start ft-xl">
                                    Mes disponibilités
                                </h1>
                                <p className="text-gray-500">
                                    Indiquez à quelles tranches horaires vous êtes disponible pour travailler.
                                </p>
                            </div>
                            <div className="px-3">
                                <Availability startDate={dateStart} endDate={dateEnd} isAvailable={isAvailable}/>
                            </div>
                            <Divider/>
                            <div className={''}>
                                <Unavailability/>
                            </div>
                        </div>
                    </CardProfile>
                </div>
            </div>
        </>
    );
};

export default Disponibilite;