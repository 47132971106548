import React from 'react';

const Cardfooter = ({children,add}) => {
    return (
        <div className={`py-3 px-6 border-t border-gray-300 text-gray-600 ${add}`}>
            {children}
        </div>
    );
};

export default Cardfooter;