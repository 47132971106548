import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";


import Unauthorized from "pages/unAuthorized";
import RequireAuth from "components/requireAuth";
import Dashboard from "pages/pages/Dashboard";
import Documents from "pages/pages/Documents";
import Contract
    from "pages/content/documents/contracts/contract";
import Sign from "pages/content/documents/contracts/sign";
import Nosign
    from "pages/content/documents/contracts/nosign";
import Hourscontent
    from "pages/content/documents/hours/hourscontent";
import CompletedHours
    from "pages/content/documents/hours/completed";
import Nocompleted
    from "pages/content/documents/hours/nocompleted";
import Payslip
    from "pages/content/documents/payslip/payslip";
import {Help} from "assets/icons";
import Profil from "pages/pages/Profil";
import Compte from "pages/pages/profil/content/compte";
import Savoirfaire
    from "pages/pages/profil/content/savoirfaire";
import Cv from "pages/content/savoirfaire/cv";
import Experience
    from "pages/content/savoirfaire/experience";
import Langues from "pages/content/savoirfaire/langues";
import Tools from "pages/content/savoirfaire/tools";
import Justificatif
    from "pages/pages/profil/content/justificatif";
import Identity
    from "pages/content/justificatifs/identity";
import Formations
    from "pages/content/justificatifs/formations";
import Licences
    from "pages/content/justificatifs/licences";
import Disponibilite
    from "pages/pages/profil/content/disponibilite";
import Unavailability from "pages/content/disponibilites/unavailability";
import Availability
    from "pages/content/disponibilites/availability";
import Parametre
    from "pages/pages/profil/content/parametre";
import Mission from "pages/pages/Mission";
import Progress from "pages/content/mission/progress";
import Completed from "pages/content/mission/completed";
import Comingsoon from "pages/content/mission/comingsoon";
import SearchMission from "pages/pages/SearchMission";
import Missionfiche
    from "pages/pages/mission/missionfiche";
import LayoutTw from 'components/layout'
import FicheHour
    from "pages/content/documents/hours/ficheHour";
import Layout from "pages/pages/layout";
import PdfView from "pages/pages/pdfView";
import MissionfileSearch from "pages/pages/mission/missionfileSearch";
import CvParsing from "../pages/content/savoirfaire/cvParsing";
import MobileSuggestion from "../pages/pages/MobileSuggestion";
import TrainingGuides from "../pages/pages/profil/content/trainingGuides";


const TempWorkerRoutes = () => {

    return (
        <>
            <Routes>

                <Route path='/' element={<Layout/>}>
                    <Route path={'interimaire'} element={<LayoutTw/>}/>
                    <Route path={'.well-known/assetlinks.json'}/>
                    <Route path={'.well-known/apple-app-site-association.json'}/>
                    <Route element={<RequireAuth
                        allowedRoles={'100'}/>}>
                        <Route path='dashboard' element={<Dashboard/>}/>
                        <Route path='documents' element={<Documents/>}>
                            <Route path='contracts' element={<Contract/>}>
                                <Route path={'sign'} element={<Sign/>}/>
                                <Route path={'nosign'}
                                       element={<Nosign/>}/>
                            </Route>
                            <Route path='hours' element={<Hourscontent/>}>
                                <Route path='completed'
                                       element={<CompletedHours/>}/>
                                <Route path='nocompleted'
                                       element={<Nocompleted/>}/>
                            </Route>
                            <Route path={'ficheHour/:id'} element={<FicheHour/>}/>
                            <Route path='payslip' element={<Payslip/>}/>
                        </Route>
                        <Route path='help' element={<Help/>}/>
                        <Route path='cv/details' element={<CvParsing/>}/>
                        <Route path='profil' element={<Profil/>}>
                            <Route path='compte' element={<Compte/>}/>
                            <Route path='savoir_faire'
                                   element={<Savoirfaire/>}>
                                <Route path='cv' element={<Cv/>}/>
                                <Route path='experiences'
                                       element={<Experience/>}/>
                                <Route path='language'
                                       element={<Langues/>}/>
                                <Route path='tools'
                                       element={<Tools/>}/>
                            </Route>
                            <Route path='justificatifs'
                                   element={<Justificatif/>}>
                                <Route path='identity'
                                       element={<Identity/>}/>
                                <Route path='degree'
                                       element={<Formations/>}/>
                                <Route path='licence'
                                       element={<Licences/>}/>
                            </Route>
                            <Route path='disponibilites'
                                   element={<Disponibilite/>}>
                                <Route path='unavailability'
                                       element={<Unavailability/>}/>
                                <Route path='availability'
                                       element={<Availability/>}/>
                            </Route>
                            <Route path='parametres'
                                   element={<Parametre/>}/>
                            <Route path='training_guides'
                                   element={<TrainingGuides/>}/>
                        </Route>
                        <Route path='mission' element={<Mission/>}>
                            <Route path={'in_progress'}
                                   element={<Progress/>}/>
                            <Route path={'completed'}
                                   element={<Completed/>}/>
                            <Route path={'coming_soon'}
                                   element={<Comingsoon/>}/>
                        </Route>
                        <Route path='searchMission'
                               element={<SearchMission/>}/>
                        <Route path='ficheMissionSearch/:id'
                               element={<MissionfileSearch/>}/>
                        <Route path='ficheMission/:id'
                               element={<Missionfiche/>}/>
                        <Route path={'mobileSuggestion'} element={<MobileSuggestion/>}/>
                        <Route path={'mobileSuggestion/:redirect'} element={<MobileSuggestion/>}/>
                    </Route>
                    <Route path='pdfView' element={<PdfView/>}/>
                    {/*<Route path={'*'} element={<Unauthorized/>}/>*/}
                    <Route path={""} element={<Navigate to="/dashboard"/>} />
                    <Route path={"*"} element={<Navigate to="/dashboard"/>} />
                </Route>
            </Routes>
        </>
    )
        ;
};

export default TempWorkerRoutes;