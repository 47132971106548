// *********** REGEX ***********
// Regex Name
const {dateToEpoch} = require("./helper");
module.exports.NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
//Regex Verify
module.exports.EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
module.exports.PWD_REGEX = /^(?=.{8,40}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/;

module.exports.MESSAGE_REGEX = /^[a-zA-Z\d?$@#()'!,+\-=_:.&€£*%\s]+$/

// *********** Function ***********
// Calcul de l'age selon une date en format string
module.exports.getAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

module.exports.checkPassword = (data) => {
    //javascript regular expression pattern
    const lower = new RegExp('(?=.*[a-z])')
    const upper = new RegExp('(?=.*[A-Z])')
    const number = new RegExp('(?=.*[0-9])')
    const special = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/)
    const length = new RegExp('(?=.{8,40})')
    const check = {
        lower: false,
        upper: false,
        number: false,
        special: false,
        length: false
    }
    check.lower = lower.test(data);
    check.length = length.test(data);
    check.upper = upper.test(data);
    check.number = number.test(data);
    check.special = special.test(data)

    return check
}

module.exports.delay = (ms) => new Promise(
    resolve => setTimeout(resolve, ms)
)

module.exports.getCookie = (key) => {
    const cookie = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return cookie ? cookie.pop() : cookie;
}

module.exports.dateDiff = (date) => {
    const diff = {}
    const date1 = new Date(date)
    const date2 = new Date()
    let diff_temps = date2 - date1
    diff_temps = Math.floor(diff_temps / 1000)
    diff.sec = diff_temps % 60
    diff_temps = Math.floor((diff_temps - diff.sec) / 60)
    diff.min = diff_temps % 60
    diff_temps = Math.floor((diff_temps - diff.min) / 60)
    diff.hour = diff_temps % 24
    diff.day = Math.floor((diff_temps - diff.hour) / 24)
    return diff
}

module.exports.dateDiffExp = (date) => {
    const ynew = date.end.getFullYear();
    const mnew = date.end.getMonth();
    const dnew = date.end.getDate();
    const yold = date.start.getFullYear();
    const mold = date.start.getMonth();
    const dold = date.start.getDate();
    let diff = date.end - date.start;
    if (mold > mnew) diff--;
    else {
        if (mold === mnew) {
            if (dold > dnew) diff--;
        }
    }
    return diff;
}

module.exports.statutMission = (date) => {
    const status = {}
    const dateJ = this.dateToEpoch(new Date())
    const DateStart = this.dateToEpoch(date?.datestart)
    const DateEnd = this.dateToEpoch(date?.dateend)
    if (dateJ.getTime() < DateStart.getTime()) {
        status.text = 'A venir'
        status.color = ''
    } else if (dateJ.getTime() >= DateStart.getTime() && dateJ.getTime() <= DateEnd.getTime()) {
        status.text = 'En cours'
        status.color = 'INFO'
    } else {
        status.text = 'Terminée'
        status.color = 'ALERT'
    }
    return status
}

module.exports.dateText = (d) => {
    const date = {}
    const month = {
        '01': 'Janv.',
        '02': 'Fev.',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juil.',
        '08': 'Août',
        '09': 'Sept.',
        '10': 'Oct.',
        '11': 'Nov.',
        '12': 'Dec.',
    }
    const dayS = d?.datestart.split('/')

    date.start = dayS[0] + ' ' + month[dayS[1]] + ' ' + dayS[2]
    const dayE = d?.dateend.split('/')
    date.end = dayE[0] + ' ' + month[dayE[1]] + ' ' + dayE[2]
    return date
}

module.exports.dateExp = (d) => {
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long'}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.dateWordShort = (d) => {
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'numeric', day: 'numeric',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.dateWordMonth=(d)=>{
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long', day: 'numeric',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.dateWordDay=(d)=>{
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long', day: 'numeric',weekday:'long',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.tContract = (c) => {
    const tContract = {
        1: 'CDI',
        2: 'CDD',
        3: 'Interim',
        4: 'Saisonnier',
        5: 'Temps partiel'
    }
    return tContract[c]
}

module.exports.sBadge = (v) => {
    const type = {
        0: 'WARNING',
        1: 'VALID',
        2: 'ALERT'
    }
    return type[v]
}

module.exports.tBadge = (v) => {
    const type = {
        0: 'En attente',
        1: 'Validée',
        2: 'Refusée'
    }
    return type[v]
}

module.exports.pFormation = (f) => {

    if (f.includes('cap')) return 'CAP ou équivalents'
    if (f.includes('bep')) return 'BEP ou équivalents'
    if (f.includes('bac')) return "Bac (général, technique ou professionnel )ou équivalents"
    if (f.includes('bac_2')) return "Bac+2 (BTS, DUT ou équivalents)"
    if (f.includes('bac_3')) return "Bac+3(Licence ou équivalents)"
    if (f.includes('bac_4')) return "Bac+4 (Maitrise ou équivalents)"
    if (f.includes('bac_5')) return "Bac+5 et plus (Master ou équivalents)"

    return ''
}

module.exports.tReplace = (t) => {
    return t.replace(' ', '_')
}

const DEFAULT_VALUE='default'
module.exports.promiseReq=({value = DEFAULT_VALUE,fail=false}={})=>{
    return new MyPromiseReq((resolve,reject)=>{
        fail? reject(value):resolve(value)
    })
}

module.exports.dateToEpoch = (date) => {
    return new Date(new Date(date).toLocaleDateString("en-EN", {timeZone: "Europe/Paris"}));
}

module.exports.getTimeSheetsDifferencesByDay = (period_start, period_end) => {
    let period_total_hour_before_6am = 0;
    let period_total_hour_after_9am = 0;
    let period_total_hour = 0;

    let hours_before_6am = 0;
    let hours_after_9pm = 0;
    let period_total_night = 0;

    const date_morning = new Date('1970-01-01T06:00:00.000Z');
    const date_night = new Date('1970-01-01T21:00:00.000Z');
    const date_midnight = new Date('1970-01-01T00:00:00.000Z');

    let period_start_date = new Date('1970-01-01T' + period_start + ':00.000Z');
    let period_end_date = new Date('1970-01-01T' + period_end + ':00.000Z');

    /*-------------------DATE MORNING---------------------*/
    if(period_start_date < date_morning && period_start_date.toString() !== date_midnight.toString()) {
        //calculate hour before 6am
        hours_before_6am += date_morning - period_start_date;
        period_start_date = date_morning;
    }
    if(period_end_date < date_morning && period_end_date.toString() !== date_midnight.toString()) {
        //calculate hour before 6am
        hours_before_6am += date_morning - period_end_date;
        period_end_date = date_morning;
    }

    /*-------------------DATE NIGHT---------------------*/
    if(period_start_date > date_night && period_start_date.toString() !== date_midnight.toString()) {
        //calculate hour after 9pm
        hours_after_9pm += date_night - period_start_date;
        period_start_date = date_night;
    }
    if(period_end_date > date_night && period_end_date.toString() !== date_midnight.toString()) {
        //calculate hour after 9pm
        hours_after_9pm += period_end_date - date_night;
        period_end_date = date_night;
    }

    const period_date_before_6am = period_end_date - period_start_date;

    period_total_hour_after_9am = hours_after_9pm / 3600000;
    period_total_hour_before_6am = hours_before_6am / 3600000;
    period_total_night = period_total_hour_after_9am + period_total_hour_before_6am;
    period_total_hour = period_date_before_6am / 3600000;

    return {
        day: period_total_hour,
        night: period_total_night
    }
}

module.exports.numberFormat = (number) => {
    return number.toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

module.exports.floatToTime = (number) => {
    //convert float to time
    /*const hours = Math.floor(number);
    const minutes = Math.floor((number - hours) * 60);

    //minutes to string with 2 digits
    const minutesString = minutes < 10 ? '0' + minutes : minutes;

    return hours + 'h' + ((minutesString !== '00' || minutesString !== '0') ? minutesString : '');*/

// Check sign of given number
    let sign = (number >= 0) ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    let hour = Math.floor(number);
    let decpart = number - hour;

    let min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    let minute = Math.floor(decpart * 60) + '';

    if (minute.length < 2) {
        minute = '0' + minute;
    }

    // Add Sign in final result
    sign = sign === 1 ? '' : '-';

    // Concate hours and minutes
    return sign + hour + 'h' + (minute !== '00' ? minute : '');
}

module.exports.calculateHours = (period_start, period_end, substraction) => {
    const period_start_split = period_start.split(':');
    const period_end_split = period_end.split(':');
    const h1 = parseInt(period_start_split[0]);
    const m1 = parseInt(period_start_split[1]);
    const h2 = parseInt(period_end_split[0]);
    const m2 = parseInt(period_end_split[1]);

    const totalMinutes1 = h1 * 60 + m1; // Convertir la première heure en minutes totales
    const totalMinutes2 = h2 * 60 + m2; // Convertir la deuxième heure en minutes totales

    const differenceMinutes = substraction ? Math.abs(totalMinutes1 - totalMinutes2) : (totalMinutes1 + totalMinutes2); // Calculer la différence ou la somme en minutes

    const hours = Math.floor(differenceMinutes / 60); // Extraire le nombre d'heures
    const minutes = differenceMinutes % 60; // Extraire le nombre de minutes

    // Formatage du résultat
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
}

module.exports.formatHours = (hour) => {
    const hourSplit = hour.split(':');
    const h = hourSplit[0];
    const m = hourSplit[1];

    //if the fist letter of hour is '0' when his length is 2, remove it and if minutes is '00', remove it, add 0 before minute if length is 1 and add 'h' between hours and minutes
    return (h[0] === '0' && h.length === 2 ? h[1] : h) + 'h' + (m === '00' ? '' : (m.length === 1 ? '0' + m : m));
}

module.exports.isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}